import { Button, Heading, Highlight, HStack } from "@chakra-ui/react";
import ResearchTask from "components/Forms/ResearchTask";
import Navigation from "components/Layout/Navigation";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createResearchTask } from "utils/api";

function CreateResearchTask() {
	const navigate = useNavigate();
	const onCancel = () => {
		navigate("/research-tasks");
	};
	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Research Task"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Research Task
					</Highlight>
				</Heading>
				<Button onClick={onCancel} size="xs">
					Cancel
				</Button>
			</HStack>
			<ResearchTask
				onSubmit={createResearchTask}
				onSuccess={(res) => {
					navigate(`/research-tasks/${res.id}`);
				}}
				onCancel={onCancel}
			/>
		</Navigation>
	);
}

export default CreateResearchTask;
