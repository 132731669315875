import React, { useEffect, useState, useContext } from "react";
import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Image,
	VStack,
	IconButton,
	Divider,
	HStack,
	Icon,
} from "@chakra-ui/react";
import Navigation from "../../../components/Layout/Navigation";
import { useParams } from "react-router-dom";
import { AiFillProject, AiFillTag } from "react-icons/ai";
import { GiArchiveResearch } from "react-icons/gi";
import { MdPublish } from "react-icons/md";
import { getBaseUrl } from "../../../base";
import axios from "axios";
import ProfileTab from "./ProfileTab";
import ResearchTaskTab from "./ResearchTaskTab";
import TagsTab from "./TagsTab";
import PublicationTab from "./PublicationTab";
import { AccountContext } from "contexts/AccountContext";

const ProjectProfile = () => {
	const { apiCall } = useContext(AccountContext);
	const { id } = useParams();
	const [projectProfile, setProjectProfile] = useState({});

	//func to get single project profile
	const getProjectProfile = async (id) => {
		let url = `${getBaseUrl()}/api/projects/getProject/${id}`;
		const { data } = await apiCall(url);
		setProjectProfile(data);
		return;
	};

	useEffect(() => {
		getProjectProfile(id);
		window.scrollTo(0, 0);
	}, []);

	// console.log(projectProfile);

	return (
		<Navigation>
			<Tabs
				variant="soft-rounded"
				variantcolor="brand.100"
				orientation="vertical"
				size={"sm"}
			>
				<TabList gap={5} maxWidth={"200px"} minWidth={"200px"}>
					<Divider />
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={AiFillProject} /> Project Profile
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={GiArchiveResearch} /> Research Task
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={AiFillTag} /> Tags
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={MdPublish} /> Publications
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel py={0} pr={0}>
						<ProfileTab
							id={id}
							getProjectProfile={getProjectProfile}
							projectProfile={projectProfile}
						/>
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<ResearchTaskTab projectProfile={projectProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<TagsTab projectProfile={projectProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<PublicationTab projectProfile={projectProfile} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Navigation>
	);
};

export default ProjectProfile;
