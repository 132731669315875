import { Button } from "@chakra-ui/react";
import { AccountContext } from "contexts/AccountContext";
import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { handleError } from "utils/api";

function removeFileExtension(filename) {
	return filename.replace(/\..+$/, "");
}

function FileUploadBtn({
	btnLabel,
	onSubmit = () => {},
	accept = "*",
	onSuccess = () => {},
}) {
	const uploadInput = useRef();
	const [isSubmitting, setSubmitting] = useState(false);
	const { toastMsg } = useContext(AccountContext);
	const handleUpload = useCallback(
		async (e) => {
			try {
				setSubmitting(true);
				const file = e.target.files[0];
				if (file) {
					await onSubmit(file);
					onSuccess(URL.createObjectURL(file), removeFileExtension(file.name));
					toastMsg({
						title: "Success!",
						desc: "File uploaded successfully",
						type: "success",
					});
				}
			} catch (e) {
				let errorMsg = handleError(e);
				if (typeof errorMsg !== "string") errorMsg = "Error uploading file";
				toastMsg({
					title: "Error!",
					desc: errorMsg,
					type: "error",
				});
			} finally {
				e.target.value = "";
				setSubmitting(false);
				return true;
			}
		},
		[toastMsg]
	);
	useEffect(() => {
		let inputNode = uploadInput.current;
		if (inputNode) {
			inputNode.addEventListener("change", handleUpload);
		}
		return () => {
			inputNode?.removeEventListener("change", handleUpload);
		};
	}, [uploadInput, handleUpload]);
	return (
		<div>
			<Button
				leftIcon={<AiOutlineUpload />}
				onClick={() => {
					uploadInput.current?.click();
				}}
				size="sm"
				variant="outline"
				isLoading={isSubmitting}
				loadingText="Uploading"
			>
				{btnLabel}
			</Button>
			<input hidden accept={accept} type="file" ref={uploadInput} />
		</div>
	);
}

export default FileUploadBtn;
