import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	useCallback,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	useColorModeValue,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Image,
	VStack,
	IconButton,
	Divider,
	HStack,
	Icon,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalCloseButton,
	Button,
	useDisclosure,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import { BsFillPersonFill } from "react-icons/bs";
import { AiFillProject } from "react-icons/ai";
import { GiArchiveResearch } from "react-icons/gi";
import { HiDocumentReport } from "react-icons/hi";
import { MdPublish, MdFlag } from "react-icons/md";
import Navigation from "../../../components/Layout/Navigation";
import Profile from "./ProfileTab/Profile";
import Specialities from "./Specialities";
import Projects from "./Projects";
import ResearchTasks from "./ResearchTasks";
import Reports from "./Reports";
import Publications from "./Publications";
import { AccountContext } from "contexts/AccountContext";
import { getBaseUrl } from "../../../base";
import FileNameInput from "components/FileNameInput";

const PeopleProfile = () => {
	const { id } = useParams();
	const [peopleProfile, setPeopleProfile] = useState({});
	const { toastMsg, apiCall } = useContext(AccountContext);

	//func to get single people profile
	const getPeopleProfile = async (peopleID) => {
		let url = `${getBaseUrl()}/api/people/getPersonProfile/${peopleID}`;
		const { data } = await apiCall(url);
		setPeopleProfile(data);
		return;
	};

	useEffect(() => {
		getPeopleProfile(id);
		window.scrollTo(0, 0);
	}, []);

	const inputRef = useRef(null);
	const [imgToUpload, setImgToUpload] = useState("");
	const [isUploading, setIsUploading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleClick = () => {
		inputRef.current.click();
	};

	const handleFileChange = (e) => {
		const fileObj = e.target.files[0];
		if (!fileObj) {
			return;
		}
		if (e.target.files && e.target.files.length > 0) {
			setImgToUpload(fileObj);
			onOpen();
		}
	};

	//func to upload profile image
	const uploadPersonProfile = async () => {
		try {
			setIsUploading(true);
			let url = `${getBaseUrl()}/api/people/uploadPersonProfile`;
			let profilePicFD = new FormData();
			profilePicFD.append("person_id", id);
			profilePicFD.append("profile", imgToUpload);

			const config = {
				"Content-Type": "multipart/form-data",
			};

			await apiCall(url, "POST", profilePicFD, config).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setImgToUpload("");
				setIsUploading(false);
				onClose();
				getPeopleProfile(id);
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			setImgToUpload("");
			onClose();
			getPeopleProfile(id);
			return;
		}
	};

	return (
		<Navigation>
			<Modal isOpen={isOpen} onClose={() => onClose()}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Confirm profile picture</ModalHeader>
					<ModalCloseButton />

					<ModalFooter justifyContent={"space-between"}>
						<Button
							onClick={() => {
								setImgToUpload("");
								onClose();
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={uploadPersonProfile}
							isLoading={isUploading}
							loadingText={"Uploading"}
						>
							Save
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Tabs
				variant="soft-rounded"
				variantcolor="brand.100"
				orientation="vertical"
				size={"sm"}
			>
				<TabList
					// pr={1}
					gap={5}
					// w="250px"
					maxWidth={"200px"}
					minWidth={"200px"}
					// borderRight="1px"
					// borderRightColor={useColorModeValue("gray.200", "gray.700")}
				>
					{peopleProfile.image_s3 != null ? (
						<>
							<VStack position="relative">
								<Image
									borderRadius="3xl"
									boxShadow="xl"
									src={peopleProfile.image_s3}
									alt={peopleProfile.image}
								/>
								<HStack
									align={"stretch"}
									position="absolute"
									bottom={0}
									right={0}
								>
									<IconButton
										borderRadius="full"
										size="sm"
										icon={<FaCamera />}
										variant="ghost"
										bg={"brand.100"}
										onClick={handleClick}
									/>
									<input
										ref={inputRef}
										type="file"
										style={{ display: "none" }}
										accept=".jpg,.png,.webp,.jpeg"
										onChange={handleFileChange}
									/>
								</HStack>
							</VStack>
							<FileNameInput
								fileName={peopleProfile.imageFilename}
								endpoint={`/api/people/renameProfile/${peopleProfile.id}`}
								onSuccess={(res) => {
									setPeopleProfile(res.person);
								}}
							/>
						</>
					) : (
						<VStack>
							<HStack align={"stretch"} bottom={0} right={0}>
								<IconButton
									borderRadius="full"
									size="sm"
									icon={<FaCamera />}
									variant="ghost"
									bg={"brand.100"}
									onClick={handleClick}
								/>
								<input
									ref={inputRef}
									type="file"
									style={{ display: "none" }}
									accept=".jpg,.png,.webp,.jpeg"
									onChange={handleFileChange}
								/>
							</HStack>
						</VStack>
					)}

					<Divider />

					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={BsFillPersonFill} /> Profile
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={AiFillProject} /> Projects
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={GiArchiveResearch} /> Research Task
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={HiDocumentReport} /> Technical Reports
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={MdPublish} /> Publications
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={MdFlag} /> Specialities
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel py={0} pr={0}>
						<Profile
							id={id}
							getPeopleProfile={getPeopleProfile}
							peopleProfile={peopleProfile}
						/>
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<Projects peopleProfile={peopleProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<ResearchTasks peopleProfile={peopleProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<Reports peopleProfile={peopleProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<Publications peopleProfile={peopleProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<Specialities peopleProfile={peopleProfile} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Navigation>
	);
};

export default PeopleProfile;
