import {
	Divider,
	Icon,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { FaAward } from "react-icons/fa";
import { HiCurrencyDollar } from "react-icons/hi";
import { useParams } from "react-router-dom";
import Awards from "./Awards";
import FinanceProfile from "./FinanceProfile";

const SingleFinance = () => {
	const { id } = useParams();
	const { data, error, loading, refetch } = useFetch(`/api/finance/${id}`);

	const renderPageData = useCallback(() => {
		if (loading) return <Text>Loading...</Text>;
		if (error || !data)
			return <Text>Error getting Finance data for id {id}</Text>;
		return (
			<TabPanels ml={4}>
				<TabPanel py={0} px={0}>
					<FinanceProfile financeData={data} refetch={refetch} />
				</TabPanel>
				<TabPanel px={0} py={0}>
					<Awards financeData={data} refetch={refetch} />
				</TabPanel>
			</TabPanels>
		);
	}, [data, error, loading]);

	return (
		<Navigation>
			<Tabs
				variant="soft-rounded"
				variantcolor="brand.100"
				orientation="vertical"
				size={"sm"}
			>
				<TabList gap={5} maxWidth={"200px"} minWidth={"200px"}>
					<Divider />
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={HiCurrencyDollar} /> Finance Profile
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={FaAward} /> Awards
					</Tab>
				</TabList>
				{renderPageData()}
			</Tabs>
		</Navigation>
	);
};

export default SingleFinance;
