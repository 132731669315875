import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getBaseUrl } from "../../../base";
import Navigation from "../../../components/Layout/Navigation";
import {
	Badge,
	Box,
	Divider,
	Heading,
	HStack,
	IconButton,
	ListItem,
	FormControl,
	Input,
	FormLabel,
	UnorderedList,
	useDisclosure,
	VStack,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { AccountContext } from "contexts/AccountContext";

const OrgTypesProfile = () => {
	const { id } = useParams();
	const [orgTypeProfile, setOrgTypeProfile] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	//func to get single Org type profile
	const getOrgTypeProfile = async (orgID) => {
		let url = `${getBaseUrl()}/api/org/getOrgTypeById/${orgID}`;
		const { data } = await apiCall(url);
		setOrgTypeProfile(data);
		return;
	};

	const [orgType, setOrgtype] = useState({});

	useEffect(() => {
		setOrgtype({ id: orgTypeProfile.id, org_type: orgTypeProfile.type_name });
	}, [orgTypeProfile]);

	//func to update org type
	const updateOrgType = async () => {
		let url = `${getBaseUrl()}/api/org/updateOrgType`;
		try {
			await apiCall(url, "POST", orgType).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
		getOrgTypeProfile(id);
		toggleInfoEdit();
		return;
	};

	//func to delete org
	const deleteOrg = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/org/deleteOrgType`;
			await apiCall(url, "POST", { id: orgTypeProfile.id }).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/organization-types");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	useEffect(() => {
		getOrgTypeProfile(id);
		window.scrollTo(0, 0);
	}, []);

	return (
		<Navigation>
			<VStack gap={1} align={"stretch"} pb={20}>
				<AlertDialog
					isOpen={isOpen}
					onClose={() => onClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Delete Organization
							</AlertDialogHeader>
							<AlertDialogCloseButton />
							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to Delete Org Type:{"  "}
								<b>"{orgTypeProfile.type_name}"</b>
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onClose()}>
									Cancel
								</Button>
								<Button
									size={"sm"}
									isLoading={addBtnStatus}
									loadingText="Deleting"
									onClick={() => {
										deleteOrg();
									}}
									ml={3}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				<AlertDialog
					isOpen={isUpdateOpen}
					onClose={() => onUpdateClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Update Org Type
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to update the Org Type
							</AlertDialogBody>
							<AlertDialogCloseButton />
							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onUpdateClose()}>
									Cancel
								</Button>
								<Button
									type="submit"
									size={"sm"}
									onClick={() => {
										updateOrgType();
										onUpdateClose();
									}}
									ml={3}
								>
									Update
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				<Box
					padding={"10px"}
					borderWidth={1}
					borderRadius={8}
					boxShadow="lg"
					rounded="xl"
				>
					<HStack justifyContent={"space-between"}>
						<Badge>
							<Heading as="h6" size="xs">
								Organization type
							</Heading>
						</Badge>
						<HStack>
							{infoEdit === false ? (
								<IconButton
									onClick={toggleInfoEdit}
									size="xs"
									icon={<EditIcon />}
								/>
							) : (
								<>
									<IconButton
										onClick={toggleInfoEdit}
										size="xs"
										icon={<CloseIcon />}
									/>
									<IconButton
										size="xs"
										icon={<CheckIcon />}
										onClick={() => onUpdateOpen()}
									/>
								</>
							)}
						</HStack>
					</HStack>
					<Divider />
					{infoEdit === false ? (
						<VStack align={"stretch"} px={5}>
							<VStack
								align={"stretch"}
								justifyContent="flex-start"
								pb={5}
								pt={3}
							>
								<Heading as="h2" size="xl">
									{orgTypeProfile.type_name}
								</Heading>
								<Heading as="h4" size="md">
									Organization Type ID: {orgTypeProfile.id}
								</Heading>
								<br />
								<UnorderedList pl={5}>
									<ListItem>
										Created on:{" "}
										{new Date(orgTypeProfile.created_at).toLocaleDateString()}
									</ListItem>
									<ListItem>
										Updated on:{" "}
										{new Date(orgTypeProfile.updated_at).toLocaleDateString()}
									</ListItem>
								</UnorderedList>
							</VStack>
							<Divider />
							<br />
							<HStack justifyContent={"center"}>
								<Button
									rightIcon={<DeleteIcon />}
									w={"10%"}
									onClick={() => {
										onOpen();
									}}
								>
									Delete
								</Button>
							</HStack>
						</VStack>
					) : (
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Organization Type</FormLabel>
									<Input
										value={orgType.org_type}
										type="text"
										name="Organization TypeName"
										placeholder="Org Type Name"
										onChange={(e) => {
											setOrgtype({
												id: orgTypeProfile.id,
												org_type: e.target.value,
											});
										}}
									/>
								</FormControl>
							</VStack>
						</VStack>
					)}
				</Box>
			</VStack>
		</Navigation>
	);
};

export default OrgTypesProfile;
