import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { TextInput } from "components/ChakraFormikComponents";
import { Field } from "formik";
import React from "react";

function WebsiteSection({ personId, websites = [], setFieldValue = () => {} }) {
	return (
		<>
			{websites?.map((website, index) => (
				<VStack
					key={`${personId}-website-${index}`}
					sx={{
						background: "rgba(232,233,235,0.35)",
						borderRadius: "0.5rem",
						padding: "1rem",
					}}
					width="100%"
					gap={2}
				>
					<HStack gap={4} width="100%">
						<Field
							component={TextInput}
							name={`websites[${index}].website_url`}
							label="URL"
							type="url"
							placeholder="https://example.com"
							required
						/>
						<IconButton
							size="sm"
							icon={<DeleteIcon />}
							sx={{
								alignSelf: "flex-end",
							}}
							onClick={() => {
								const updatedValue = [...websites];
								updatedValue.splice(index, 1);
								setFieldValue(updatedValue);
							}}
							variant="outline"
						/>
					</HStack>
				</VStack>
			))}
			<Button
				leftIcon={<AddIcon />}
				size="sm"
				style={{
					marginTop: "1.5rem",
					alignSelf: "flex-start",
				}}
				onClick={() => {
					setFieldValue([
						...websites,
						{
							website_url: "",
						},
					]);
				}}
			>
				Add Website
			</Button>
		</>
	);
}

export default WebsiteSection;
