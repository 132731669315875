import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Divider,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";

const AddressSec = ({ peopleProfile }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Address
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
				<UnorderedList pl={10} style={{ listStyleType: "disc" }}>
					{peopleProfile.addresses?.map((ad) => (
						<ListItem key={ad.id} display={"inline-block"} mr={10}>
							<VStack w={"full"} textAlign="left" pb={3}>
								<HStack w="100%">
									<Text>
										{ad.address_1}, {ad.address_2}
									</Text>
									<Text>
										<Badge size={"xs"} variant="outline">
											{ad.address_type}
										</Badge>
									</Text>
								</HStack>
								<Text w="100%">Country: {ad.country}</Text>
								<Text w="100%">
									State/Province/Region: {ad.state_province_region}
								</Text>
								<Text w="100%">City/Town: {ad.city_town}</Text>
								<Text w="100%">Zip/Postal Code: {ad.zip_postal_code}</Text>
							</VStack>
						</ListItem>
					))}
				</UnorderedList>
			</VStack>
		</>
	);
};

export default AddressSec;
