import { Badge, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React from "react";

function SubAward({
	awardId = null,
	subcontractor = null,
	awardAmount = null,
	amount = null,
	issueDate = null,
	startDate = null,
	endDate = null,
	onCancel,
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	submitBtnText = "Add Sub Award",
}) {
	const { data: subcontractorData } = useFetch("/api/org/getAllOrgs");
	return (
		<Formik
			initialValues={{
				awardId,
				subcontractor,
				awardAmount,
				amount,
				issueDate,
				startDate,
				endDate,
			}}
			enableReinitialize
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const body = { ...values };
					delete body.awardAmount;
					const res = await onSubmit(body);
					await onSuccess(res);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<VStack gap={3} alignItems="stretch">
						<Field
							name="subcontractor"
							component={MultiSelect}
							label="Sub Contractor"
							placeholder="Select Sub Contractor"
							options={(subcontractorData || []).map((sc) => ({
								value: sc.id,
								label: sc.organization_name,
							}))}
							required
						/>

						<HStack gap={3}>
							<Text pt={1} w={"50%"}>
								Award Amount:{" "}
								<Badge colorScheme="green">
									${awardAmount}
									.00
								</Badge>
							</Text>
							<Field
								type={"number"}
								name="amount"
								component={TextInput}
								label="Sub Award Amount"
								placeholder="Sub Award Amount"
								leftAddon="$"
								required
							/>
						</HStack>

						<HStack gap={3}>
							<Field
								name="issueDate"
								component={TextInput}
								label="Issue Date"
								type="date"
								required
							/>
							<Field
								name="startDate"
								component={TextInput}
								label="Start Date"
								type="date"
								required
							/>
							<Field
								name="endDate"
								component={TextInput}
								label="End Date"
								type="date"
								required
							/>
						</HStack>
						<HStack gap={3} justifyContent={"flex-end"}>
							<Button onClick={onCancel} size="xs">
								Cancel
							</Button>
							<Button
								colorScheme="teal"
								type="submit"
								isLoading={isSubmitting}
								size="xs"
							>
								{submitBtnText}
							</Button>
						</HStack>
					</VStack>
				</Form>
			)}
		</Formik>
	);
}

export default SubAward;
