import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import InfoSec from "./InfoSec";
import EmailSec from "./EmailSec";
import PhoneSec from "./PhoneSec";
import WebsiteSec from "./WebsiteSec";
import AddressSec from "./AddressSec";
import {
	HStack,
	VStack,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";

const Profile = ({ id, getOrgProfile, orgProfile, orgType }) => {
	// const { id } = useParams();
	// const [orgProfile, setOrgProfile] = useState({});
	// const [orgType, setOrgType] = useState("");
	const { toastMsg, apiCall } = useContext(AccountContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const navigate = useNavigate();

	// //func to get single org profile
	// const getOrgProfile = async (orgID) => {
	//   let url = `${getBaseUrl()}/api/org/getOrgInfo/${orgID}`;
	//   const { data } = await axios.get(url);
	//   setOrgProfile(data);
	//   return;
	// };

	//func to delete org
	const deleteOrg = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/org/deleteOrg`;
			await apiCall(url, "POST", { id: id }).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/organizations");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	// useEffect(() => {
	//   getOrgProfile(id);
	//   window.scrollTo(0, 0);
	// }, []);

	// useEffect(() => {
	//   const getOrgType = async (orgProfile) => {
	//     let url = `${getBaseUrl()}/api/org/getOrgTypeById/${
	//       orgProfile.organization_type_id
	//     }`;
	//     await axios.get(url).then((res) => {
	//       setOrgType(res.data);
	//       return;
	//     });
	//   };
	//   if (orgProfile.organization_type_id !== null) {
	//     getOrgType(orgProfile);
	//   }
	// }, [orgProfile]);

	// console.log("orgProfile", orgProfile);
	// console.log("orgType", orgType);

	return (
		<VStack gap={1} align={"stretch"} pb={20}>
			<AlertDialog
				isOpen={isOpen}
				onClose={() => onClose()}
				closeOnOverlayClick={false}
				motionPreset="slideInBottom"
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Delete Organization
						</AlertDialogHeader>
						<AlertDialogCloseButton />
						<AlertDialogBody>
							Are you sure?
							<br />
							Confirm to Delete Org:{"  "}
							<b>"{orgProfile.organization_name}"</b>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button size={"sm"} onClick={() => onClose()}>
								Cancel
							</Button>
							<Button
								size={"sm"}
								isLoading={addBtnStatus}
								loadingText="Deleting"
								onClick={() => {
									deleteOrg();
								}}
								ml={3}
							>
								Delete
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<InfoSec
				orgProfile={orgProfile}
				getOrgProfile={getOrgProfile}
				id={id}
				orgType={orgType}
			/>
			<EmailSec orgProfile={orgProfile} getOrgProfile={getOrgProfile} id={id} />
			<PhoneSec orgProfile={orgProfile} getOrgProfile={getOrgProfile} id={id} />
			<WebsiteSec
				orgProfile={orgProfile}
				getOrgProfile={getOrgProfile}
				id={id}
			/>
			<AddressSec
				orgProfile={orgProfile}
				getOrgProfile={getOrgProfile}
				id={id}
			/>
			<HStack justifyContent={"center"}>
				<Button
					rightIcon={<DeleteIcon />}
					w={"10%"}
					onClick={() => {
						onOpen();
					}}
				>
					Delete
				</Button>
			</HStack>
		</VStack>
	);
};

export default Profile;
