import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field } from "formik";
import React from "react";

function JobSection({
	personId,
	jobList = [],
	organizationsData = [],
	setFieldValue = () => {},
}) {
	return (
		<>
			{jobList?.map((job, index) => {
				if (typeof job.organization_id === "string")
					job.organization_id = parseInt(job.organization_id, 10);
				if (job.start_date && job.start_date.split("-").length < 3) {
					job.start_date = job.start_date + "-01";
				}
				if (job.end_date && job.end_date.split("-").length < 3) {
					job.end_date = job.end_date + "-01";
				}
				return (
					<VStack
						key={`${personId}-job-${index}`}
						sx={{
							background: "rgba(232,233,235,0.35)",
							borderRadius: "0.5rem",
							padding: "1rem",
						}}
						width="100%"
						gap={2}
					>
						<IconButton
							size="sm"
							icon={<DeleteIcon />}
							sx={{
								alignSelf: "flex-end",
							}}
							onClick={() => {
								const updatedValue = [...jobList];
								updatedValue.splice(index, 1);
								setFieldValue(updatedValue);
							}}
							variant="outline"
						/>
						<HStack gap={4} width="100%">
							<Field
								component={TextInput}
								name={`jobList[${index}].job_title`}
								label="Job Title"
								required
							/>
							<Field
								name={`jobList[${index}].organization_id`}
								component={MultiSelect}
								label="Organization"
								placeholder="Select Organization"
								options={(organizationsData || []).map((org) => ({
									value: org.org_id,
									label: org.organization_name,
								}))}
							/>
						</HStack>
						<HStack gap={4} width="100%">
							<Field
								name={`jobList[${index}].start_date`}
								component={TextInput}
								label="Start Date"
								type="date"
							/>
							{job.current !== "yes" && (
								<Field
									name={`jobList[${index}].end_date`}
									component={TextInput}
									label="End Date"
									type="date"
								/>
							)}
							<Field
								component={MultiSelect}
								name={`jobList[${index}].current`}
								label="Current"
								placeholder="Select option"
								options={[
									{ value: "yes", label: "Yes" },
									{ value: "no", label: "No" },
								]}
							/>
							<Field
								component={MultiSelect}
								name={`jobList[${index}].visibility`}
								label="Visibility"
								placeholder="Select option"
								options={[
									{ value: "yes", label: "Yes" },
									{ value: "no", label: "No" },
								]}
							/>
						</HStack>
					</VStack>
				);
			})}
			<Button
				leftIcon={<AddIcon />}
				size="sm"
				style={{
					marginTop: "1.5rem",
					alignSelf: "flex-start",
				}}
				onClick={() => {
					setFieldValue([
						...jobList,
						{
							job_title: "",
							organization_id: "",
							start_date: "",
							end_date: "",
							current: "no",
							visibility: "",
						},
					]);
				}}
			>
				Add Job
			</Button>
		</>
	);
}

export default JobSection;
