import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { CSVLink } from "react-csv";
import dummyImg from "assets/profile.jpeg";

function ActiveResearchers() {
	const { data, loading, error } = useFetch(
		"/api/researchTask/allActiveResearchers"
	);

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting research tasks data</p>;
		data.forEach((dt) => {
			dt.updated_at = new Date(dt.updated_at).toLocaleDateString();
			if (Array.isArray(dt.researchTasks)) {
				dt.roles = dt.researchTasks.map((rt) => rt.role).join(", ");
				dt.researchTasks = dt.researchTasks.map((rt) => rt.taskName).join(", ");
			}
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						title: "IMG",
						width: "10%",
						sortable: false,
						render: (data, type, row) => {
							return `<img src="${
								row.image_s3 === null ? dummyImg : row.image_s3
							}" onerror="this.onerror=null; this.src='${dummyImg}'" alt="img"/>`;
						},
					},
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Prefix",
						data: "prefix",
					},
					{
						className: "dt-body-left",
						title: "First Name",
						data: "first_name",
					},
					{ className: "dt-body-left", title: "Last Name", data: "last_name" },
					{
						className: "dt-body-left",
						title: "Research Task",
						data: "researchTasks",
					},
					{
						className: "dt-body-left",
						title: "Role",
						data: "roles",
					},
				]}
				theaders={[
					"IMG",
					"ID",
					"Prefix",
					"First Name",
					"Last Name",
					"researchTasks",
					"roles",
				]}
				navigatePath="/people"
				tableId="viewActiveResearcher"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Active Researchers"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Active Researchers
					</Highlight>
				</Heading>

				<CSVLink
					data={data}
					headers={[
						{ label: "ID", key: "id" },
						{ label: "Prefix", key: "prefix" },
						{ label: "First Name", key: "first_name" },
						{ label: "Last Name", key: "last_name" },
						{ label: "Research Task", key: "researchTasks" },
						{ label: "Updated On", key: "updated_at" },
					]}
					filename="SERC_Active_Researchers_Data"
					target="_blank"
				>
					<Button size="xs">Export</Button>
				</CSVLink>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
}

export default ActiveResearchers;
