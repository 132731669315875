import Axios from "axios";
import { getBaseUrl } from "base";

const baseURL = getBaseUrl();

const axios = Axios.create({
	baseURL,
	withCredentials: true,
});

export const handleError = (error) => {
	if (error.response?.data?.message) return error.response.data.message;
	if (error.message) return error.message;
	return error;
};

/**
 *
 * @param {string} endpoint to which the API request is to be made
 * @param {object} params query parameters
 * @returns response data or error response
 */
export const GET = async (endpoint, params = {}, headers = {}) => {
	const { data } = await axios.get(endpoint, { params, headers });
	return data;
};

/**
 *
 * @param {string} endpoint to which the API request is to be made
 * @param {object} body request body
 * @param {object} params query parameters
 * @returns
 */
export const POST = async (endpoint, body = {}, params = {}, headers = {}) => {
	const { data } = await axios.post(endpoint, body, {
		params,
		headers,
	});
	return data;
};

/**
 *
 * @param {string} endpoint to which the API request is to be made
 * @param {object} body request body
 * @param {object} params query parameters
 * @returns
 */
export const PUT = async (endpoint, body = {}, params = {}, headers = {}) => {
	const { data } = await axios.put(endpoint, body, {
		params,
		headers,
	});
	return data;
};

/**
 *
 * @param {string} endpoint to which the API request is to be made
 * @param {object} body request body
 * @param {object} params query parameters
 * @returns
 */
export const PATCH = async (endpoint, body = {}, params = {}, headers = {}) => {
	const { data } = await axios.patch(endpoint, body, {
		params,
		headers,
	});
	return data;
};

/**
 *
 * @param {string} endpoint to which the API request is to be made
 * @param {object} body request body
 * @param {object} params query parameters
 * @returns
 */
export const DELETE = async (
	endpoint,
	body = {},
	params = {},
	headers = {}
) => {
	const { data } = await axios.delete(endpoint, {
		data: body,
		params,
		headers,
	});
	return data;
};

/**
 * Types of axios request possible
 * @typedef {('GET'|'POST'|'PUT'|'DELETE' | 'PATCH')} requestType
 */

export const requestTypes = {
	GET: "GET",
	POST: "POST",
	PUT: "PUT",
	DELETE: "DELETE",
	PATCH: "PATCH",
};

Object.freeze(requestTypes);

export const createResearchTask = async (body) =>
	POST("/api/researchTask/createResearchTask", body);

export const updateResearchTask = async (body) =>
	POST("/api/researchTask/updateResearchTask", body);

export const createPublication = async (body) =>
	POST(
		"/api/publications/createPublication",
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const updatePublication = async (id, body) =>
	POST(
		`/api/publications/updatePublication/${id}`,
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const createPublicationCategory = async (category) =>
	POST("/api/publications/categories", { category });

export const updatePublicationImg = async (body) =>
	POST(
		"/api/publications/updatePublicationImg",
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const updatePublicationDocument = async (body) =>
	POST(
		"/api/publications/updatePublicationDocumentFile",
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

//publication types APIs
export const createPublicationType = async (category) =>
	POST("/api/publications/categories", category);

export const updatePublicationType = async (id, body) =>
	PUT(`/api/publications/categories/${id}`, body);

//Finance APIs
export const createFinanceRecord = async (body) => POST("/api/finance", body);
export const createSubAward = async (body) =>
	POST("/api/finance/awards/subAwards/", body);

export const updateSubAward = async (id, body) =>
	PUT(`/api/finance/awards/subAwards/${id}`, body);

export const quickAddPerson = async (body) =>
	POST("/api/people/quickCreatePerson", body);

export const updatePerson = async (body) =>
	POST("api/people/updatePerson", body);

export const addPersonToResearchTask = (body) =>
	POST("/api/researchTask/addPersonRoleResearchTask", body);

export const updateResearchTaskPerson = (body) =>
	POST("/api/researchTask/updatePersonRoleResearchTask", body);

export const deleteResearchTaskPerson = (id) =>
	POST("/api/researchTask/deletePersonRoleResearchTask", { id });
//Technical Report APIs
export const createTechnicalReports = async (body) =>
	POST(
		"/api/technicalReports/",
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const updateTechnicalReports = async (id, body) =>
	PUT(
		`/api/technicalReports/${id}`,
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);
export const updateTechnicalReportImg = async (id, body) =>
	PUT(
		`/api/technicalReports/image/${id}`,
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const updateTechnicalReportDocument = async (id, body) =>
	PUT(
		`/api/technicalReports/file/${id}`,
		body,
		{},
		{ "Content-Type": "multipart/form-data" }
	);

export const deletePerson = async (id) => DELETE(`/api/people/${id}`);

export const quickCreateOrg = async (organizationName, organizationTypeId) =>
	POST("/api/org/quickCreateOrg", {
		organizationName,
		organizationTypeId,
	});
