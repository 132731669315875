import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field } from "formik";
import React from "react";
import countryList from "utils/data/Country-State.json";

function AddressSection({
	personId,
	addressList = [],
	setFieldValue = () => {},
}) {
	return (
		<>
			{addressList?.map((job, index) => {
				let stateList = [];
				if (addressList[index].country) {
					stateList =
						countryList.find(
							(country) => country.country_id === addressList[index].country
						)?.states ?? [];
				}
				return (
					<VStack
						key={`${personId}-address-${index}`}
						sx={{
							background: "rgba(232,233,235,0.35)",
							borderRadius: "0.5rem",
							padding: "1rem",
						}}
						width="100%"
						gap={2}
					>
						<IconButton
							size="sm"
							icon={<DeleteIcon />}
							sx={{
								alignSelf: "flex-end",
							}}
							onClick={() => {
								const updatedValue = [...addressList];
								updatedValue.splice(index, 1);
								setFieldValue(updatedValue);
							}}
							variant="outline"
						/>
						<HStack gap={4} width="100%">
							<Field
								component={TextInput}
								name={`addressList[${index}].address_1`}
								label="Address Line 1"
								required
							/>
							<Field
								component={TextInput}
								name={`addressList[${index}].address_2`}
								label="Address Line 2"
							/>
						</HStack>
						<HStack gap={4} width="100%">
							<Field
								name={`addressList[${index}].country`}
								component={MultiSelect}
								label="Country"
								placeholder="Select Country"
								options={(countryList || []).map((country) => ({
									value: country.country_id,
									label: country.country_name,
								}))}
								required
							/>
							<Field
								name={`addressList[${index}].state_province_region`}
								component={MultiSelect}
								label="State/Province/Region"
								placeholder="Select State/Province/Region"
								options={(stateList || []).map((state) => ({
									value: state.state_id,
									label: state.state_name,
								}))}
								required
							/>
						</HStack>
						<HStack gap={4} width="100%">
							<Field
								name={`addressList[${index}].city_town`}
								component={TextInput}
								label="City/Town"
								required
							/>
							<Field
								name={`addressList[${index}].zip_postal_code`}
								component={TextInput}
								label="Zip/Postal Code"
								required
							/>
							<Field
								component={MultiSelect}
								name={`addressList[${index}].address_type`}
								label="Address Type"
								placeholder="Select option"
								options={[
									{ value: "Personal", label: "Personal" },
									{ value: "Work", label: "Work" },
									{ value: "Other", label: "Other" },
								]}
								required
							/>
						</HStack>
					</VStack>
				);
			})}
			<Button
				leftIcon={<AddIcon />}
				size="sm"
				style={{
					marginTop: "1.5rem",
					alignSelf: "flex-start",
				}}
				onClick={() => {
					setFieldValue([
						...addressList,
						{
							address_1: "",
							address_2: "",
							city_town: "",
							state_province_region: "",
							zip_postal_code: "",
							country: "",
							address_type: "",
							stateList: [],
						},
					]);
				}}
			>
				Add Address
			</Button>
		</>
	);
}

export default AddressSection;
