import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
	IconButton,
	Td,
	Tr,
	HStack,
	Table,
	Tbody,
	Box,
	Thead,
	Th,
	VStack,
	FormControl,
	FormLabel,
	Input,
	InputLeftAddon,
	InputGroup,
	Divider,
	Button,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	AccordionItem,
	Text,
} from "@chakra-ui/react";
import Modal from "components/Modal";
import CustomAlertDialog from "components/CustomAlertDialog";
import { AccountContext } from "contexts/AccountContext";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { createSubAward, DELETE, PUT, updateSubAward } from "utils/api";
import SubAward from "components/Forms/Finance/SubAward";

const EditAward = ({ awd, sponsorList, refetch }) => {
	const { toastMsg } = useContext(AccountContext);
	const [awardObj, setAwardObj] = useState({});
	const [isEditing, setEditing] = useState(false);
	const toggleInfoEdit = () => {
		setEditing(!isEditing);
	};

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isUpdateOpen, setIsUpdateOpen] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [addSubAwardModal, setAddSubAwardModal] = useState(false);
	const [editSubAward, setEditSubAward] = useState({});
	const [editSubAwardModal, setEditSubAwardModal] = useState(false);

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setAwardObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	useEffect(() => {
		let coreVal = awd.cores?.map((i) => {
			return { value: i.core, label: i.core };
		});
		let sprVal = awd.sponsors?.map((i) => {
			return { value: i.sponsorId, label: i.sponsor };
		});

		setAwardObj({
			cores: awd.cores?.map((i) => i.core),
			sponsors: awd.sponsors?.map((i) => i.sponsorId),
			awardDate: awd.awardDate,
			totalAwardAmount: awd.totalAwardAmount,
			incrementCount: awd.increment,
			defSpr: sprVal,
			defCore: coreVal,
		});
	}, [isEditing === false]);

	const handleDelete = async () => {
		try {
			setIsDeleteLoading(true);
			await DELETE(`/api/finance/awards/${awd.id}`).then((data) => {
				setIsDeleteOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				refetch();
				setIsDeleteLoading(false);
				return;
			});
		} catch (error) {
			setIsDeleteOpen(false);
			setIsDeleteLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	const handleDeleteSubAward = async (id) => {
		try {
			await DELETE(`/api/finance/awards/subAwards/${id}`).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				refetch();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	const handleUpdate = async () => {
		try {
			setIsUpdateLoading(true);
			delete awardObj.defCore;
			delete awardObj.defSpr;
			await PUT(`/api/finance/awards/${awd.id}`, awardObj).then((data) => {
				setEditing(false);
				setIsUpdateOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				refetch();
				setIsUpdateLoading(false);
				return;
			});
		} catch (error) {
			setIsUpdateOpen(false);
			setIsUpdateLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

		return (
			<Box
				key={awd.id}
				padding="10px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
				width="100%"
			>
				{isEditing === false ? (
					<VStack w={"full"} px={5} pb={2}>
						<CustomAlertDialog
							title="Delete Award Record"
							message="Are you sure?  Confirm to delete"
							isOpen={isDeleteOpen}
							onClose={() => setIsDeleteOpen(false)}
							onConfirm={handleDelete}
							isLoading={isDeleteLoading}
							confirmText="Delete"
						/>
						<HStack
							justifyContent="space-between"
							style={{
								width: "100%",
							}}
						>
							<Divider />
							<HStack>
								<Button
									size={"xs"}
									leftIcon={<AddIcon />}
									onClick={() => setAddSubAwardModal(true)}
								>
									Sub Award
								</Button>
								<IconButton
									size={"xs"}
									icon={<EditIcon />}
									onClick={toggleInfoEdit}
								/>
								<IconButton
									size={"xs"}
									icon={<DeleteIcon />}
									onClick={() => setIsDeleteOpen(true)}
								/>
							</HStack>
						</HStack>
						<Table size={"sm"} variant="unstyled">
							<Tbody>
								<Tr>
									<Th width={"30%"}>Award Date</Th>
									<Td>{awd.awardDate}</Td>
								</Tr>
								<Tr>
									<Th>Sponsors</Th>
									<Td>
										{awd.sponsors?.map((s, idx) => (
											<p key={idx}>{s.sponsor}</p>
										))}
									</Td>
								</Tr>
								<Tr>
									<Th>Core</Th>
									<Td>
										{awd.cores?.map((c, idx) => (
											<p key={idx}>{c.core}</p>
										))}
									</Td>
								</Tr>
								<Tr>
									<Th>Award Amount</Th>
									<Td>${awd.totalAwardAmount}.00</Td>
								</Tr>
								<Tr>
									<Th>Increment Number</Th>
									<Td>#{awd.increment}</Td>
								</Tr>
							</Tbody>
						</Table>
						<Modal
							isOpen={Boolean(addSubAwardModal)}
							header="Add Sub Award"
							onClose={() => {
								setAddSubAwardModal(false);
							}}
						>
							<SubAward
								awardId={awd.id}
								awardAmount={awd.totalAwardAmount}
								submitBtnText="Add Sub Award"
								onCancel={() => {
									setAddSubAwardModal(false);
								}}
								onSubmit={async (body) => createSubAward(body)}
								onSuccess={(data) => {
									refetch();
									setAddSubAwardModal(false);
									toastMsg({
										title: "Success!",
										desc: data.message,
										type: "success",
									});
								}}
								onError={(e) => {
									toastMsg({
										title: "Error!",
										desc: e.response.data.message,
										type: "error",
									});
								}}
							/>
						</Modal>
						<Modal
							isOpen={Boolean(editSubAwardModal)}
							header="Edit Sub Award"
							onClose={() => {
								setEditSubAwardModal(false);
							}}
						>
							<SubAward
								awardId={awd.id}
								awardAmount={awd.totalAwardAmount}
								subcontractor={editSubAward.subcontractor}
								amount={editSubAward.amount}
								issueDate={editSubAward.issueDate}
								startDate={editSubAward.startDate}
								endDate={editSubAward.endDate}
								submitBtnText="Edit Sub Award"
								onCancel={() => {
									setEditSubAwardModal(false);
								}}
								onSubmit={async (body) => updateSubAward(editSubAward.id, body)}
								onSuccess={(data) => {
									refetch();
									setEditSubAwardModal(false);
									toastMsg({
										title: "Success!",
										desc: data.message,
										type: "success",
									});
								}}
								onError={(e) => {
									toastMsg({
										title: "Error!",
										desc: e.response.data.message,
										type: "error",
									});
								}}
							/>
						</Modal>
						<Accordion allowToggle w={"full"}>
							<AccordionItem>
								<AccordionButton>
									<Box
										as="span"
										flex="1"
										textAlign="left"
										fontWeight="bold"
										fontSize={"sm"}
									>
										Sub Awards
									</Box>
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel pb={4}>
									{awd.subAwards !== null ? (
										<Table size={"sm"}>
											<Thead>
												<Tr>
													<Th width={"40%"}>Sub-Contractor</Th>
													<Th>Amount</Th>
													<Th>Issue Date</Th>
													<Th>Start Date</Th>
													<Th>End Date</Th>
													<Th width={"10%"}>Action</Th>
												</Tr>
											</Thead>
											<Tbody>
												{awd.subAwards.map((sa, idx) => (
													<Tr key={idx}>
														<Td>{sa.subcontractor}</Td>
														<Td>${sa.amount}.00</Td>
														<Td>{sa.issueDate}</Td>
														<Td>{sa.startDate}</Td>
														<Td>{sa.endDate}</Td>
														<Td>
															<HStack>
																<IconButton
																	size={"xs"}
																	icon={<EditIcon />}
																	onClick={() => {
																		setEditSubAward({
																			id: sa.id,
																			subcontractor: sa.subcontractorId,
																			amount: sa.amount,
																			issueDate: sa.issueDate,
																			startDate: sa.startDate,
																			endDate: sa.endDate,
																		});
																		setEditSubAwardModal(true);
																	}}
																/>
																<IconButton
																	size={"xs"}
																	icon={<DeleteIcon />}
																	onClick={() => handleDeleteSubAward(sa.id)}
																/>
															</HStack>
														</Td>
													</Tr>
												))}
											</Tbody>
										</Table>
									) : (
										<Text align={"center"} color={"grey"}>
											List is empty
										</Text>
									)}
								</AccordionPanel>
							</AccordionItem>
						</Accordion>
					</VStack>
				) : (
					<form action="submit">
						<CustomAlertDialog
							title="Update Finance Record"
							message="Are you sure?  Confirm to update"
							isOpen={isUpdateOpen}
							onClose={() => setIsUpdateOpen(false)}
							onConfirm={handleUpdate}
							isLoading={isUpdateLoading}
							confirmText="Update"
							type={"submit"}
						/>
						<VStack gap={4} w={"full"} px={5} pb={5}>
							<FormControl isRequired>
								<FormLabel>Sponsors (Multi Select)</FormLabel>
								<Select
									isMulti
									placeholder="Search or Select multiple sponsors"
									defaultValue={awardObj.defSpr}
									options={sponsorList?.map((i) => {
										return { value: i.id, label: i.organization_name };
									})}
									onChange={(e) => {
										let sprL = [];
										e.forEach((s) => {
											sprL.push(s.value);
										});
										setAwardObj((prev) => {
											return { ...prev, sponsors: sprL };
										});
									}}
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Cores (Multi Select)</FormLabel>
								<Select
									isMulti
									placeholder="Select multiple cores"
									defaultValue={awardObj.defCore}
									options={[
										{ value: "Core", label: "Core" },
										{
											value: "Non-Core",
											label: "Non-Core",
										},
									]}
									onChange={(e) => {
										let coreL = [];
										e.forEach((c) => {
											coreL.push(c.value);
										});
										setAwardObj((prev) => {
											return {
												...prev,
												cores: coreL,
											};
										});
									}}
								/>
							</FormControl>
							<HStack gap={5} w={"full"}>
								<FormControl isRequired>
									<FormLabel>Award Amount</FormLabel>
									<InputGroup>
										<InputLeftAddon children="$" />
										<Input
											value={awardObj.totalAwardAmount}
											name="totalAwardAmount"
											type="number"
											placeholder="Enter amount"
											onChange={handleInfoChange}
										/>
									</InputGroup>
								</FormControl>
								<FormControl isRequired>
									<FormLabel>Increment Number</FormLabel>
									<InputGroup>
										<InputLeftAddon children="#" />
										<Input
											value={awardObj.incrementCount}
											name="incrementCount"
											type="number"
											placeholder="Enter amount"
											onChange={handleInfoChange}
										/>
									</InputGroup>
								</FormControl>
								<FormControl>
									<FormLabel>Award Date</FormLabel>
									<Input
										value={awardObj.awardDate}
										type="date"
										name="awardDate"
										onChange={handleInfoChange}
									/>
								</FormControl>
							</HStack>
							<HStack gap={3} justifyContent={"center"} pt={5}>
								<Button
									size="xs"
									onClick={() => {
										toggleInfoEdit();
									}}
								>
									Cancel
								</Button>
								<Button
									size="xs"
									colorScheme="teal"
									type="submit"
									loadingText="Adding Increment"
									onClick={(e) => {
										e.preventDefault();
										setIsUpdateOpen(true);
									}}
								>
									Update Increment
								</Button>
							</HStack>
						</VStack>
					</form>
				)}
			</Box>
		);
};

export default EditAward;
