import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import TechnicalReport from "./TechnicalReport";

function SingleTechnicalReport() {
	const { id } = useParams();
	const { data, error, loading } = useFetch(`/api/technicalReports/${id}`);
	const renderPageData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error || !data) return <p>Error getting technical report data</p>;
		return <TechnicalReport technicalReportData={data} />;
	}, [data, error, loading]);

	return <Navigation>{renderPageData()}</Navigation>;
}

export default SingleTechnicalReport;
