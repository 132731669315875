import { Box, Button, HStack, VStack, useDisclosure } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import InfoSec from "./InfoSec";
import JobSec from "./JobSec";
import EmailSec from "./EmailSec";
import PhoneSec from "./PhoneSec";
import WebsiteSec from "./WebsiteSec";
import AddressSec from "./AddressSec";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";
import { deletePerson, updatePerson } from "utils/api";
import { DeleteIcon } from "@chakra-ui/icons";
import Alert from "components/Alert";
import { useNavigate } from "react-router-dom";
import EditPerson from "components/Forms/EditPerson";

const Profile = ({ id, getPeopleProfile, peopleProfile }) => {
	const navigate = useNavigate();
	const { apiCall } = useContext(AccountContext);
	const [rolesList, setRolesList] = useState([]);
	const [orgsList, setOrgsList] = useState([]);

	const [isEditing, setEditing] = useState(false);
	const toggleEdit = () => {
		setEditing(!isEditing);
	};

	useEffect(() => {
		getPeopleProfile(id);
	}, []);

	//func to get list of orgs
	const getAllOrgs = async () => {
		let url = `${getBaseUrl()}/api/org/getAllOrgs`;
		const { data } = await apiCall(url);

		data.forEach((dt) => {
			dt.created_at = new Date(dt.created_at).toLocaleDateString();
			dt.updated_at = new Date(dt.updated_at).toLocaleDateString();
		});
		return data;
	};

	//func to get list of roles
	const getAllRoles = async () => {
		let url = `${getBaseUrl()}/api/roles/getAllRoles`;
		const { data } = await apiCall(url);
		return data;
	};

	useEffect(() => {
		getAllRoles().then((res) => {
			setRolesList(res);
		});
	}, []);

	useEffect(() => {
		getAllOrgs().then((res) => {
			setOrgsList(res);
		});
	}, []);

	const handleUpdate = async (updatedValue) => {
		try {
			await updatePerson(updatedValue);
			await getPeopleProfile(id);
			setEditing(false);
		} catch (e) {
			console.error(e);
		}
	};

	const handleDeletePerson = async () => {
		try {
			await deletePerson(id);
			navigate("/people");
		} catch (e) {
			console.error(e);
		}
	};

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
			px={5}
		>
			{isEditing ? (
				<EditPerson
					organizationsData={orgsList}
					setOrganizationsData={setOrgsList}
					rolesData={rolesList}
					peopleProfile={peopleProfile}
					personId={peopleProfile.id}
					handleUpdate={handleUpdate}
					onCancel={() => {
						setEditing(false);
					}}
				/>
			) : (
				<>
					<Alert
						isOpen={isDeleteOpen}
						onClose={() => onDeleteClose()}
						header="Delete Person"
						body={
							<>
								Are you sure?
								<br />
								Confirm to delete the person
							</>
						}
						onSuccessClick={handleDeletePerson}
						successBtnText="Delete"
					/>
					<VStack gap={1} align={"stretch"} pb={10}>
						<InfoSec
							peopleProfile={peopleProfile}
							toggleInfoEdit={toggleEdit}
						/>
						<JobSec peopleProfile={peopleProfile} />
						<EmailSec peopleProfile={peopleProfile} />
						<PhoneSec peopleProfile={peopleProfile} />
						<WebsiteSec peopleProfile={peopleProfile} />
						<AddressSec peopleProfile={peopleProfile} />
						<HStack justifyContent={"center"}>
							<Button
								rightIcon={<DeleteIcon />}
								w={"10%"}
								onClick={() => {
									onDeleteOpen();
								}}
							>
								Delete
							</Button>
						</HStack>
					</VStack>
				</>
			)}
		</Box>
	);
};

export default Profile;
