import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "components/Layout/Navigation";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
	VStack,
	Badge,
	Text,
	IconButton,
	FormControl,
	FormLabel,
	Input,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	useDisclosure,
	AlertDialogCloseButton,
	Icon,
	Switch,
	TableContainer,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Select from "react-select";
import { FaUserAlt } from "react-icons/fa";
import AccessDenied from "pages/AccessDenied";
import { HiRefresh } from "react-icons/hi";
import CustomAlertDialog from "components/CustomAlertDialog";
import { PUT } from "utils/api";

const ViewUser = () => {
	const { apiCall, toastMsg, session } = useContext(AccountContext);
	const { id } = useParams();
	const [userObj, setUserObj] = useState({});
	const [infoObj, setInfoObj] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const [groupList, setGroupList] = useState([]);
	const navigate = useNavigate();

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();

	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	//func to get single user
	const getUser = async () => {
		let url = `${getBaseUrl()}/api/users/getUser/${id}`;
		const { data } = await apiCall(url);
		const options = {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true,
		};
		data.last_login === null
			? (data.last_login = "NA")
			: (data.last_login = new Date(data.last_login).toLocaleTimeString(
					"en-us",
					options
			  ));

		if (Array.isArray(data.loginHistory)) {
			data.loginHistory.forEach((h) => {
				h.loginTime = new Date(h.loginTime).toLocaleTimeString(
					"en-us",
					options
				);
			});
		}
		setUserObj(data);
		return;
	};

	//func to get all group list
	const getGroupList = async () => {
		let url = `${getBaseUrl()}/api/accessRoles`;
		const { data } = await apiCall(url);
		setGroupList(data);
		return;
	};

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	useEffect(() => {
		if (!id) {
			return;
		}
		getUser();
		getGroupList();
	}, []);

	useEffect(() => {
		if (!userObj || groupList.length === 0) return;
		let groupVal = [];

		userObj.groups?.forEach((g) => {
			let gObj = groupList.find((i) => i.id === g.id);
			if (gObj !== undefined) {
				groupVal.push({
					value: gObj.id,
					label: gObj.name,
				});
			}
		});

		setInfoObj({
			id: id,
			name: userObj.name,
			groups: userObj.groups?.map((i) => i.id),
			defGP: groupVal,
		});
	}, [userObj, groupList]);

	//func to delete user
	const deleteUser = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/users/deleteUser/${id}`;
			await apiCall(url, "POST").then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/user-group-management");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to update User
	const updateUser = async () => {
		try {
			let url = `${getBaseUrl()}/api/users/updateUser`;
			delete infoObj.defGP;
			await apiCall(url, "POST", infoObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getUser();
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getUser();
			toggleInfoEdit();
			return;
		}
	};

	//func to enable/disable User
	const changeUserStatus = async () => {
		try {
			let changeStatus = userObj.enabled === 1 ? "disable" : "enable";
			let url = `${getBaseUrl()}/api/users/changeStatus/${id}/${changeStatus}`;
			await apiCall(url, "POST").then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getUser();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getUser();
			return;
		}
	};

	//func to change admin status
	const changeUserAdminStatus = async () => {
		try {
			let url = `${getBaseUrl()}/api/users/updateAdminStatus/${id}`;
			let adminObj = { isAdmin: userObj.is_admin === 1 ? false : true };
			await apiCall(url, "POST", adminObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getUser();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getUser();
			return;
		}
	};

	const handleResetPass = async () => {
		try {
			setIsLoading(true);
			await PUT(`/api/users/resetPassword/${id}`).then((data) => {
				setIsOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setIsLoading(false);
				return;
			});
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			{session && session.isAdmin === true ? (
				<VStack gap={1} align={"stretch"}>
					<form>
						<AlertDialog
							isOpen={isDeleteOpen}
							onClose={() => onDeleteClose()}
							closeOnOverlayClick={false}
							motionPreset="slideInBottom"
						>
							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize="lg" fontWeight="bold">
										Delete User
									</AlertDialogHeader>
									<AlertDialogCloseButton />
									<AlertDialogBody>
										Are you sure?
										<br />
										Confirm to delete User
									</AlertDialogBody>

									<AlertDialogFooter>
										<Button size={"sm"} onClick={() => onDeleteClose()}>
											Cancel
										</Button>
										<Button
											type="submit"
											size={"sm"}
											isLoading={addBtnStatus}
											loadingText="Deleting"
											onClick={() => {
												deleteUser();
												onDeleteClose();
											}}
											ml={3}
										>
											Delete
										</Button>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>
						</AlertDialog>
						<AlertDialog
							isOpen={isUpdateOpen}
							onClose={() => onUpdateClose()}
							closeOnOverlayClick={false}
							motionPreset="slideInBottom"
						>
							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize="lg" fontWeight="bold">
										Update User Info
									</AlertDialogHeader>

									<AlertDialogBody>
										Are you sure?
										<br />
										Confirm to update.
									</AlertDialogBody>
									<AlertDialogCloseButton />
									<AlertDialogFooter>
										<Button size={"sm"} onClick={() => onUpdateClose()}>
											Cancel
										</Button>
										<Button
											type="submit"
											size={"sm"}
											onClick={() => {
												updateUser();
												onUpdateClose();
											}}
											ml={3}
										>
											Update
										</Button>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>
						</AlertDialog>
						<HStack justifyContent={"space-between"}>
							<Heading as="h3" size="mx" mb={2}>
								<Highlight
									query="User Management"
									styles={{
										px: "2",
										py: "1",
										rounded: "full",
										bg: "brand.100",
									}}
								>
									User Management
								</Highlight>
							</Heading>
						</HStack>
						<Divider mb={2} />
						<Box
							padding={"15px"}
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
						>
							<HStack justifyContent={"space-between"}>
								<Badge>
									<Heading as="h6" size="xs">
										Information
									</Heading>
								</Badge>
								<HStack>
									{infoEdit === false ? (
										<IconButton
											onClick={toggleInfoEdit}
											size="xs"
											icon={<EditIcon />}
										/>
									) : (
										<></>
									)}
								</HStack>
							</HStack>
							<Divider />
							<br />
							{infoEdit === false ? (
								<VStack align={"stretch"} px={5}>
									<CustomAlertDialog
										title="Password Reset"
										message="Are you sure?  Confirm to reset"
										isOpen={isOpen}
										onClose={() => setIsOpen(false)}
										onConfirm={handleResetPass}
										isLoading={isLoading}
										confirmText="Reset"
									/>
									<Heading as="h2" size="xl" pl={5}>
										<Icon as={FaUserAlt} /> {userObj.name}
									</Heading>
									<VStack gap={3} pb={3}>
										<TableContainer width={"75%"}>
											<Table variant="simple">
												<Tbody>
													<Tr>
														<Th>Email</Th>
														<Td>
															<Text>{userObj.email}</Text>
														</Td>
													</Tr>
													<Tr>
														<Th>Group Member</Th>
														<Td>
															<Text>
																{userObj.roles?.length === 0
																	? "NA"
																	: userObj.roles
																			?.map((g) => g.name)
																			.join(", ")}
															</Text>
														</Td>
													</Tr>
													<Tr>
														<Th>Status</Th>
														<Td>
															<Badge
																colorScheme={
																	userObj.status === "yes" ? "green" : "red"
																}
															>
																{userObj.status === "yes"
																	? "Active"
																	: "Inactive"}
															</Badge>
														</Td>
													</Tr>
													<Tr>
														<Th>Admin</Th>
														<Td>
															<HStack>
																<Badge
																	colorScheme={
																		userObj.is_admin === 1 ? "green" : "red"
																	}
																>
																	{userObj.is_admin === 1 ? "Yes" : "No"}
																</Badge>{" "}
																<Switch
																	colorScheme="teal"
																	isChecked={
																		userObj.is_admin === 1 ? true : false
																	}
																	onChange={changeUserAdminStatus}
																/>
															</HStack>
														</Td>
													</Tr>
													<Tr>
														<Th>Account</Th>
														<Td>
															<HStack>
																<Badge
																	colorScheme={
																		userObj.enabled === 1 ? "green" : "red"
																	}
																>
																	{userObj.enabled === 1
																		? "Enabled"
																		: "Disabled"}
																</Badge>{" "}
																<Switch
																	colorScheme="teal"
																	isChecked={
																		userObj.enabled === 1 ? true : false
																	}
																	onChange={changeUserStatus}
																/>
															</HStack>
														</Td>
													</Tr>
													<Tr>
														<Th>Reset Password</Th>
														<Td>
															<IconButton
																size={"xs"}
																colorScheme="teal"
																icon={<HiRefresh />}
																onClick={() => {
																	setIsOpen(true);
																}}
															/>
														</Td>
													</Tr>
													<Tr>
														<Th>Last Login</Th>
														<Td>
															<Text>{userObj.last_login}</Text>
														</Td>
													</Tr>
													<Tr>
														<Th>Login History</Th>
														<Td>
															{userObj?.loginHistory?.length ? (
																userObj.loginHistory.map((h) => (
																	<Text key={h.id}>{h.loginTime}</Text>
																))
															) : (
																<Text>NA</Text>
															)}
														</Td>
													</Tr>
												</Tbody>
											</Table>
										</TableContainer>
									</VStack>
									<HStack justifyContent={"center"}>
										<Button
											rightIcon={<DeleteIcon />}
											w={"10%"}
											onClick={() => {
												onDeleteOpen();
											}}
										>
											Delete
										</Button>
									</HStack>
								</VStack>
							) : (
								<VStack gap={3} px={3}>
									<FormControl isRequired>
										<FormLabel>User Name</FormLabel>
										<Input
											name="name"
											type="text"
											value={infoObj.name}
											size={"sm"}
											placeholder="User Name"
											onChange={(e) => {
												setInfoObj((prev) => {
													return {
														...prev,
														name: e.target.value,
													};
												});
											}}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Email ID</FormLabel>
										<Input disabled value={userObj.email} size={"sm"} />
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Assign Groups</FormLabel>
										<Select
											isMulti
											placeholder="Search or Select multiple groups"
											defaultValue={infoObj.defGP}
											options={groupList?.map((i) => {
												return {
													value: i.id,
													label: i.name,
												};
											})}
											onChange={(e) => {
												let groupL = [];
												e.forEach((g) => {
													groupL.push(g.value);
												});
												setInfoObj((prev) => {
													return { ...prev, groups: groupL };
												});
											}}
										/>
									</FormControl>
									<HStack gap={3} justifyContent={"center"} pt={5} pb={3}>
										<Button
											size="xs"
											onClick={() => {
												getUser();
												toggleInfoEdit();
											}}
										>
											Cancel
										</Button>
										<Button
											size="xs"
											colorScheme="teal"
											onClick={() => {
												onUpdateOpen();
											}}
										>
											Update User
										</Button>
									</HStack>
								</VStack>
							)}
						</Box>
					</form>
				</VStack>
			) : (
				<AccessDenied />
			)}
		</Navigation>
	);
};

export default ViewUser;
