import React, { useContext, useState } from "react";
import Navigation from "components/Layout/Navigation";
import {
	Heading,
	Highlight,
	HStack,
	Divider,
	Input,
	IconButton,
	InputGroup,
	InputLeftAddon,
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Icon,
} from "@chakra-ui/react";
import { Search2Icon, SmallCloseIcon } from "@chakra-ui/icons";
import { GiArchiveResearch } from "react-icons/gi";
import { BsFillPeopleFill } from "react-icons/bs";
import ResearchTasksReport from "./ResearchTasksReport";
import ResearchersReport from "./ResearchersReport";
import { GET } from "utils/api";
import { AccountContext } from "contexts/AccountContext";
import { getFormattedDateStr } from "utils/helpers";

const Reports = () => {
	const { toastMsg } = useContext(AccountContext);
	const [dateRange, setDateRange] = useState({
		startDate: "",
		endDate: "",
	});

	const [researchersReport, setResearchersReport] = useState([]);
	const [researchTasksReport, setResearchTasksReport] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleOnSearch = async () => {
		setIsLoading(true);
		try {
			const resData = await GET(
				`/api/researchTask/activeResearchersInDateRange?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
			);
			const rtsData = await GET(
				`/api/researchTask/activeRtsInDateRange?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
			);

			if (rtsData.length === 0 && resData.length === 0) {
				toastMsg({
					title: "Reports",
					desc: "No records found!",
					type: "info",
				});
				setIsLoading(false);
				return;
			}

			if (Array.isArray(rtsData)) {
				rtsData.forEach((dt) => {
					dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
					if (Array.isArray(dt.researchThrusts))
						dt.researchThrusts = dt.researchThrusts
							.map((th) => th.research_thrust)
							.join(", ");
					if (Array.isArray(dt.centers)) {
						dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
					}
				});
			}

			if (Array.isArray(resData)) {
				resData.forEach((dt) => {
					if (Array.isArray(dt.researchTasks))
						dt.researchTasks = dt.researchTasks
							.map((rt) => rt.taskName)
							.join(", ");
				});
			}
			setResearchTasksReport(rtsData);
			setResearchersReport(resData);
			setIsLoading(false);
			return;
		} catch (error) {
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	const handleDateChange = (event) => {
		const { name, value } = event.target;
		setDateRange((prevDateRange) => ({
			...prevDateRange,
			[name]: value,
		}));
	};

	return (
		<Navigation>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleOnSearch();
				}}
			>
				<HStack justifyContent={"space-between"}>
					<Heading as="h3" size="mx" mb={2}>
						<Highlight
							query="Reports"
							styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
						>
							Reports
						</Highlight>
					</Heading>

					<HStack gap={1} pb={1}>
						<InputGroup size="xs">
							<InputLeftAddon children="Start Date" rounded="md" />
							<Input
								size="xs"
								rounded="md"
								type="date"
								name="startDate"
								value={dateRange.startDate || ""}
								onChange={handleDateChange}
								isRequired
							/>
						</InputGroup>
						<Divider height="20px" orientation="vertical" />
						<InputGroup size="xs">
							<InputLeftAddon children="End Date" rounded="md" />
							<Input
								size="xs"
								rounded="md"
								type="date"
								name="endDate"
								value={dateRange.endDate || ""}
								onChange={handleDateChange}
								isRequired
							/>
						</InputGroup>
					</HStack>
					<HStack>
						<IconButton
							size="xs"
							aria-label="Clear"
							icon={<SmallCloseIcon />}
							isDisabled={
								dateRange.startDate === "" && dateRange.endDate === ""
							}
							onClick={() => {
								setDateRange({
									startDate: "",
									endDate: "",
								});
								setResearchTasksReport([]);
								setResearchersReport([]);
							}}
						/>

						<IconButton
							size="xs"
							aria-label="Search"
							type="submit"
							icon={<Search2Icon />}
							isLoading={isLoading}
						/>
					</HStack>
				</HStack>
			</form>
			<Divider mb={2} />
			{(researchersReport.length !== 0 || researchTasksReport.length !== 0) && (
				<Tabs
					variant="soft-rounded"
					variantcolor="brand.100"
					orientation="vertical"
					size={"sm"}
				>
					<TabList mr={1}>
						<Tab>
							<Icon boxSize={5} color={"gray.700"} as={GiArchiveResearch} />
						</Tab>
						<Tab>
							<Icon boxSize={5} color={"gray.700"} as={BsFillPeopleFill} />
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
						>
							<ResearchTasksReport
								researchTasksReport={researchTasksReport}
								startDate={dateRange.startDate}
								endDate={dateRange.endDate}
							/>
						</TabPanel>
						<TabPanel
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
						>
							<ResearchersReport
								researchersReport={researchersReport}
								startDate={dateRange.startDate}
								endDate={dateRange.endDate}
							/>
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
		</Navigation>
	);
};

export default Reports;
