import {
	Badge,
	Box,
	Button,
	Divider,
	Grid,
	GridItem,
	HStack,
	VStack,
} from "@chakra-ui/react";
import {
	FileInput,
	MultiSelect,
	RichText,
	TextInput,
} from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createPublicationCategory } from "utils/api";
import { AccountContext } from "contexts/AccountContext";

function PublicationForm({
	category = null,
	title = null,
	img = null,
	img_input = null,
	documentFile = null,
	documentFile_input = null,
	abstract = null,
	description = null,
	primaryAuthors = [],
	secondaryAuthors = [],
	organizations = [],
	sponsors = [],
	centers = [],
	// researchThrusts = [],
	// researchPrograms = [],
	// researchTasks = [],
	projects = [],
	tags = [],
	contractNumber = null,
	documentId = null,
	reportNumber = null,
	publicationDate = null,
	startDate = null,
	endDate = null,
	eventName = null,
	location = null,
	url = null,
	isbn = null,
	productionDate = null,
	publication = null,
	publisher = null,
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	onCancel = () => {},
	submitBtnText = "Add Publication",
	isPublic = "yes",
}) {
	const { data: people } = useFetch("/api/people/getAllPeopleWithOrg");
	const { data: organizationsData } = useFetch("/api/org/getAllOrgs");
	// const { data: researchThrustsData } = useFetch(
	// 	"/api/researchThrusts/getAllResearchThrust"
	// );
	// const { data: researchProgramsData } = useFetch(
	// 	"/api/researchPrograms/getAllResearchPrograms"
	// );
	// const { data: researchTasksData } = useFetch(
	// 	"/api/researchTask/getAllResearchTasks"
	// );
	const { data: projectsData } = useFetch("/api/projects/getAllProjects");
	const { data: tagsData } = useFetch("/api/tag/getAllTags");
	const { data: categoriesData } = useFetch("/api/publications/categories");

	const { toastMsg } = useContext(AccountContext);

	const [categories, setCategories] = useState([]);
	const [creatingNewCategory, setCreatingNewCategory] = useState(false);

	useEffect(() => {
		setCategories(categoriesData);
	}, [categoriesData]);

	const handleCreateCategory = useCallback(
		async (newOption, onSuccess) => {
			setCreatingNewCategory(true);
			try {
				const newCategory = await createPublicationCategory(newOption);
				setCategories((prev) => [...prev, newCategory.category]);
				onSuccess(newOption);
			} catch (e) {
				toastMsg({
					title: "Error!",
					desc: "Error creating new category",
					type: "error",
				});
			} finally {
				setCreatingNewCategory(false);
			}
		},
		[toastMsg]
	);

	return (
		<Formik
			initialValues={{
				category,
				title,
				img,
				img_input,
				documentFile,
				documentFile_input,
				abstract,
				description,
				primaryAuthors,
				secondaryAuthors,
				organizations,
				sponsors,
				centers,
				// researchThrusts,
				// researchPrograms,
				// researchTasks,
				projects,
				tags,
				contractNumber,
				documentId,
				reportNumber,
				publicationDate,
				startDate,
				endDate,
				eventName,
				location,
				url,
				isbn,
				productionDate,
				publication,
				publisher,
				isPublic,
			}}
			enableReinitialize
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const body = { ...values };
					body.public = values.isPublic;
					delete body.isPublic;
					delete body.img_input;
					delete body.documentFile_input;
					Object.entries(body).forEach(([key, val]) => {
						if (Array.isArray(val)) body[key] = JSON.stringify(val);
					});
					const res = await onSubmit(body);
					onSuccess(res);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting, setFieldValue }) => (
				<Form>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						pb={5}
					>
						<VStack gap={3} alignItems="stretch">
							<Box>
								<Badge sx={{ mb: 2 }}>General Info</Badge>
								<Divider />
							</Box>
							<HStack gap={3}>
								<Field
									name="category"
									component={MultiSelect}
									options={(categories || []).map((c) => ({
										value: c.category,
										label: c.category,
									}))}
									label="Category"
									placeholder="Select Category"
									isCreatable
									onCreateOption={async (newOption) =>
										handleCreateCategory(newOption, (newVal) => {
											setFieldValue("category", newVal);
										})
									}
									isLoading={creatingNewCategory}
									isDisabled={creatingNewCategory}
									required
								/>
								<Field
									name="isPublic"
									component={MultiSelect}
									label="Public"
									options={[
										{
											value: "yes",
											label: "Yes",
										},
										{ value: "no", label: "No" },
									]}
									placeholder="Select option"
									required
								/>
							</HStack>
							<Field
								name="title"
								component={TextInput}
								label="Publication Title"
								placeholder="Publication Title"
								required
							/>
							<HStack gap={3}>
								<Field
									name="centers"
									component={MultiSelect}
									label="Center"
									placeholder="Select Center (Multi Select)"
									options={(["serc", "airc"] || []).map((c) => ({
										value: c,
										label: c.toUpperCase(),
									}))}
									isMulti
									required
								/>
								<Field
									name="img_input"
									component={FileInput}
									label="Image"
									type="file"
									accept="image/*"
								/>
								<Field
									name="documentFile_input"
									component={FileInput}
									label="Document"
									type="file"
									accept="application/pdf"
								/>
							</HStack>
							<Field name="abstract" component={RichText} label="Abstract" />
							<Field
								name="description"
								component={RichText}
								label="Description"
							/>
							<Box>
								<Badge sx={{ mb: 2 }}>Associated Records</Badge>
								<Divider />
							</Box>
							<Field
								name="primaryAuthors"
								component={MultiSelect}
								label="Authors"
								placeholder="Select Primary Authors (Multi Select)"
								options={(people || []).map((person) => ({
									value: person.id,
									label: `${person.first_name} ${person.last_name}`,
								}))}
								isMulti
							/>
							<Field
								name="secondaryAuthors"
								component={MultiSelect}
								label="Secondary Authors"
								placeholder="Select Primary Authors (Multi Select)"
								options={(people || []).map((person) => ({
									value: person.id,
									label: `${person.first_name} ${person.last_name}`,
								}))}
								isMulti
							/>
							<Field
								name="organizations"
								component={MultiSelect}
								label="Organizations"
								placeholder="Select Organizations (Multi Select)"
								options={(organizationsData || []).map((org) => ({
									value: org.id,
									label: org.organization_name,
								}))}
								isMulti
							/>
							<Field
								name="sponsors"
								component={MultiSelect}
								label="Sponsors"
								placeholder="Select Sponsors (Multi Select)"
								options={(organizationsData || []).map((org) => ({
									value: org.id,
									label: org.organization_name,
								}))}
								isMulti
							/>
							{/* <Field
								name="researchThrusts"
								component={MultiSelect}
								label="Research Thrusts"
								placeholder="Select Research Thrusts (Multi Select)"
								options={(researchThrustsData || []).map((rt) => ({
									value: rt.id,
									label: rt.thrust_name,
								}))}
								isMulti
							/>
							<Field
								name="researchPrograms"
								component={MultiSelect}
								label="Research Programs"
								placeholder="Select Research Programs (Multi Select)"
								options={(researchProgramsData || []).map((rp) => ({
									value: rp.id,
									label: rp.program_name,
								}))}
								isMulti
							/>
							<Field
								name="researchTasks"
								component={MultiSelect}
								label="Research Tasks"
								placeholder="Select Research Tasks (Multi Select)"
								options={(researchTasksData || []).map((rt) => ({
									value: rt.id,
									label: rt.task_name,
								}))}
								isMulti
							/> */}
							<Field
								name="projects"
								component={MultiSelect}
								label="Projects"
								placeholder="Select Projects (Multi Select)"
								options={(projectsData || []).map((p) => ({
									value: p.id,
									label: p.project_title,
								}))}
								isMulti
							/>
							<Field
								name="tags"
								component={MultiSelect}
								label="Tags"
								placeholder="Select Tags (Multi Select)"
								options={(tagsData || []).map((t) => ({
									value: t.id,
									label: t.tag_title,
								}))}
								isMulti
							/>
							<Box>
								<Badge sx={{ mb: 2 }}>Document IDs</Badge>
								<Divider />
							</Box>
							<Grid templateColumns="repeat(2 , 1fr)" gap={3}>
								<GridItem>
									<Field
										name="contractNumber"
										component={TextInput}
										label="Contract Number"
										placeholder="Contract Number"
									/>
								</GridItem>
								<GridItem>
									<Field
										name="documentId"
										component={TextInput}
										label="Document ID"
										placeholder="Document ID"
									/>
								</GridItem>
								<GridItem>
									<Field
										name="reportNumber"
										component={TextInput}
										label="Report Number"
										placeholder="Report Number"
									/>
								</GridItem>
							</Grid>
							<Box>
								<Badge sx={{ mb: 2 }}>Dates</Badge>
								<Divider />
							</Box>
							<Grid templateColumns="repeat(2 , 1fr)" gap={3}>
								<GridItem>
									<Field
										name="publicationDate"
										component={TextInput}
										label="Publication Date"
										type="date"
									/>
								</GridItem>
								<GridItem />
								<GridItem>
									<Field
										name="startDate"
										component={TextInput}
										label="Start Date"
										type="date"
									/>
								</GridItem>
								<GridItem>
									<Field
										name="endDate"
										component={TextInput}
										label="End Date"
										type="date"
									/>
								</GridItem>
							</Grid>
							<Box>
								<Badge sx={{ mb: 2 }}>Event Info</Badge>
								<Divider />
							</Box>
							<Field
								name="eventName"
								component={TextInput}
								label="Event Name"
								placeholder="Event Name"
							/>
							<Field
								name="location"
								component={TextInput}
								label="Location"
								placeholder="Location"
							/>
							<Box>
								<Badge sx={{ mb: 2 }}>Misc Info</Badge>
								<Divider />
							</Box>
							<Grid templateColumns="repeat(2 , 1fr)" gap={3}>
								<GridItem>
									<Field name="url" component={TextInput} label="URL" />
								</GridItem>
								<GridItem>
									<Field
										name="productionDate"
										component={TextInput}
										label="Production Date"
										type="date"
									/>
								</GridItem>
								<GridItem>
									<Field name="isbn" component={TextInput} label="ISBN" />
								</GridItem>
								<GridItem>
									<Field
										name="publication"
										component={TextInput}
										label="Publication"
									/>
								</GridItem>
								<GridItem>
									<Field
										name="publisher"
										component={TextInput}
										label="Publisher"
									/>
								</GridItem>
							</Grid>
						</VStack>
					</Box>
					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button onClick={onCancel} size="xs">
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={isSubmitting}
							loadingText="Adding Publication"
						>
							{submitBtnText}
						</Button>
					</HStack>
				</Form>
			)}
		</Formik>
	);
}

export default PublicationForm;
