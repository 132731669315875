import React, { useState, useContext } from "react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "contexts/AccountContext";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Highlight,
	HStack,
	Input,
	VStack,
	Select as ChakraSelect,
	IconButton,
	Divider,
} from "@chakra-ui/react";
import { POST } from "utils/api";

const CreateResearchTaskRoles = () => {
	const { toastMsg } = useContext(AccountContext);
	const navigate = useNavigate();
	const [roleObj, setRoleObj] = useState({ roleName: "" });

	const [addBtnStatus, setAddBtnStatus] = useState(false);

	//func to create rt role
	const createRole = async (e) => {
		try {
			e.preventDefault();
			setAddBtnStatus(true);
			await POST("/api/researchTaskRoles", roleObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/rt-roles");
					setAddBtnStatus(false);
				}, 1000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Research Task Role"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Research Task Role
					</Highlight>
				</Heading>

				<Button size="xs" onClick={() => navigate("/rt-roles")}>
					Cancel
				</Button>
			</HStack>
			<form action="submit" onSubmit={createRole}>
				<VStack gap={1} align={"stretch"} pb={10}>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						// my={2}
						pb={5}
					>
						<Badge>Information</Badge>
						<Divider />
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Research Task Role Name</FormLabel>
									<Input
										type="text"
										name="Role Name"
										placeholder="Role Name"
										onChange={(e) => {
											setRoleObj({ roleName: e.target.value });
										}}
									/>
								</FormControl>
							</VStack>
						</VStack>
					</Box>
					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button size="xs" onClick={() => navigate("/rt-roles")}>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={addBtnStatus}
							loadingText="Adding RT Role"
						>
							Add RT Role
						</Button>
					</HStack>
				</VStack>
			</form>
		</Navigation>
	);
};

export default CreateResearchTaskRoles;
