import React from "react";
import { Heading, HStack, Badge, Box, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const PeopleTab = ({ orgProfile }) => {
	orgProfile?.people?.map((person) => {
		if (!person.emails) person.emails = "";
		if (Array.isArray(person.emails))
			person.emails = person.emails
				.map((emailObj) => emailObj.email)
				.join(", ");
		return person;
	});
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						People
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<br />
			<Datatable
				data={orgProfile.people}
				columns={[
					{
						title: "IMG",
						width: "8%",
						sortable: false,
						render: (data, type, row) => {
							return `<img src="${row.image_s3}" />`;
						},
					},
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Prefix",
						data: "prefix",
					},
					{
						className: "dt-body-left",
						width: "20%",
						title: "First Name",
						data: "first_name",
					},
					{
						className: "dt-body-left",
						title: "Last Name",
						width: "20%",
						data: "last_name",
					},
					{
						className: "dt-body-left",
						title: "Emails",
						width: "20%",
						data: "emails",
					},
					{
						className: "dt-body-left",
						title: "Public",
						data: "public",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
						render: function (dt) {
							return getFormattedDateStr(new Date(dt));
						},
					},
				]}
				theaders={[
					"IMG",
					"ID",
					"Prefix",
					"First Name",
					"Last Name",
					"Emails",
					"Public",
					"Updated On",
				]}
				navigatePath="/people"
				tableId="peopleTab"
			/>
		</Box>
	);
};

export default PeopleTab;
