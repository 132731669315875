import { Table, Th, Thead, Tr } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import "datatables.net-dt/css/jquery.dataTables.css";

/**
 * @typedef {object} Props
 * @property {Array<{}>} data - Datatable data
 * @property {Array<{}>} columns - Datatable columns
 * @property {Array<string>} theaders - Rendered table headers
 * @property {string} tableId - Rendered table id
 * @property {string} navigatePath - Navigation path when a single record is clicked, e.g. "/publications"
 */

/**
 * @param {Props}
 * @returns {React.ReactElement}
 */
function Datatable({ data, columns, theaders, navigatePath = "", tableId }) {
	$.DataTable = require("datatables.net");
	const tableRef = useRef();
	const navigate = useNavigate();
	useEffect(() => {
		const table = $(tableRef.current).DataTable({
			data,
			pageLength: 25,
			columns,
			destroy: true,
		});
		if (navigatePath !== "") {
			$(`#${tableId} tbody`).on("click", "tr", function () {
				const rowData = table.row(this).data();
				if (rowData?.id) {
					navigate(`${navigatePath}/${rowData.id}`);
				}
			});
		}

		return () => {
			table.destroy();
		};
	}, [data, columns, tableId, navigate, navigatePath]);
	return (
		<div className="user-table display responsive">
			<Table
				className="display responsive hover dt[-head|-body]-left"
				width="100% !important"
				ref={tableRef}
				id={tableId}
			>
				<Thead>
					<Tr>
						{theaders.map((header) => (
							<Th key={header}>{header}</Th>
						))}
					</Tr>
				</Thead>
			</Table>
		</div>
	);
}

export default Datatable;
