import React from "react";
import { Box, HStack, Badge, Heading, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";

const Permissions = ({ permissionList }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Permissions
					</Heading>
				</Badge>
			</HStack>
			<Divider mb={2} />
			<Datatable
				data={permissionList}
				columns={[
					{
						className: "dt-body-left",
						title: "Category",
						data: "category",
					},
					{
						className: "dt-body-left",
						title: "Permissions",
						data: "types",
						render: function (data) {
							let val = data.map((a) => a).join(", ");
							return val;
						},
					},
				]}
				theaders={["Category", "Permission"]}
				tableId="permissionsTab"
			/>
		</Box>
	);
};

export default Permissions;
