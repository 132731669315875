import {
	CloseIcon,
	DeleteIcon,
	EditIcon,
	ExternalLinkIcon,
} from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Divider,
	Heading,
	HStack,
	VStack,
	Text,
	Link,
	UnorderedList,
	ListItem,
	IconButton,
	Image,
	Button,
} from "@chakra-ui/react";
import CustomAlertDialog from "components/CustomAlertDialog";
import FileNameInput from "components/FileNameInput";
import FileUploadBtn from "components/FileUploadBtn";
import TechnicalReportForm from "components/Forms/TechnicalReport";
import PageSection from "components/PageSection";
import { AccountContext } from "contexts/AccountContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	DELETE,
	updateTechnicalReportDocument,
	updateTechnicalReportImg,
	updateTechnicalReports,
} from "utils/api";

function TechnicalReport({ technicalReportData }) {
	const [technicalReport, setTechnicalReport] = useState(technicalReportData);
	const [isEditing, setEditing] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const { toastMsg } = useContext(AccountContext);

	useEffect(() => {
		setTechnicalReport(technicalReportData);
	}, [technicalReportData]);
	const technicalReportValues = useMemo(() => {
		const values = { ...technicalReport };
		Object.entries(values).forEach(([key, val]) => {
			if (Array.isArray(val) && val.length > 0 && typeof val[0].id === "number")
				values[key] = val.map((v) => v.id);
		});
		return values;
	}, [technicalReport]);

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await DELETE(`/api/technicalReports/${technicalReport.id}`).then(
				(data) => {
					setIsOpen(false);
					toastMsg({
						title: "Success!",
						desc: data.message,
						type: "success",
					});
					setIsLoading(false);
					navigate("/technical-reports");

					return;
				}
			);
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};
	return (
		<VStack gap={1} align="stretch" pb={20}>
			<CustomAlertDialog
				title="Delete Technical Record"
				message="Are you sure?  Confirm to delete"
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={handleDelete}
				isLoading={isLoading}
				confirmText="Delete"
			/>
			<Box
				padding="10px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Technical Report
						</Heading>
					</Badge>
					{isEditing ? (
						<IconButton
							onClick={() => {
								setEditing(!isEditing);
							}}
							size="xs"
							icon={<CloseIcon />}
						/>
					) : (
						<IconButton
							onClick={() => {
								setEditing(!isEditing);
							}}
							size="xs"
							icon={<EditIcon />}
						/>
					)}
				</HStack>
				<Divider />
				{isEditing ? (
					<TechnicalReportForm
						{...technicalReportValues}
						onCancel={() => {
							setEditing(false);
						}}
						onSubmit={async (body) =>
							updateTechnicalReports(technicalReport.id, body)
						}
						onSuccess={(res) => {
							setTechnicalReport(res.technicalReport);
							setEditing(false);
						}}
						submitBtnText="Update Technical Report"
						hideUploadSection
					/>
				) : (
					<Box>
						<HStack
							justifyContent="space-between"
							alignItems="flex-start"
							mt={2}
							mb={5}
							gap={1}
						>
							<VStack gap={1} alignItems="flex-start">
								<Heading px={5} as="h1" size="xl">
									{technicalReport.title}
								</Heading>
								technicalReport
								<HStack px={5}>
									<Text as="b">Report Number:</Text>
									<Text>{technicalReport.reportNumber}</Text>
								</HStack>
								<HStack px={5}>
									<Text as="b">Contract Number:</Text>
									<Text>{technicalReport.contractNumber}</Text>
								</HStack>
								<HStack px={5}>
									<Text as="b">Publication Date:</Text>
									<Text>{technicalReport.publicationDate}</Text>
								</HStack>
							</VStack>
							<VStack gap={1} pr={3}>
								{technicalReport.imageS3 && (
									<>
										<Image
											padding={1}
											width={"200px"}
											borderRadius="3xl"
											boxShadow="xl"
											alt={technicalReport.title}
											src={technicalReport.imageS3}
											// src={`${orgProfile.image}?time=${new Date().getTime()}`}
											// alt={orgProfile.organization_name}
										/>
										<FileNameInput
											fileName={technicalReport.imageFilename}
											endpoint={`/api/technicalReports/image/rename/${technicalReport.id}`}
											onSuccess={(res) => {
												setTechnicalReport(res.techReport);
											}}
										/>
									</>
								)}
								<FileUploadBtn
									btnLabel="Update Image"
									accept="image/*"
									onSubmit={async (img) =>
										updateTechnicalReportImg(technicalReport.id, { img })
									}
									onSuccess={(imageS3, imageFilename) => {
										setTechnicalReport({
											...technicalReport,
											imageS3,
											imageFilename,
										});
									}}
								/>
							</VStack>
						</HStack>
						<PageSection title={"Document"}>
							<HStack justifyContent={"space-between"}>
								{technicalReport.fileS3 && (
									<FileNameInput
										fileName={technicalReport.technicalReportFilename}
										endpoint={`/api/technicalReports/file/rename/${technicalReport.id}`}
										onSuccess={(res) => {
											setTechnicalReport(res.techReport);
										}}
										isHyperLink
										externalLink={technicalReport.fileS3}
									/>
								)}
								<FileUploadBtn
									btnLabel="Update Document"
									accept="application/pdf"
									onSubmit={async (documentFile) =>
										updateTechnicalReportDocument(technicalReport.id, {
											technicalReport: documentFile,
										})
									}
									onSuccess={(fileS3, technicalReportFilename) => {
										setTechnicalReport({
											...technicalReport,
											fileS3,
											technicalReportFilename,
										});
									}}
								/>
							</HStack>
						</PageSection>
						<PageSection title="Abstract">
							<Text
								dangerouslySetInnerHTML={{
									__html: technicalReport.abstract,
								}}
							/>
						</PageSection>
						<PageSection title="Research Tasks">
							<UnorderedList>
								{technicalReport.researchTasks?.map((rt) => (
									<ListItem key={rt.id}>{rt.taskName}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Tags">
							<UnorderedList>
								{technicalReport.tags?.map((tg) => (
									<ListItem key={tg.id}>{tg.tagTitle}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Related RTs">
							<Text
								dangerouslySetInnerHTML={{
									__html: technicalReport.relatedRts,
								}}
							/>
						</PageSection>
						<PageSection title={"Performance Period"}>
							<UnorderedList>
								<ListItem key={1}>
									<HStack>
										<Text as="b">Period Start Date:</Text>
										<Text>{technicalReport.periodStart}</Text>
									</HStack>
								</ListItem>
								<ListItem key={2}>
									<HStack>
										<Text as="b">Period End Date:</Text>
										<Text>{technicalReport.periodEnd}</Text>
									</HStack>
								</ListItem>
							</UnorderedList>
						</PageSection>
						<Divider />
						<br />
						<HStack justifyContent={"center"}>
							<Button
								rightIcon={<DeleteIcon />}
								w={"10%"}
								onClick={() => {
									setIsOpen(true);
								}}
							>
								Delete
							</Button>
						</HStack>
					</Box>
				)}
			</Box>
		</VStack>
	);
}

export default TechnicalReport;
