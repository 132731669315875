import React from "react";
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from "@chakra-ui/react";

const CustomAlertDialog = ({
	title,
	message,
	isOpen, //state to set dialog view true
	onClose, //func to set isOpen to false: setIsOpen(false)
	onConfirm, //onClick function
	type, //if it is a form "submit" - this is optional
	isLoading,
	confirmText,
}) => {
	const cancelRef = React.useRef();

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
			closeOnOverlayClick={false}
			motionPreset="slideInBottom"
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader fontSize="lg" fontWeight="bold">
					{title}
				</AlertDialogHeader>
				<AlertDialogCloseButton />

				<AlertDialogBody>{message}</AlertDialogBody>

				<AlertDialogFooter>
					<Button ref={cancelRef} onClick={onClose} size={"sm"}>
						Cancel
					</Button>
					<Button
						ml={3}
						size={"sm"}
						isLoading={isLoading}
						onClick={onConfirm}
						type={type}
					>
						{confirmText}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};

export default CustomAlertDialog;
