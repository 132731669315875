import React, { useMemo } from "react";
import Datatable from "..";
import { getFormattedDateStr } from "utils/helpers";

function PublicationsDatatable({ data, variant = "allPublications" }) {
	const { datatableData, columns, theaders } = useMemo(() => {
		let formattedData = [];
		if (Array.isArray(data)) formattedData = [...data];
		formattedData?.forEach((dt) => {
			dt.updatedAt = getFormattedDateStr(new Date(dt.updatedAt));
			if (Array.isArray(dt.primaryAuthors))
				dt.primaryAuthors = dt.primaryAuthors
					.map((a) => `${a.firstName} ${a.lastName}`)
					.join(", ");
			if (Array.isArray(dt.secondaryAuthors))
				dt.secondaryAuthors = dt.secondaryAuthors
					.map((a) => `${a.firstName} ${a.lastName}`)
					.join(", ");
			if (Array.isArray(dt.centers)) {
				dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
			}
		});
		let theaders = [
			"ID",
			"Title",
			"Category",
			"Centers",
			"Published Date",
			"Updated At",
		];
		let columns = [
			{
				className: "dt-body-left",
				title: "ID",
				data: "id",
			},
			{
				className: "dt-body-left",
				title: "Title",
				data: "title",
				width: "50%",
			},
			{
				className: "dt-body-left",
				title: "Category",
				data: "category",
				width: "10%",
			},
			{
				className: "dt-body-left",
				title: "Centers",
				data: "centers",
			},
			{
				className: "dt-body-left",
				title: "Published Date",
				data: "publicationDate",
				width: "10%",
			},
			{
				className: "dt-body-left",
				title: "Updated At",
				data: "updatedAt",
				width: "10%",
			},
		];
		if (variant === "allPublications") {
			theaders.splice(3, 0, "Primary Authors", "Secondary Authors");
			columns.splice(
				3,
				0,
				{
					className: "dt-body-left",
					title: "Primary Authors",
					data: "primaryAuthors",
					orderable: false,
					width: "10%",
				},
				{
					className: "dt-body-left",
					title: "Secondary Authors",
					data: "secondaryAuthors",
					orderable: false,
					width: "10%",
				}
			);
		}
		return {
			datatableData: formattedData,
			theaders,
			columns,
		};
	}, [data, variant]);
	return (
		<Datatable
			data={datatableData || []}
			columns={columns}
			theaders={theaders}
			navigatePath="/publications"
			tableId="viewPublications"
		/>
	);
}

export default PublicationsDatatable;
