import React, { useCallback } from "react";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import Datatable from "components/Datatable";
import useFetch from "hooks/useFetch";

const Finance = () => {
	const { data, loading, error } = useFetch("/api/finance");
	const navigate = useNavigate();

		const renderData = useCallback(() => {
			if (loading) return <p>Loading...</p>;
			if (error) return <p>Error getting Organisations data</p>;
			data.forEach((dt) => {
				if (Array.isArray(dt.pis))
					dt.pis = dt.pis.map((a) => a.name).join(", ");
			});
			return (
				<Datatable
					data={data}
					columns={[
						{
							className: "dt-body-left",
							title: "Contract",
							data: "contract",
						},
						{
							className: "dt-body-left",
							title: "Research Task",
							data: "title",
						},
						{
							className: "dt-body-left",
							title: "Task Number",
							data: "taskNumber",
						},
						{
							className: "dt-body-left",
							title: "Thrust",
							data: "thrust",
						},

						{
							className: "dt-body-left",
							title: "DO Number",
							data: "taskOrder",
						},
						{
							className: "dt-body-left",
							title: "Kuali Number",
							data: "kuali",
						},
						{
							className: "dt-body-left",
							title: "PI",
							data: "pis",
						},
						{
							className: "dt-body-left",
							title: "Task Correlation",
							data: "taskCorrelation",
						},
						{
							className: "dt-body-left",
							title: "Quarter",
							data: "quarter",
							render: (dt) => `Q${dt}`,
						},
						{
							className: "dt-body-left",
							title: "Start Date",
							data: "startDate",
						},
						{
							className: "dt-body-left",
							title: "End Date",
							data: "endDate",
						},
					]}
					theaders={[
						"Contract",
						"Research Task",
						"Task Number",
						"Thrust",
						"DO Number",
						"Kuali Number",
						"PI",
						"Task Correlation",
						"Quarter",
						"Start Date",
						"End Date",
					]}
					navigatePath="/finance"
					tableId="viewFinance"
				/>
			);
		}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Finance"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Finance
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button
						size="xs"
						onClick={() => {
							navigate("/finance/create");
						}}
					>
						Create
					</Button>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default Finance;
