import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	HStack,
	IconButton,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react";
import Alert from "components/Alert";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import AddPerson from "components/Forms/ResearchTask/AddPerson";
import Modal from "components/Modal";
import { AccountContext } from "contexts/AccountContext";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	addPersonToResearchTask,
	deleteResearchTaskPerson,
	updateResearchTaskPerson,
} from "utils/api";

function TeamMemberForm({
	people,
	rolesData,
	onCancel,
	setPeople,
	person_id = null,
	task_role = null,
	start_date = null,
	end_date = null,
	onSubmit,
	onSuccess = () => {},
	onError = () => {},
	submitBtnText = "Add",
}) {
	const { toastMsg } = useContext(AccountContext);
	const [addPersonModal, setAddPersonModal] = useState(false);
	const handleCreatePerson = useCallback(
		async (newOption, onSuccess) => {
			setPeople([...people, newOption]);
			onSuccess(newOption);
			toastMsg({
				title: "Success!",
				desc: "Created person successfully",
				type: "success",
			});
			setAddPersonModal(false);
			return;
		},
		[people, toastMsg, setPeople]
	);
	return (
		<Formik
			initialValues={{
				person_id,
				task_role,
				start_date,
				end_date,
			}}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const res = await onSubmit(values);
					onSuccess(res);
					onCancel();
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ setFieldValue, isSubmitting }) => (
				<Form style={{ width: "100%", margin: "1rem 0" }}>
					<HStack alignItems="center" width="full">
						<Field
							name={"person_id"}
							component={MultiSelect}
							label="Person"
							placeholder="Select Person"
							options={(people || []).map((person) => ({
								value: person.id,
								label: `${person.first_name} ${person.last_name}`,
							}))}
							isCreatable
							onCreateOption={() => {
								setAddPersonModal(true);
							}}
							isLoading={addPersonModal}
							isDisabled={addPersonModal}
							required
						/>
						<Field
							name={"task_role"}
							component={MultiSelect}
							label="Role"
							placeholder="Select Role"
							options={(rolesData || []).map((role) => ({
								value: role.role_name?.toLowerCase(),
								label: role.role_name,
							}))}
							required
						/>
						<Field
							name={"start_date"}
							component={TextInput}
							label="Start Date"
							type="date"
							required
						/>
						<Field
							name={"end_date"}
							component={TextInput}
							label="End Date"
							type="date"
							required
						/>
						{/* <IconButton
							size="sm"
							icon={<DeleteIcon />}
							style={{
								marginLeft: "1rem",
							}}
							// onClick={() => {
							// 	const updatedValue = [...values.roles];
							// 	updatedValue.splice(index, 1);
							// 	setFieldValue("roles", updatedValue);
							// }}
							variant="outline"
						/> */}
					</HStack>
					<HStack gap={2} justifyContent="flex-end" sx={{ mt: "1rem" }}>
						<Button variant="outline" onClick={onCancel}>
							Cancel
						</Button>
						<Button type="submit" isLoading={isSubmitting}>
							{submitBtnText}
						</Button>
					</HStack>

					<Modal
						isOpen={Boolean(addPersonModal)}
						header="Add Person"
						onClose={() => {
							setAddPersonModal(false);
						}}
					>
						<AddPerson
							onCancel={() => {
								setAddPersonModal(false);
							}}
							onSuccess={async (person) =>
								handleCreatePerson(person, (person) => {
									setFieldValue("person_id", person.id);
								})
							}
							onError={() => {
								toastMsg({
									title: "Error!",
									desc: "Error creating new person",
									type: "error",
								});
							}}
						/>
					</Modal>
				</Form>
			)}
		</Formik>
	);
}

function TeamMembers({ membersData, periodStart, periodEnd, researchTaskId }) {
	const [showAddMemberForm, setShowAddMemberForm] = useState(false);
	const [editPerson, setEditPerson] = useState(false);
	const { data: rolesData } = useFetch(
		"/api/researchTask/getResearchTaskRoles"
	);
	const { data: peopleData } = useFetch("/api/people/getAllPeopleWithOrg");
	const [people, setPeople] = useState([]);
	const [members, setMembers] = useState([]);
	const [showRemoveMember, setShowRemoveMember] = useState(false);

	useEffect(() => {
		setMembers(membersData);
	}, [membersData]);

	useEffect(() => {
		setPeople(peopleData);
	}, [peopleData]);

	const handleRemovePerson = async () => {
		try {
			await deleteResearchTaskPerson(showRemoveMember.relId);
			const updatedMembers = members.filter(
				(member) => member.id !== showRemoveMember.id
			);
			setMembers(updatedMembers);
		} catch (e) {
			console.error(e);
		} finally {
			setShowRemoveMember(false);
		}
	};

	return (
		<VStack alignItems="flex-start">
			<Alert
				isOpen={Boolean(showRemoveMember)}
				onClose={() => setShowRemoveMember(false)}
				header="Remove Person"
				body={
					<>
						Are you sure?
						<br />
						Confirm to remove {showRemoveMember.first_name}{" "}
						{showRemoveMember.last_name}
					</>
				}
				onSuccessClick={handleRemovePerson}
				successBtnText="Delete"
			/>
			{members.map((member) => (
				<Box
					key={member.id}
					padding="10px"
					borderWidth={1}
					borderRadius={8}
					boxShadow="lg"
					rounded="xl"
					width="100%"
				>
					{editPerson === member.id ? (
						<TeamMemberForm
							{...member}
							task_role={member.task_role?.toLowerCase()}
							people={people}
							rolesData={rolesData}
							setPeople={setPeople}
							onCancel={() => {
								setEditPerson(false);
							}}
							onSubmit={(values) =>
								updateResearchTaskPerson({
									...values,
									id: member.relId,
									research_task_id: researchTaskId,
								})
							}
							onSuccess={(response) => {
								const updatedMembers = members.map((member) => {
									if (member.relId === response.updated.relId)
										return response.updated;
									return member;
								});
								setMembers(updatedMembers);
							}}
							submitBtnText="Update"
						/>
					) : (
						<HStack
							alignItems="flex-start"
							style={{
								width: "100%",
							}}
						>
							<img
								src={member.image_s3}
								alt={member.first_name}
								style={{
									width: 100,
									height: 100,
									objectFit: "cover",
								}}
							/>
							<HStack
								justifyContent="space-between"
								alignItems="flex-start"
								style={{
									width: "100%",
								}}
							>
								<Box>
									<Text fontSize="xl">
										{member.first_name} {member.last_name}
									</Text>
									<Text>
										<strong>Role:</strong> {member.task_role}
									</Text>
									<Text>
										{member.start_date}{" "}
										{member.start_date && member.end_date && "to"}{" "}
										{member.end_date}
									</Text>
								</Box>
								<Stack>
									<IconButton
										onClick={() => {
											setEditPerson(member.id);
										}}
										icon={<EditIcon />}
										size="sm"
									/>
									<IconButton
										onClick={() => {
											setShowRemoveMember(member);
										}}
										icon={<DeleteIcon />}
										size="sm"
									/>
								</Stack>
							</HStack>
						</HStack>
					)}
				</Box>
			))}
			{showAddMemberForm ? (
				<Box
					padding="10px"
					borderWidth={1}
					borderRadius={8}
					boxShadow="lg"
					rounded="xl"
					width="100%"
				>
					<TeamMemberForm
						people={people}
						rolesData={rolesData}
						setPeople={setPeople}
						start_date={periodStart}
						end_date={periodEnd}
						onCancel={() => {
							setShowAddMemberForm(false);
						}}
						onSubmit={(values) =>
							addPersonToResearchTask({
								...values,
								research_task_id: researchTaskId,
							})
						}
						onSuccess={(response) => {
							setMembers([...members, response.created]);
						}}
					/>
				</Box>
			) : (
				<Box
					sx={{
						alignSelf: "flex-end",
					}}
				>
					<Button
						sx={{
							margin: "2rem 0",
						}}
						onClick={() => {
							setShowAddMemberForm(true);
						}}
					>
						Add Team Member
					</Button>
				</Box>
			)}
		</VStack>
	);
}

export default TeamMembers;
