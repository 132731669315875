import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	IconButton,
	Divider,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

const InfoSec = ({ peopleProfile, toggleInfoEdit }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Information
					</Heading>
				</Badge>
				<HStack>
					<IconButton onClick={toggleInfoEdit} size="xs" icon={<EditIcon />} />
				</HStack>
			</HStack>
			<Divider />
			<VStack align={"stretch"}>
				<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
					<Heading as="h2" size="xl">
						{peopleProfile.prefix} {peopleProfile.first_name}{" "}
						{peopleProfile.middle_name} {peopleProfile.last_name}{" "}
						{peopleProfile.suffix}
					</Heading>
					<HStack align={"stretch"} justifyContent="flex-start">
						<Text>
							Public: <Badge colorScheme="green">{peopleProfile.public}</Badge>
						</Text>
					</HStack>
				</VStack>

				<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
					<Badge>SERC Collaborating Organizations</Badge>
					<UnorderedList pl={10}>
						{peopleProfile.orgs?.map((org) => (
							<ListItem key={org.id}>
								<Text>{org.organization_name}</Text>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>

				<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
					<Badge>Roles</Badge>
					<UnorderedList pl={10}>
						{peopleProfile.roles?.map((role) => (
							<ListItem key={role.id}>
								<Text>{role.role_name}</Text>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>

				<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
					<Badge>Bio</Badge>
					<Text
						px={10}
						dangerouslySetInnerHTML={{ __html: peopleProfile.biography }}
					/>
				</VStack>
			</VStack>
		</>
	);
};

export default InfoSec;
