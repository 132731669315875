import { CloseIcon } from "@chakra-ui/icons";
import {
	Badge,
	Button,
	Divider,
	HStack,
	Heading,
	IconButton,
	VStack,
} from "@chakra-ui/react";
import {
	MultiSelect,
	RichText,
	TextInput,
} from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import { salutationList } from "pages/People/CreatePeople/CreatePeople";
import React, { useContext, useState } from "react";
import JobSection from "./JobSection";
import EmailSection from "./EmailPhoneSection";
import WebsiteSection from "./WebsiteSection";
import AddressSection from "./AddressSection";
import QuickCreateOrgModal from "../QuickCreateOrgModal";
import { AccountContext } from "contexts/AccountContext";

function EditPerson({
	personId,
	peopleProfile,
	organizationsData,
	setOrganizationsData,
	rolesData,
	handleUpdate,
	onCancel,
}) {
	const { toastMsg } = useContext(AccountContext);
	const [createOrgModal, setCreateOrgModal] = useState(false);
	return (
		<Formik
			initialValues={{
				id: peopleProfile.id ?? null,
				isPublic: peopleProfile?.public?.toLowerCase() ?? null,
				prefix: peopleProfile?.prefix ?? "",
				first_name: peopleProfile?.first_name ?? "",
				middle_name: peopleProfile?.middle_name ?? "",
				last_name: peopleProfile?.last_name ?? "",
				suffix: peopleProfile?.suffix ?? "",
				biography: peopleProfile?.biography ?? "",
				organizations: peopleProfile?.orgs?.map((org) => org.id) ?? [],
				roles: peopleProfile?.roles?.map((role) => role.role_id) ?? [],
				jobList: peopleProfile?.jobTitles ?? [],
				emailList: peopleProfile?.emails ?? [],
				phoneList: peopleProfile?.phones ?? [],
				websites: peopleProfile?.websites ?? [],
				addressList: peopleProfile?.addresses ?? [],
			}}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const {
						id,
						isPublic,
						prefix,
						first_name,
						middle_name,
						last_name,
						suffix,
						biography,
						organizations,
						roles,
						jobList,
						emailList,
						phoneList,
						websites,
						addressList,
					} = values;
					const reqBody = {
						id,
						public: isPublic,
						prefix,
						first_name,
						middle_name,
						last_name,
						suffix,
						biography,
						organizations,
						roles,
						jobTitles: jobList.map(
							({
								job_title,
								organization_id,
								start_date,
								end_date,
								current,
								visibility,
							}) => ({
								job_title,
								organization_id,
								start_date,
								end_date,
								current,
								visibility,
							})
						),
						emails: emailList.map(({ email_address, email_type }) => ({
							email_address,
							email_type,
						})),
						phones: phoneList.map(({ phone_number, phone_type }) => ({
							phone_number,
							phone_type,
						})),
						websites: websites.map(({ website_url }) => website_url),
						addresses: addressList.map(
							({
								address_1,
								address_2,
								city_town,
								state_province_region,
								zip_postal_code,
								country,
								address_type,
							}) => ({
								address_1,
								address_2,
								city_town,
								state_province_region,
								zip_postal_code,
								country,
								address_type,
							})
						),
					};
					await handleUpdate(reqBody);
				} catch (e) {
					console.error(e);
				} finally {
					setSubmitting(false);
				}
			}}
			enableReinitialize
		>
			{({ values, isSubmitting, setFieldValue }) => (
				<Form>
					<VStack
						gap={2}
						alignItems="flex-start"
						justifyContent="stretch"
						pb={4}
					>
						<HStack justifyContent={"space-between"} width="100%">
							<Badge>
								<Heading as="h6" size="xs">
									Information
								</Heading>
							</Badge>
							<IconButton onClick={onCancel} size="xs" icon={<CloseIcon />} />
						</HStack>
						<Divider />
						<HStack gap={4} width="100%">
							<Field
								component={MultiSelect}
								name="isPublic"
								label="Public"
								placeholder="Select option"
								options={[
									{ value: "yes", label: "Yes" },
									{ value: "no", label: "No" },
								]}
								required
							/>
							<Field
								component={MultiSelect}
								name="prefix"
								label="Salutation"
								placeholder="Select prefix"
								options={salutationList.map((salutation) => ({
									value: salutation,
									label: salutation,
								}))}
							/>
						</HStack>
						<HStack gap={4} width="100%">
							<Field
								component={TextInput}
								name="first_name"
								label="First Name"
								required
							/>
							<Field
								component={TextInput}
								name="middle_name"
								label="Middle Name"
							/>
						</HStack>
						<HStack gap={4} width="100%">
							<Field
								component={TextInput}
								name="last_name"
								label="Last Name"
								required
							/>
							<Field component={TextInput} name="suffix" label="Suffix" />
						</HStack>
						<Field component={RichText} name="biography" label="Biography" />
						<Field
							name="organizations"
							component={MultiSelect}
							label="Organizations"
							placeholder="Select Organizations (Multi Select)"
							options={(organizationsData || []).map((org) => ({
								value: org.id,
								label: org.organization_name,
							}))}
							isMulti
							isCreatable
							onCreateOption={() => {
								setCreateOrgModal(true);
							}}
							isLoading={createOrgModal}
							isDisabled={createOrgModal}
						/>
						<QuickCreateOrgModal
							isOpen={createOrgModal}
							onClose={() => {
								setCreateOrgModal(false);
							}}
							onSuccess={async (org) => {
								setOrganizationsData([...organizationsData, org]);
								setFieldValue("organizations", [
									...values.organizations,
									org.id,
								]);
								toastMsg({
									title: "Success!",
									desc: "Created organization successfully",
									type: "success",
								});
								setCreateOrgModal(false);
								return;
							}}
						/>
						<Field
							name="roles"
							component={MultiSelect}
							label="Roles"
							placeholder="Select Roles (Multi Select)"
							options={(rolesData || []).map((role) => ({
								value: role.id,
								label: role.role_name,
							}))}
							isMulti
						/>
						<Badge>
							<Heading as="h6" size="xs">
								Job Title
							</Heading>
						</Badge>
						<Divider />
						<JobSection
							personId={personId}
							jobList={values.jobList}
							organizationsData={organizationsData}
							setFieldValue={(updatedValue) => {
								setFieldValue("jobList", updatedValue);
							}}
						/>
						<Badge>
							<Heading as="h6" size="xs">
								Email ID
							</Heading>
						</Badge>
						<Divider />
						<EmailSection
							personId={personId}
							list={values.emailList}
							fieldOneType="email"
							setFieldValue={(updatedValue) => {
								setFieldValue("emailList", updatedValue);
							}}
						/>
						<Badge>
							<Heading as="h6" size="xs">
								Phone
							</Heading>
						</Badge>
						<Divider />
						<EmailSection
							personId={personId}
							list={values.phoneList}
							listName="phoneList"
							setFieldValue={(updatedValue) => {
								setFieldValue("phoneList", updatedValue);
							}}
							fieldOneName="phone_number"
							fieldOneLabel="Phone Number"
							typeFieldName="phone_type"
						/>
						<Badge>
							<Heading as="h6" size="xs">
								Websites
							</Heading>
						</Badge>
						<Divider />
						<WebsiteSection
							personId={personId}
							websites={values.websites}
							setFieldValue={(updatedValue) => {
								setFieldValue("websites", updatedValue);
							}}
						/>
						<Badge>
							<Heading as="h6" size="xs">
								Address
							</Heading>
						</Badge>
						<Divider />
						<AddressSection
							personId={personId}
							addressList={values.addressList}
							setFieldValue={(updatedValue) => {
								setFieldValue("addressList", updatedValue);
							}}
						/>
						<HStack justifyContent="flex-end" gap={3} mb={4} width="100%">
							<Button variant="outline" onClick={onCancel}>
								Cancel
							</Button>
							<Button type="submit" isLoading={isSubmitting}>
								Update
							</Button>
						</HStack>
					</VStack>
				</Form>
			)}
		</Formik>
	);
}

export default EditPerson;
