import React from "react";
import { Heading, HStack, Badge, Box, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const Specialities = ({ peopleProfile }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Specialities
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<br />
			<Datatable
				data={peopleProfile.specialty}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Title",
						data: "specialty_title",
					},
					{
						className: "dt-body-left",
						title: "People Count",
						data: "people_count",
					},
					{
						className: "dt-body-left",
						title: "Updated At",
						data: "updated_at",
						render: function (dt) {
							return getFormattedDateStr(new Date(dt));
						},
					},
				]}
				theaders={["ID", "Title", "Task Name", "People Count", "Updated At"]}
				navigatePath="/specialties"
				tableId="viewSpcl"
			/>
		</Box>
	);
};

export default Specialities;
