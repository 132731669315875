import React from "react";
import { Box, HStack, Badge, Heading, Button, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";

const Groups = ({ groupList, toggleAddGroup }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Groups
					</Heading>
				</Badge>
				<Button size="xs" onClick={() => toggleAddGroup()}>
					Add Group
				</Button>
			</HStack>
			<Divider mb={2} />
			<Datatable
				data={groupList}
				columns={[
					{
						className: "dt-body-left",
						title: "Groups",
						data: "name",
					},
					{
						className: "dt-body-left",
						title: "People List",
						data: "users",
						render: function (data) {
							let val = data.map((a) => a.name).join(", ");
							return val;
						},
					},
					{
						className: "dt-body-left",
						title: "Permissions On",
						data: "permissions",
						render: function (data) {
							let val = data.map((a) => a.category).join(", ");
							return val;
						},
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={["Groups", "People List", "Permissions On", "Updated On"]}
				navigatePath="/groups"
				tableId="groupsTab"
			/>
		</Box>
	);
};

export default Groups;
