import React, { useContext } from "react";
import { Flex, Heading, Text, Icon, Stack, Image } from "@chakra-ui/react";
import { FiLock } from "react-icons/fi";
import mainLogo from "assets/serc-logo.png";
import { AccountContext } from "contexts/AccountContext";
import { Link } from "react-router-dom";

const AccessDenied = () => {
	const { session } = useContext(AccountContext);
	return (
		<Flex
			width="100%"
			minHeight="50vh"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
		>
			<Stack align={"center"} pb={5}>
				<Image width="200px" src={mainLogo} alt="SERC" />
			</Stack>
			<Icon as={FiLock} w={16} h={16} color="gray.500" />
			<Heading size="lg" mt={4}>
				Access Denied
			</Heading>
			<Text mt={4} mb={2}>
				Sorry, this page is not accessible to you.
			</Text>
			{Boolean(session) ? (
				<Text>
					Take me back to{" "}
					<Link
						to="/homepage"
						style={{
							color: "var(--chakra-colors-blue-400)",
						}}
					>
						Homepage
					</Link>
				</Text>
			) : (
				<Text>
					Login or Register with{" "}
					<Link
						to="/"
						style={{
							color: "var(--chakra-colors-blue-400)",
						}}
					>
						SERC
					</Link>{" "}
					to continue.
				</Text>
			)}
		</Flex>
	);
};

export default AccessDenied;
