import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
import React from "react";
import { CSVLink } from "react-csv";
import dummyImg from "assets/profile.jpeg";

function ResearchersReport({ researchersReport, startDate, endDate }) {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Heading size="sm" mb={2}>
					<Highlight
						query="Researchers Report"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Researchers Report
					</Highlight>
				</Heading>

				<CSVLink
					data={researchersReport}
					headers={[
						{ label: "ID", key: "id" },
						{ label: "Prefix", key: "prefix" },
						{ label: "First Name", key: "first_name" },
						{ label: "Last Name", key: "last_name" },
						{ label: "Research Task", key: "researchTasks" },
						{ label: "Updated On", key: "updated_at" },
					]}
					filename={`SERC_Researchers_Report_${startDate}_${endDate}`}
					target="_blank"
				>
					<Button size="xs">Export</Button>
				</CSVLink>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<Datatable
					data={researchersReport}
					columns={[
						{
							title: "IMG",
							width: "10%",
							sortable: false,
							render: (data, type, row) => {
								return `<img src="${
									row.image_s3 === null ? dummyImg : row.image_s3
								}" onerror="this.onerror=null; this.src='${dummyImg}'" alt="img"/>`;
							},
						},
						{
							className: "dt-body-left",
							title: "ID",
							data: "id",
						},
						{
							className: "dt-body-left",
							title: "Prefix",
							data: "prefix",
						},
						{
							className: "dt-body-left",
							title: "First Name",
							data: "first_name",
						},
						{
							className: "dt-body-left",
							title: "Last Name",
							data: "last_name",
						},
						{
							className: "dt-body-left",
							title: "Research Task",
							data: "researchTasks",
							width: "50% !important",
						},
					]}
					theaders={[
						"IMG",
						"ID",
						"Prefix",
						"First Name",
						"Last Name",
						"researchTasks",
					]}
					navigatePath="/people"
					tableId="viewResearcherReport"
				/>
			</Box>
		</>
	);
}

export default ResearchersReport;
