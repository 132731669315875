import { Box, Button, Heading, Highlight, HStack } from "@chakra-ui/react";
import FinanceForm from "components/Forms/Finance";
import Navigation from "components/Layout/Navigation";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createFinanceRecord } from "utils/api";

function CreateFinance() {
	const navigate = useNavigate();
	const onCancel = () => {
		navigate("/finance");
	};
	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Finance Record"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Finance Record
					</Highlight>
				</Heading>
				<Button size="xs" onClick={onCancel}>
					Cancel
				</Button>
			</HStack>
			<Box
				padding={"10px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
				pb={5}
			>
				<FinanceForm
					onSubmit={createFinanceRecord}
					onSuccess={(res) => {
						navigate(`/finance/${res.id}`);
					}}
					onCancel={onCancel}
				/>
			</Box>
		</Navigation>
	);
}

export default CreateFinance;
