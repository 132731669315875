import { Button, Heading, Highlight, HStack } from "@chakra-ui/react";
import PublicationForm from "components/Forms/Publication";
import Navigation from "components/Layout/Navigation";
import React from "react";
import { useNavigate } from "react-router-dom";
import { createPublication } from "utils/api";

function CreatePublication() {
	const navigate = useNavigate();
	const onCancel = () => {
		navigate("/publications");
	};
	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Publication"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Publication
					</Highlight>
				</Heading>
				<Button onClick={onCancel} size="xs">
					Cancel
				</Button>
			</HStack>
			<PublicationForm
				onSubmit={createPublication}
				onSuccess={(res) => {
					navigate(`/publications/${res.id}`);
				}}
				onCancel={onCancel}
			/>
		</Navigation>
	);
}

export default CreatePublication;
