import React, { useContext, useEffect, useState } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import {
	EditIcon,
	CloseIcon,
	CheckIcon,
	DeleteIcon,
	AddIcon,
} from "@chakra-ui/icons";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";
import axios from "axios";

const WebsiteSec = ({ orgProfile, getOrgProfile, id }) => {
	const { toastMsg, apiCall } = useContext(AccountContext);

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const [deleteObj, setDeleteObj] = useState({ id: "" });

	const {
		isOpen: isAddOpen,
		onOpen: onAddOpen,
		onClose: onAddClose,
	} = useDisclosure();

	const [websiteEdit, setWebsiteEdit] = useState(false);
	const toggleWebisteEdit = () => {
		setWebsiteEdit(!websiteEdit);
	};

	const [oldWebisteList, setOldWebsiteList] = useState([]);

	useEffect(() => {
		setOldWebsiteList(
			orgProfile.websites?.map((wb) => {
				return {
					id: wb.id,
					website_url: wb.website_url,
				};
			})
		);
	}, [orgProfile]);

	const handleOldWebisteChange = (e, index) => {
		const { name, value } = e.target;
		const newWebsiteList = [...oldWebisteList];
		newWebsiteList[index][name] = value;
		setOldWebsiteList(newWebsiteList);
	};

	const [websiteList, setWebsiteList] = useState([]);

	const handleAddWebsite = () => {
		setWebsiteList([
			...websiteList,
			{
				organization_id: orgProfile.id,
				website_url: "",
			},
		]);
	};

	const handleWebisteChange = (e, index) => {
		const { name, value } = e.target;
		const newWebsiteList = [...websiteList];
		newWebsiteList[index][name] = value;
		setWebsiteList(newWebsiteList);
	};

	const handleRemoveWebsite = (idx) => {
		const newWebsiteList = [...websiteList];
		newWebsiteList.splice(idx, 1);
		setWebsiteList(newWebsiteList);
	};

	//func to update website
	const updateOrgWebsite = async () => {
		let url = `${getBaseUrl()}/api/org/updateOrgWebsite`;
		oldWebisteList.forEach(async (wb, idx) => {
			try {
				await apiCall(url, "POST", wb).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleWebisteEdit();
		return;
	};

	//func to delete website
	const deleteOrgWebsite = async () => {
		try {
			let url = `${getBaseUrl()}/api/org/deleteOrgWebsite`;
			await apiCall(url, "POST", deleteObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getOrgProfile(id);
				toggleWebisteEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to add website
	const addOrgWebsite = async () => {
		let url = `${getBaseUrl()}/api/org/addOrgWebsite`;
		websiteList.forEach(async (wb, idx) => {
			try {
				await apiCall(url, "POST", wb).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleWebisteEdit();
		setWebsiteList([]);
		return;
	};

	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Website
					</Heading>
				</Badge>
				{websiteEdit === false ? (
					<IconButton
						onClick={toggleWebisteEdit}
						size="xs"
						icon={<EditIcon />}
					/>
				) : (
					<IconButton
						onClick={toggleWebisteEdit}
						size="xs"
						icon={<CloseIcon />}
					/>
				)}
			</HStack>
			<Divider />
			{websiteEdit === false ? (
				<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
					<UnorderedList pl={10}>
						{orgProfile.websites?.map((web) => (
							<ListItem key={web.id}>
								<Text>{web.website_url}</Text>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>
			) : (
				<>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isDeleteOpen}
								onClose={() => onDeleteClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Delete Website Url
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to delete the website url
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onDeleteClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													deleteOrgWebsite();
													onDeleteClose();
												}}
												ml={3}
											>
												Delete
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>

							<AlertDialog
								isOpen={isUpdateOpen}
								onClose={() => onUpdateClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Update Website Url
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to update the website urls
										</AlertDialogBody>
										<AlertDialogCloseButton />
										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onUpdateClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													updateOrgWebsite();
													onUpdateClose();
												}}
												ml={3}
											>
												Update
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Edit Website</Badge>
								<IconButton
									size="xs"
									icon={<CheckIcon />}
									onClick={() => onUpdateOpen()}
								/>
							</HStack>
							<Divider />
							{oldWebisteList && oldWebisteList.length > 0 && (
								<VStack gap={0} mt={2}>
									{oldWebisteList?.map((wb, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="website_url"
													type="url"
													placeholder="Url"
													size={"sm"}
													value={wb.website_url}
													onChange={(e) => {
														handleOldWebisteChange(e, idx);
													}}
												/>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => {
													setDeleteObj({ id: wb.id });
													onDeleteOpen();
												}}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isAddOpen}
								onClose={() => onAddClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Add Website Url
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to Add new website urls
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onAddClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													addOrgWebsite();
													onAddClose();
												}}
												ml={3}
											>
												Add
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Add New Website</Badge>
								<HStack>
									<IconButton
										size="xs"
										icon={<AddIcon />}
										onClick={handleAddWebsite}
									/>
									{websiteList && websiteList.length > 0 && (
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => onAddOpen()}
										/>
									)}
								</HStack>
							</HStack>
							<Divider />
							{websiteList && websiteList.length > 0 && (
								<VStack gap={0} mt={2}>
									{websiteList?.map((wb, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="website_url"
													type="url"
													placeholder="https://example.com"
													size={"sm"}
													value={wb.website_url}
													onChange={(e) => {
														handleWebisteChange(e, idx);
													}}
												/>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => handleRemoveWebsite(idx)}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
				</>
			)}
		</Box>
	);
};

export default WebsiteSec;
