import React, { useCallback } from "react";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const OrgTypes = () => {
	const { data, loading, error } = useFetch("/api/org/getAllOrgTypes");
	const navigate = useNavigate();

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Org Types data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Organization Type Name",
						data: "type_name",
						width: "40%",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={["ID", "Organization Type Name", "Updated On"]}
				navigatePath="/organization-types"
				tableId="viewOrgType"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Organization Type"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Organization Type
					</Highlight>
				</Heading>
				<Button
					size="xs"
					onClick={() => navigate("/organization-types/create")}
				>
					Create
				</Button>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default OrgTypes;
