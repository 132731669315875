import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import Publication from "./Publication";

function SinglePublication() {
	const { id } = useParams();
	const { data, error, loading } = useFetch(
		`/api/publications/getPublication/${id}`
	);
	const renderPageData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error || !data) return <p>Error getting publications data</p>;
		return <Publication publicationData={data} />;
	}, [data, error, loading]);

	return <Navigation>{renderPageData()}</Navigation>;
}

export default SinglePublication;
