import { Box, Button, Grid, GridItem, HStack, VStack } from "@chakra-ui/react";
import {
	FileInput,
	MultiSelect,
	RichText,
	TextInput,
} from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React from "react";

function TechnicalReportForm({
	title = null,
	reportNumber = null,
	contractNumber = null,
	img = null,
	img_input = null,
	technicalReport = null,
	technicalReport_input = null,
	publicationDate = null,
	relatedRts = null,
	periodStart = null,
	periodEnd = null,
	abstract = null,
	researchTasks = [],
	tags = [],
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	onCancel = () => {},
	submitBtnText = "Add Technical Report",
	hideUploadSection = false,
}) {
	const { data: researchTasksData } = useFetch(
		"/api/researchTask/getAllResearchTasks"
	);
	const { data: tagsData } = useFetch("/api/tag/getAllTags");

	return (
		<Formik
			initialValues={{
				title,
				reportNumber,
				contractNumber,
				img,
				img_input,
				technicalReport,
				technicalReport_input,
				publicationDate,
				relatedRts,
				periodStart,
				periodEnd,
				abstract,
				researchTasks,
				tags,
			}}
			enableReinitialize
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					if (hideUploadSection) {
						delete values.img;
						delete values.technicalReport;
					}
					const body = { ...values };
					delete body.img_input;
					delete body.technicalReport_input;
					Object.entries(body).forEach(([key, val]) => {
						if (Array.isArray(val)) body[key] = JSON.stringify(val);
					});
					const res = await onSubmit(body);
					onSuccess(res);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<Box
						padding={"20px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						pb={5}
					>
						<VStack gap={3} alignItems="stretch">
							<Field
								name="title"
								component={TextInput}
								label="Technical Report Title"
								placeholder="Technical Report Title"
								required
							/>
							<HStack gap={3}>
								<Field
									name="reportNumber"
									component={TextInput}
									label="Report Number"
									placeholder="Report Number"
								/>
								<Field
									name="contractNumber"
									component={TextInput}
									label="Contract Number"
									placeholder="Contract Number"
								/>
							</HStack>
							{!hideUploadSection && (
								<HStack gap={3}>
									<Field
										name="img_input"
										component={FileInput}
										label="Image"
										type="file"
										accept="image/*"
									/>
									<Field
										name="technicalReport_input"
										component={FileInput}
										label="Technical Report Doc"
										type="file"
										accept="application/pdf"
									/>
								</HStack>
							)}
							<Grid templateColumns="repeat(2 , 1fr)" gap={5}>
								<GridItem>
									<Field
										name="publicationDate"
										component={TextInput}
										label="Publication Date"
										type="date"
										required
									/>
								</GridItem>
								<GridItem />
							</Grid>
							<Field
								name="researchTasks"
								component={MultiSelect}
								label="Research Tasks"
								placeholder="Select Research Tasks (Multi Select)"
								options={(researchTasksData || []).map((rt) => ({
									value: rt.id,
									label: `${rt.task_number} - ${rt.task_name}`,
								}))}
								isMulti
								required
							/>
							<Field
								name="tags"
								component={MultiSelect}
								label="Tags"
								placeholder="Select Tags (Multi Select)"
								options={(tagsData || []).map((t) => ({
									value: t.id,
									label: t.tag_title,
								}))}
								isMulti
							/>
							<Field
								name="relatedRts"
								component={RichText}
								label="Related RTs"
							/>
							<HStack gap={3}>
								<Field
									name="periodStart"
									component={TextInput}
									label="Period Start Date"
									type="date"
								/>
								<Field
									name="periodEnd"
									component={TextInput}
									label="Period End Date"
									type="date"
								/>
							</HStack>
							<Field name="abstract" component={RichText} label="Abstract" />
						</VStack>
					</Box>
					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button onClick={onCancel} size="xs">
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={isSubmitting}
							loadingText="Adding Publication"
						>
							{submitBtnText}
						</Button>
					</HStack>
				</Form>
			)}
		</Formik>
	);
}

export default TechnicalReportForm;
