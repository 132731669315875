import React, { useContext, useEffect, useState } from "react";
import { getBaseUrl } from "base";
import Navigation from "components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate, useParams } from "react-router-dom";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	FormLabel,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { RichTextEditor } from "@mantine/rte";
import { EditIcon, CloseIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";

const ThrustProfile = () => {
	const { apiCall, toastMsg } = useContext(AccountContext);
	const { id } = useParams();
	const [thrustProfile, setThrustProfile] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const [infoObj, setInfoObj] = useState({});
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const navigate = useNavigate();

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	//func to get single thrust profile
	const getThrustProfile = async (id) => {
		let url = `${getBaseUrl()}/api/researchThrusts/getResearchThrust/${id}`;
		const { data } = await apiCall(url);
		setThrustProfile(data);
		return;
	};

	useEffect(() => {
		getThrustProfile(id);
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		setInfoObj({
			thrust_name: thrustProfile.thrust_name,
			description: thrustProfile.description,
			is_public: thrustProfile.is_public,
		});
	}, [thrustProfile]);

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//func to update thrust
	const updateThrust = async () => {
		try {
			let url = `${getBaseUrl()}/api/researchThrusts/updateResearchThrust/${id}`;
			await apiCall(url, "POST", infoObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getThrustProfile(id);
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getThrustProfile(id);
			toggleInfoEdit();
			return;
		}
	};

	//func to delete project
	const deleteThrust = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/researchThrusts/deleteResearchThrust/${id}`;
			await apiCall(url, "POST").then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/research-thrusts");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			<VStack gap={1} align={"stretch"} pb={20}>
				<form>
					<AlertDialog
						isOpen={isDeleteOpen}
						onClose={() => onDeleteClose()}
						closeOnOverlayClick={false}
						motionPreset="slideInBottom"
					>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									Delete Research Thrust
								</AlertDialogHeader>
								<AlertDialogCloseButton />
								<AlertDialogBody>
									Are you sure?
									<br />
									Confirm to delete Research Thrust
								</AlertDialogBody>

								<AlertDialogFooter>
									<Button size={"sm"} onClick={() => onDeleteClose()}>
										Cancel
									</Button>
									<Button
										type="submit"
										size={"sm"}
										isLoading={addBtnStatus}
										loadingText="Deleting"
										onClick={() => {
											deleteThrust();
											onDeleteClose();
										}}
										ml={3}
									>
										Delete
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>

					<AlertDialog
						isOpen={isUpdateOpen}
						onClose={() => onUpdateClose()}
						closeOnOverlayClick={false}
						motionPreset="slideInBottom"
					>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									Update Research Thrust
								</AlertDialogHeader>

								<AlertDialogBody>
									Are you sure?
									<br />
									Confirm to update.
								</AlertDialogBody>
								<AlertDialogCloseButton />
								<AlertDialogFooter>
									<Button size={"sm"} onClick={() => onUpdateClose()}>
										Cancel
									</Button>
									<Button
										type="submit"
										size={"sm"}
										onClick={() => {
											updateThrust();
											onUpdateClose();
										}}
										ml={3}
									>
										Update
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
					>
						<HStack justifyContent={"space-between"}>
							<Badge>
								<Heading as="h6" size="xs">
									Research Thrust Information
								</Heading>
							</Badge>
							<HStack>
								{infoEdit === false ? (
									<IconButton
										onClick={toggleInfoEdit}
										size="xs"
										icon={<EditIcon />}
									/>
								) : (
									<>
										<IconButton
											onClick={toggleInfoEdit}
											size="xs"
											icon={<CloseIcon />}
										/>
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => {
												onUpdateOpen();
											}}
										/>
									</>
								)}
							</HStack>
						</HStack>
						<Divider />
						{infoEdit === false ? (
							<VStack align={"stretch"} px={5}>
								<VStack
									align={"stretch"}
									justifyContent="flex-start"
									pb={5}
									pt={3}
								>
									<Heading as="h2" size="xl">
										{thrustProfile.thrust_name}
									</Heading>
									<VStack
										align={"stretch"}
										justifyContent="flex-start"
										pb={5}
										// pt={5}
									>
										<Text>
											Public:{" "}
											<Badge colorScheme="green">
												{thrustProfile.is_public}
											</Badge>
										</Text>{" "}
										<UnorderedList pl={5}>
											<ListItem>
												Created on:{" "}
												{new Date(
													thrustProfile.created_at
												).toLocaleDateString()}
											</ListItem>
											<ListItem>
												Updated on:{" "}
												{new Date(
													thrustProfile.updated_at
												).toLocaleDateString()}
											</ListItem>
										</UnorderedList>
									</VStack>
									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Research Programs</Badge>
										<UnorderedList pl={10}>
											{thrustProfile.researchPrograms?.map((rp) => (
												<ListItem key={rp.id}>
													<Text>{rp.program_name}</Text>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>

									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Description</Badge>
										<Text
											px={10}
											dangerouslySetInnerHTML={{
												__html: thrustProfile.description,
											}}
										/>
									</VStack>

									<Divider />
									<br />
									<HStack justifyContent={"center"}>
										<Button
											rightIcon={<DeleteIcon />}
											w={"10%"}
											onClick={() => {
												onDeleteOpen();
											}}
										>
											Delete
										</Button>
									</HStack>
								</VStack>
							</VStack>
						) : (
							<VStack py={2} gap={2} mt={5}>
								<VStack gap={3} w={"full"} px={5} pb={5}>
									<FormControl isRequired>
										<FormLabel>Research Thrust Title</FormLabel>
										<Input
											type="text"
											name="thrust_name"
											value={infoObj.thrust_name}
											onChange={handleInfoChange}
										/>
									</FormControl>

									<FormControl isRequired>
										<FormLabel>Public</FormLabel>
										<ChakraSelect
											name="is_public"
											placeholder="Select option"
											value={infoObj.is_public}
											onChange={handleInfoChange}
										>
											<option value="Yes">Yes</option>
											<option value="No">No</option>
										</ChakraSelect>
									</FormControl>

									{/* <FormControl>
									<FormLabel>Research Programs (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Research Programs"
										defaultValue={infoObj.defRP}
										options={rpList?.map((i) => {
											return {
												value: i.id,
												label: i.program_name,
											};
										})}
										onChange={(e) => {
											let rpL = [];
											e.forEach((l) => {
												rpL.push(l.value);
											});
											setInfoObj((prev) => {
												return { ...prev, research_programs: rpL };
											});
										}}
									/>
								</FormControl> */}

									<FormControl style={{ zIndex: 0 }} isRequired>
										<FormLabel>Description</FormLabel>
										<RichTextEditor
											value={infoObj.description}
											onChange={(e) => {
												setInfoObj((prev) => {
													return { ...prev, description: e };
												});
											}}
											id="rte1"
										/>
									</FormControl>
								</VStack>
							</VStack>
						)}
					</Box>
				</form>
			</VStack>
		</Navigation>
	);
};

export default ThrustProfile;
