import React from "react";
import { Heading, HStack, Badge, Box, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const Reports = ({ peopleProfile }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Technical Reports
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<br />
			<Datatable
				data={peopleProfile.technicalReports}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Title",
						data: "title",
						width: "30%",
					},
					{
						className: "dt-body-left",
						title: "Report Number",
						data: "report_number",
					},
					{
						className: "dt-body-left",
						title: "Contract Number",
						data: "contract_number",
					},
					{
						className: "dt-body-left",
						title: "Published Date",
						data: "publication_date",
					},
					{
						className: "dt-body-left",
						title: "Period Start",
						data: "period_start",
					},
					{
						className: "dt-body-left",
						title: "Period End",
						data: "period_end",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
						render: function (dt) {
							return getFormattedDateStr(new Date(dt));
						},
					},
				]}
				theaders={[
					"ID",
					"Title",
					"Report Number",
					"Contract Number",
					"Published Date",
					"Period Start",
					"Period End",
					"Updated On",
				]}
				navigatePath="/technical-reports"
				tableId="viewTechnicalReports"
			/>
		</Box>
	);
};

export default Reports;
