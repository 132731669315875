import React, { useContext } from "react";
import { Flex, Heading, Text, Icon, Stack, Image } from "@chakra-ui/react";
import { TbError404 } from "react-icons/tb";
import mainLogo from "assets/serc-logo.png";
import { AccountContext } from "contexts/AccountContext";
import { Link } from "react-router-dom";

const PageNotFound = () => {
	const { session } = useContext(AccountContext);

	return (
		<Flex
			width="100%"
			height="100vh"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
		>
			<Stack align={"center"} pb={5}>
				<Image width="200px" src={mainLogo} alt="SERC" />
			</Stack>
			<Icon as={TbError404} w={16} h={16} color="gray.500" />
			<Heading size="lg" mt={4}>
				Page Not Found
			</Heading>
			<Text mt={4} mb={2}>
				Sorry, the page you are looking for could not be found.
			</Text>
			{session ? (
				<Text>
					Take me back to{" "}
					<Link
						to="/homepage"
						style={{
							color: "var(--chakra-colors-blue-400)",
						}}
					>
						Homepage
					</Link>
				</Text>
			) : (
				<Text fontSize="sm">
					Login or Register with{" "}
					<Link
						to="/"
						style={{
							color: "var(--chakra-colors-blue-400)",
						}}
					>
						SERC
					</Link>{" "}
					to continue.
				</Text>
			)}
		</Flex>
	);
};

export default PageNotFound;
