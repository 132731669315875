import React, { useContext, useEffect, useState } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormLabel,
	FormControl,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import {
	EditIcon,
	CloseIcon,
	CheckIcon,
	DeleteIcon,
	AddIcon,
} from "@chakra-ui/icons";
import { AccountContext } from "contexts/AccountContext";
import countryList from "utils/data/Country-State.json";
import { getBaseUrl } from "../../../../base";
import axios from "axios";

const AddressSec = ({ orgProfile, getOrgProfile, id }) => {
    const { toastMsg, apiCall } = useContext(AccountContext);

		const {
			isOpen: isUpdateOpen,
			onOpen: onUpdateOpen,
			onClose: onUpdateClose,
		} = useDisclosure();
		const {
			isOpen: isDeleteOpen,
			onOpen: onDeleteOpen,
			onClose: onDeleteClose,
		} = useDisclosure();

		const [deleteObj, setDeleteObj] = useState({ id: "" });

		const {
			isOpen: isAddOpen,
			onOpen: onAddOpen,
			onClose: onAddClose,
		} = useDisclosure();

	const [addressEdit, setAddressEdit] = useState(false);
	const toggleAddressEdit = () => {
		setAddressEdit(!addressEdit);
	};

	const [oldAddressList, setOldAddressList] = useState([]);

	useEffect(() => {
		setOldAddressList(
			orgProfile.addresses?.map((ad) => {
				let cStates = countryList.find((i) => i.country_name === ad.country);
				return {
					id: ad.id,
					address_1: ad.address_1,
					address_2: ad.address_2,
					city_town: ad.city_town,
					state_province_region: ad.state_province_region,
					zip_postal_code: ad.zip_postal_code,
					country: ad.country,
					address_type: ad.address_type,
					stateList: cStates ? cStates.states : [],
				};
			})
		);
	}, [orgProfile]);

	const handleOldAddressChange = (e, index) => {
		const { name, value } = e.target;
		const newAddressList = [...oldAddressList];
		newAddressList[index][name] = value;
		setOldAddressList(newAddressList);
	};

	const [addressList, setAddressList] = useState([]);

	const handleAddAddress = () => {
		setAddressList([
			...addressList,
			{
				organization_id: orgProfile.id,
				address_1: "",
				address_2: "",
				city_town: "",
				state_province_region: "",
				zip_postal_code: "",
				country: "",
				address_type: "",
				stateList: [],
			},
		]);
	};

	const handleAddressChange = (e, index) => {
		const { name, value } = e.target;
		const newAddressList = [...addressList];
		newAddressList[index][name] = value;
		setAddressList(newAddressList);
	};

	const handleRemoveAddress = (idx) => {
		const newAddressList = [...addressList];
		newAddressList.splice(idx, 1);
		setAddressList(newAddressList);
	};

	//func to update address
	const updateOrgAddress = async () => {
		let url = `${getBaseUrl()}/api/org/updateOrgAddress`;
		oldAddressList.forEach(async (ad, idx) => {
			try {
				await apiCall(url, "POST", ad).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleAddressEdit();
		return;
	};

	//func to delete address
	const deleteOrgAddress = async () => {
		try {
			let url = `${getBaseUrl()}/api/org/deleteOrgAddress`;
			await apiCall(url, "POST", deleteObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getOrgProfile(id);
				toggleAddressEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to add address
	const addOrgAddress = async () => {
		let url = `${getBaseUrl()}/api/org/addOrgAddress`;
		addressList.forEach(async (ad, idx) => {
			try {
				await apiCall(url, "POST", ad).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleAddressEdit();
		setAddressList([]);
		return;
	};

	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Address
					</Heading>
				</Badge>
				{addressEdit === false ? (
					<IconButton
						onClick={toggleAddressEdit}
						size="xs"
						icon={<EditIcon />}
					/>
				) : (
					<IconButton
						onClick={toggleAddressEdit}
						size="xs"
						icon={<CloseIcon />}
					/>
				)}
			</HStack>
			<Divider />
			{addressEdit === false ? (
				<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
					<UnorderedList pl={10} style={{ listStyleType: "disc" }}>
						{orgProfile.addresses?.map((ad) => (
							<ListItem key={ad.id} display={"inline-block"} mr={10}>
								<VStack w={"full"} textAlign="left" pb={3}>
									<HStack w="100%">
										<Text>
											{ad.address_1}, {ad.address_2}
										</Text>
										<Text>
											<Badge size={"xs"} variant="outline">
												{ad.address_type}
											</Badge>
										</Text>
									</HStack>
									<Text w="100%">Country: {ad.country}</Text>
									<Text w="100%">
										State/Province/Region: {ad.state_province_region}
									</Text>
									<Text w="100%">City/Town: {ad.city_town}</Text>
									<Text w="100%">Zip/Postal Code: {ad.zip_postal_code}</Text>
								</VStack>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>
			) : (
				<>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isDeleteOpen}
								onClose={() => onDeleteClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Delete Address
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to delete the address
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onDeleteClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													deleteOrgAddress();
													onDeleteClose();
												}}
												ml={3}
											>
												Delete
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>

							<AlertDialog
								isOpen={isUpdateOpen}
								onClose={() => onUpdateClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Update Address
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to update the addresses
										</AlertDialogBody>
										<AlertDialogCloseButton />
										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onUpdateClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													updateOrgAddress();
													onUpdateClose();
												}}
												ml={3}
											>
												Update
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Edit Address</Badge>
								<IconButton
									size="xs"
									icon={<CheckIcon />}
									onClick={() => onUpdateOpen()}
								/>
							</HStack>
							<Divider />
							{oldAddressList && oldAddressList.length > 0 && (
								<VStack gap={0} mt={2}>
									{oldAddressList?.map((ad, idx) => (
										<HStack w={"full"} px={5} pb={8} key={idx}>
											<VStack key={idx} gap={2} pr={5} w={"full"}>
												<Divider />
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>Address 1</FormLabel>
														<Input
															name="address_1"
															type="text"
															size={"sm"}
															value={ad.address_1}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl>
														<FormLabel>Address 2</FormLabel>
														<Input
															name="address_2"
															type="text"
															size={"sm"}
															value={ad.address_2}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														/>
													</FormControl>
												</HStack>
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>Country</FormLabel>
														<ChakraSelect
															name="country"
															type="text"
															placeholder="Select Country"
															size={"sm"}
															value={ad.country}
															onChange={(e) => {
																const cName = e.target.value;
																const newAddressList = [...oldAddressList];
																let country = countryList.find(
																	(i) => i.country_name === cName
																);
																newAddressList[idx]["country"] =
																	country.country_name;
																newAddressList[idx]["stateList"] =
																	country.states;
																setOldAddressList(newAddressList);
															}}
														>
															{countryList?.map((c) => (
																<option
																	key={c.country_id}
																	value={c.country_name}
																>
																	{c.country_name}
																</option>
															))}
														</ChakraSelect>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>State/Province/Region</FormLabel>
														<ChakraSelect
															name="state_province_region"
															type="text"
															placeholder="Select State/Province/Region"
															size={"sm"}
															value={ad.state_province_region}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														>
															{ad.stateList?.map((s) => (
																<option key={s.state_id} value={s.state_name}>
																	{s.state_name}
																</option>
															))}
														</ChakraSelect>
													</FormControl>
												</HStack>
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>City/Town</FormLabel>
														<Input
															name="city_town"
															type="text"
															size={"sm"}
															value={ad.city_town}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>Zip/Postal Code</FormLabel>
														<Input
															name="zip_postal_code"
															type="text"
															size={"sm"}
															value={ad.zip_postal_code}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>Address Type</FormLabel>
														<ChakraSelect
															name="address_type"
															placeholder="select one"
															size={"sm"}
															value={ad.address_type}
															onChange={(e) => {
																handleOldAddressChange(e, idx);
															}}
														>
															<option value="Personal">Personal</option>
															<option value="Work">Work</option>
															<option value="Other">Other</option>
														</ChakraSelect>
													</FormControl>
												</HStack>
											</VStack>
											<Divider height="200px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => {
													setDeleteObj({ id: ad.id });
													onDeleteOpen();
												}}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isAddOpen}
								onClose={() => onAddClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Add Address
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to Add new addresses
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onAddClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													addOrgAddress();
													onAddClose();
												}}
												ml={3}
											>
												Add
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Add New Address</Badge>
								<HStack>
									<IconButton
										size="xs"
										icon={<AddIcon />}
										onClick={handleAddAddress}
									/>
									{addressList && addressList.length > 0 && (
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => onAddOpen()}
										/>
									)}
								</HStack>
							</HStack>
							<Divider />
							{addressList && addressList.length > 0 && (
								<VStack gap={0} mt={2}>
									{addressList?.map((ad, idx) => (
										<HStack w={"full"} px={5} pb={8} key={idx}>
											<VStack key={idx} gap={2} pr={5} w={"full"}>
												<Divider />
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>Address 1</FormLabel>
														<Input
															name="address_1"
															type="text"
															size={"sm"}
															value={ad.address_1}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl>
														<FormLabel>Address 2</FormLabel>
														<Input
															name="address_2"
															type="text"
															size={"sm"}
															value={ad.address_2}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														/>
													</FormControl>
												</HStack>
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>Country</FormLabel>
														<ChakraSelect
															name="country"
															type="text"
															placeholder="Select Country"
															size={"sm"}
															value={ad.country}
															onChange={(e) => {
																const cName = e.target.value;
																const newAddressList = [...addressList];
																let country = countryList.find(
																	(i) => i.country_name === cName
																);
																newAddressList[idx]["country"] =
																	country.country_name;
																newAddressList[idx]["stateList"] =
																	country.states;
																setAddressList(newAddressList);
															}}
														>
															{countryList?.map((c) => (
																<option
																	key={c.country_id}
																	value={c.country_name}
																>
																	{c.country_name}
																</option>
															))}
														</ChakraSelect>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>State/Province/Region</FormLabel>
														<ChakraSelect
															name="state_province_region"
															type="text"
															placeholder="Select State/Province/Region"
															size={"sm"}
															value={ad.state_province_region}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														>
															{ad.stateList?.map((s) => (
																<option key={s.state_id} value={s.state_name}>
																	{s.state_name}
																</option>
															))}
														</ChakraSelect>
													</FormControl>
												</HStack>
												<HStack gap={5} w={"full"}>
													<FormControl isRequired>
														<FormLabel>City/Town</FormLabel>
														<Input
															name="city_town"
															type="text"
															size={"sm"}
															value={ad.city_town}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>Zip/Postal Code</FormLabel>
														<Input
															name="zip_postal_code"
															type="text"
															size={"sm"}
															value={ad.zip_postal_code}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														/>
													</FormControl>
													<FormControl isRequired>
														<FormLabel>Address Type</FormLabel>
														<ChakraSelect
															name="address_type"
															placeholder="select one"
															size={"sm"}
															value={ad.address_type}
															onChange={(e) => {
																handleAddressChange(e, idx);
															}}
														>
															<option value="Personal">Personal</option>
															<option value="Work">Work</option>
															<option value="Other">Other</option>
														</ChakraSelect>
													</FormControl>
												</HStack>
											</VStack>
											<Divider height="200px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => handleRemoveAddress(idx)}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
				</>
			)}
		</Box>
	);
};

export default AddressSec;
