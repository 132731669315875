import React, { useEffect, useState, useContext } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	FormLabel,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountContext } from "contexts/AccountContext";
import Select from "react-select";
import { RichTextEditor } from "@mantine/rte";
import { EditIcon, CloseIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import { getBaseUrl } from "../../../base";
import axios from "axios";

const ProfileTab = ({ getProjectProfile, projectProfile }) => {
	const { id } = useParams();
	const [infoEdit, setInfoEdit] = useState(false);
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [peopleList, setPeopleList] = useState([]);
	const [rpList, setRP] = useState([]);
	const [tList, setT] = useState([]);
	const [infoObj, setInfoObj] = useState({});
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const navigate = useNavigate();

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	//func to get all tag
	const getAllTag = async () => {
		let url = `${getBaseUrl()}/api/tag/getAllTags`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to get all tag
	const getAllResearchPrograms = async () => {
		let url = `${getBaseUrl()}/api/researchPrograms/getAllResearchPrograms`;
		const { data } = await apiCall(url);
		return data;
	};

	// func to get all people
	const getAllPeople = async () => {
		let url = `${getBaseUrl()}/api/people/getAllPeopleWithOrg`;
		const { data } = await apiCall(url);
		return data;
	};

	useEffect(() => {
		getProjectProfile(id);
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		getAllTag().then((res) => {
			setT(res);
		});
	}, []);

	useEffect(() => {
		getAllResearchPrograms().then((res) => {
			setRP(res);
		});
	}, []);

	useEffect(() => {
		getAllPeople().then((res) => {
			setPeopleList(res);
		});
	}, []);

	useEffect(() => {
		let centerVal = projectProfile.centers?.map((i) => {
			return { value: i, label: i.toUpperCase() };
		});
		let researchPgVal = [];
		let piVal = [];
		let copiVal = [];
		let tagVal = [];

		projectProfile.researchPrograms?.forEach((e) => {
			let rpObj = rpList.find((i) => i.id === e.id);
			if (rpObj !== undefined) {
				researchPgVal.push({ value: rpObj.id, label: rpObj.program_name });
			}
		});

		projectProfile.pi?.forEach((e) => {
			let piObj = peopleList.find((i) => i.id === e.id);
			if (piObj !== undefined) {
				piVal.push({
					value: piObj.id,
					label: `${piObj.first_name} ${piObj.last_name}`,
				});
			}
		});

		projectProfile.co?.forEach((e) => {
			let copiObj = peopleList.find((i) => i.id === e.id);
			if (copiObj !== undefined) {
				copiVal.push({
					value: copiObj.id,
					label: `${copiObj.first_name} ${copiObj.last_name}`,
				});
			}
		});

		projectProfile.tags?.forEach((e) => {
			let tagObj = tList.find((i) => i.id === e.id);
			if (tagObj !== undefined) {
				tagVal.push({ value: tagObj.id, label: tagObj.tag_title });
			}
		});

		setInfoObj({
			project_id: id,
			project_title: projectProfile.project_title,
			project_unique_id: projectProfile.project_unique_id,
			abstract: projectProfile.abstract,
			deliverable_summary: projectProfile.deliverable_summary,
			transition: projectProfile.transition,
			is_public: projectProfile.is_public,
			research_programs: projectProfile.research_programs?.map((i) => i.id),
			pi: projectProfile.pi?.map((i) => i.id),
			copi: projectProfile.co?.map((i) => i.id),
			tags: projectProfile.tags?.map((i) => i.id),
			defCT: centerVal,
			defRP: researchPgVal,
			defPI: piVal,
			defCO: copiVal,
			defTG: tagVal,
		});
	}, [projectProfile]);

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//func to update project
	const updateProject = async () => {
		try {
			let url = `${getBaseUrl()}/api/projects/updateProject`;
			delete infoObj.defCT;
			delete infoObj.defRP;
			delete infoObj.defPI;
			delete infoObj.defCO;
			delete infoObj.defTG;
			await apiCall(url, "POST", infoObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getProjectProfile(id);
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			toggleInfoEdit();
			getProjectProfile(id);
			return;
		}
	};

	//func to delete project
	const deleteProject = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/projects/deleteProject`;
			await apiCall(url, "POST", { project_id: id }).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/projects");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<VStack gap={1} align={"stretch"} pb={20}>
			<form>
				<AlertDialog
					isOpen={isDeleteOpen}
					onClose={() => onDeleteClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Delete Project
							</AlertDialogHeader>
							<AlertDialogCloseButton />
							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to delete Project
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onDeleteClose()}>
									Cancel
								</Button>
								<Button
									type="submit"
									size={"sm"}
									isLoading={addBtnStatus}
									loadingText="Deleting"
									onClick={() => {
										deleteProject();
										onDeleteClose();
									}}
									ml={3}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>

				<AlertDialog
					isOpen={isUpdateOpen}
					onClose={() => onUpdateClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Update Project Info
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to update.
							</AlertDialogBody>
							<AlertDialogCloseButton />
							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onUpdateClose()}>
									Cancel
								</Button>
								<Button
									type="submit"
									size={"sm"}
									onClick={() => {
										updateProject();
										onUpdateClose();
									}}
									ml={3}
								>
									Update
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				<Box
					padding={"10px"}
					borderWidth={1}
					borderRadius={8}
					boxShadow="lg"
					rounded="xl"
				>
					<HStack justifyContent={"space-between"}>
						<Badge>
							<Heading as="h6" size="xs">
								Project Information
							</Heading>
						</Badge>
						<HStack>
							{infoEdit === false ? (
								<IconButton
									onClick={toggleInfoEdit}
									size="xs"
									icon={<EditIcon />}
								/>
							) : (
								<>
									<IconButton
										onClick={toggleInfoEdit}
										size="xs"
										icon={<CloseIcon />}
									/>
									<IconButton
										size="xs"
										icon={<CheckIcon />}
										onClick={() => {
											onUpdateOpen();
										}}
									/>
								</>
							)}
						</HStack>
					</HStack>
					<Divider />
					{infoEdit === false ? (
						<VStack align={"stretch"} px={5}>
							<VStack
								align={"stretch"}
								justifyContent="flex-start"
								pb={5}
								pt={3}
							>
								<Heading as="h2" size="xl">
									{projectProfile.project_title}
								</Heading>
								<VStack
									align={"stretch"}
									justifyContent="flex-start"
									pb={5}
									// pt={5}
								>
									<Text>
										Public:{" "}
										<Badge colorScheme="green">
											{projectProfile.is_public}
										</Badge>
									</Text>{" "}
									<UnorderedList pl={5}>
										<ListItem>
											Created on:{" "}
											{new Date(projectProfile.created_at).toLocaleDateString()}
										</ListItem>
										<ListItem>
											Updated on:{" "}
											{new Date(projectProfile.updated_at).toLocaleDateString()}
										</ListItem>
									</UnorderedList>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Center</Badge>
									<UnorderedList pl={10}>
										{projectProfile.centers?.map((c, idx) => (
											<ListItem key={idx}>
												<Text>{c.toUpperCase()}</Text>
											</ListItem>
										))}
									</UnorderedList>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Research Programs</Badge>
									<UnorderedList pl={10}>
										{projectProfile.researchPrograms?.map((rp) => (
											<ListItem key={rp.id}>
												<Text>{rp.program_name}</Text>
											</ListItem>
										))}
									</UnorderedList>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Principal Investigators</Badge>
									<UnorderedList pl={10}>
										{projectProfile.pi?.map((pi) => (
											<ListItem key={pi.id}>
												<Text>
													{pi.first_name} {pi.last_name}
												</Text>
											</ListItem>
										))}
									</UnorderedList>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Co-Principal Investigators</Badge>
									<UnorderedList pl={10}>
										{projectProfile.co?.map((co) => (
											<ListItem key={co.id}>
												<Text>
													{co.first_name} {co.last_name}
												</Text>
											</ListItem>
										))}
									</UnorderedList>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Abstract</Badge>
									<Text
										px={10}
										dangerouslySetInnerHTML={{
											__html: projectProfile.abstract,
										}}
									/>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Deliverable Summary</Badge>
									<Text
										px={10}
										dangerouslySetInnerHTML={{
											__html: projectProfile.deliverable_summary,
										}}
									/>
								</VStack>
								<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
									<Badge>Transition</Badge>
									<Text
										px={10}
										dangerouslySetInnerHTML={{
											__html: projectProfile.transition,
										}}
									/>
								</VStack>
								<Divider />
								<br />
								<HStack justifyContent={"center"}>
									<Button
										rightIcon={<DeleteIcon />}
										w={"10%"}
										onClick={() => {
											onDeleteOpen();
										}}
									>
										Delete
									</Button>
								</HStack>
							</VStack>
						</VStack>
					) : (
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Projet Title</FormLabel>
									<Input
										type="text"
										name="project_title"
										value={infoObj.project_title}
										onChange={handleInfoChange}
									/>
								</FormControl>
								<HStack gap={5} w={"full"}>
									<FormControl isRequired>
										<FormLabel>Project Unique ID</FormLabel>
										<Input
											type="number"
											name="project_unique_id"
											value={infoObj.project_unique_id}
											onChange={handleInfoChange}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Public</FormLabel>
										<ChakraSelect
											name="is_public"
											placeholder="Select option"
											value={infoObj.is_public}
											onChange={handleInfoChange}
										>
											<option value="Yes">Yes</option>
											<option value="No">No</option>
										</ChakraSelect>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Center</FormLabel>
										<Select
											isMulti
											placeholder="Select Center (Multi Select)"
											defaultValue={infoObj.defCT}
											options={[
												{ value: "serc", label: "SERC" },
												{ value: "airc", label: "AIRC" },
											]}
											onChange={(e) => {
												let cList = [];
												e.forEach((c) => {
													cList.push(c.value);
												});
												setInfoObj((prev) => {
													return {
														...prev,
														centers: cList,
													};
												});
											}}
										/>
									</FormControl>
								</HStack>

								<FormControl>
									<FormLabel>Research Programs (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Research Programs"
										defaultValue={infoObj.defRP}
										options={rpList?.map((i) => {
											return {
												value: i.id,
												label: i.program_name,
											};
										})}
										onChange={(e) => {
											let rpL = [];
											e.forEach((l) => {
												rpL.push(l.value);
											});
											setInfoObj((prev) => {
												return { ...prev, research_programs: rpL };
											});
										}}
									/>
								</FormControl>

								<FormControl isRequired>
									<FormLabel>Principal Investigator (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Principal Investigator"
										defaultValue={infoObj.defPI}
										options={peopleList?.map((i) => {
											return {
												value: i.id,
												label: `${i.first_name} ${i.last_name}`,
											};
										})}
										onChange={(e) => {
											let peopleL = [];
											e.forEach((l) => {
												peopleL.push(l.value);
											});
											setInfoObj((prev) => {
												return { ...prev, pi: peopleL };
											});
										}}
									/>
								</FormControl>

								<FormControl>
									<FormLabel>
										Co-Principal Investigator (Multi Select)
									</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Co-Principal Investigator"
										defaultValue={infoObj.defCO}
										options={peopleList?.map((i) => {
											return {
												value: i.id,
												label: `${i.first_name} ${i.last_name}`,
											};
										})}
										onChange={(e) => {
											let peopleL = [];
											e.forEach((l) => {
												peopleL.push(l.value);
											});
											setInfoObj((prev) => {
												return { ...prev, copi: peopleL };
											});
										}}
									/>
								</FormControl>

								<FormControl>
									<FormLabel>Tag (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Tag"
										defaultValue={infoObj.defTG}
										options={tList?.map((i) => {
											return {
												value: i.id,
												label: i.tag_title,
											};
										})}
										onChange={(e) => {
											let tagL = [];
											e.forEach((l) => {
												tagL.push(l.value);
											});
											setInfoObj((prev) => {
												return { ...prev, tags: tagL };
											});
										}}
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }} isRequired>
									<FormLabel>Abstract</FormLabel>
									<RichTextEditor
										value={infoObj.abstract}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, abstract: e };
											});
										}}
										id="rte1"
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Deliverable Summary</FormLabel>
									<RichTextEditor
										value={infoObj.deliverable_summary}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, deliverable_summary: e };
											});
										}}
										id="rte2"
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Transition</FormLabel>
									<RichTextEditor
										value={infoObj.transition}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, transition: e };
											});
										}}
										id="rte3"
									/>
								</FormControl>
							</VStack>
						</VStack>
					)}
				</Box>
			</form>
		</VStack>
	);
};

export default ProfileTab;
