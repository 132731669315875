import React, { useContext, useEffect, useState, useRef } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	FormLabel,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
	Image,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import Select from "react-select";
import { RichTextEditor } from "@mantine/rte";
import { EditIcon, CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";
import axios from "axios";

const InfoSec = ({ orgProfile, getOrgProfile, id, orgType }) => {
	const { toastMsg, apiCall } = useContext(AccountContext);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [infoEdit, setInfoEdit] = useState(false);

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	const [infoObj, setInfoObj] = useState({});
	const [orgImg, setOrgImg] = useState("");
	const [orgTypeList, setOrgTypeList] = useState([]);
	const [peopleList, setPeopleList] = useState([]);
	const [researchTaskList, setReserchTaskList] = useState([]);

	//func to get all org types
	const getAllOrgType = async () => {
		let url = `${getBaseUrl()}/api/org/getAllOrgTypes`;
		const { data } = await apiCall(url);
		return data;
	};

	// func to get all people
	const getAllPeople = async () => {
		let url = `${getBaseUrl()}/api/people/getAllPeopleWithOrg`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to get all research task
	const getAllResearchTask = async () => {
		let url = `${getBaseUrl()}/api/researchTask/getAllResearchTasks`;
		const { data } = await apiCall(url);
		return data;
	};

	useEffect(() => {
		getAllOrgType().then((res) => {
			setOrgTypeList(res);
		});
	}, []);

	useEffect(() => {
		getAllPeople().then((res) => {
			setPeopleList(res);
		});
	}, []);

	useEffect(() => {
		getAllResearchTask().then((res) => {
			setReserchTaskList(res);
		});
	}, []);

	useEffect(() => {
		let researchTaskVal = [];
		let peopleVal = [];

		orgProfile.researchTasks?.forEach((e) => {
			let rtObj = researchTaskList.find((i) => i.id === e.id);
			if (rtObj !== undefined) {
				researchTaskVal.push({ value: rtObj.id, label: rtObj.task_name });
			}
		});

		orgProfile.people?.forEach((e) => {
			let peopleObj = peopleList.find((i) => i.id === e.id);
			if (peopleObj !== undefined) {
				peopleVal.push({
					value: peopleObj.id,
					label: `${peopleObj.first_name} ${peopleObj.last_name}`,
				});
			}
		});

		setInfoObj({
			id: orgProfile.id,
			org_id: orgProfile.org_id,
			organization_name: orgProfile.organization_name,
			org_name_short: orgProfile.org_name_short,
			public: orgProfile.public,
			url: orgProfile.url,
			organization_type_id: orgProfile.organization_type_id,
			description: orgProfile.description,
			people: orgProfile.people?.map((i) => i.id),
			researchTasks: orgProfile.researchTasks?.map((i) => i.id),
			defPeople: peopleVal,
			defResearch: researchTaskVal,
		});
	}, [orgProfile]);

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//func to update org information
	const updateOrgInfo = async () => {
		try {
			let url = `${getBaseUrl()}/api/org/updateOrg`;
			let orgFormData = new FormData();
			orgFormData.append("id", infoObj.id);
			orgFormData.append("organization_name", infoObj.organization_name);
			orgFormData.append("org_name_short", infoObj.org_name_short);
			if (infoObj.org_id) orgFormData.append("org_id", infoObj.org_id);
			orgFormData.append("organization_type_id", infoObj.organization_type_id);
			orgFormData.append("description", infoObj.description);
			orgFormData.append("public", infoObj.public);
			orgFormData.append(
				"researchTasks",
				JSON.stringify(infoObj.researchTasks)
			);
			orgFormData.append("people", JSON.stringify(infoObj.people));
			if (orgImg !== "") {
				orgFormData.append("orgImg", orgImg);
			}
			const config = {
				"Content-Type": "multipart/form-data",
			};
			await apiCall(url, "POST", orgFormData, config).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getOrgProfile(id);
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			toggleInfoEdit();
			getOrgProfile(id);
			return;
		}
	};

	return (
		<form>
			<AlertDialog
				isOpen={isOpen}
				onClose={() => onClose()}
				closeOnOverlayClick={false}
				motionPreset="slideInBottom"
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Update Information
						</AlertDialogHeader>
						<AlertDialogCloseButton />
						<AlertDialogBody>
							Are you sure?
							<br />
							Confirm to save the changes for "{orgProfile.organization_name}"
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								size={"sm"}
								onClick={() => {
									onClose();
								}}
							>
								Cancel
							</Button>
							<Button
								type="submit"
								size={"sm"}
								onClick={() => {
									updateOrgInfo();
									onClose();
								}}
								ml={3}
							>
								Update
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			<Box
				padding={"10px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent={"space-between"}>
					<Badge>
						<Heading as="h6" size="xs">
							Org Information
						</Heading>
					</Badge>
					<HStack>
						{infoEdit === false ? (
							<IconButton
								onClick={toggleInfoEdit}
								size="xs"
								icon={<EditIcon />}
							/>
						) : (
							<>
								<IconButton
									onClick={toggleInfoEdit}
									size="xs"
									icon={<CloseIcon />}
								/>
								<IconButton
									size="xs"
									icon={<CheckIcon />}
									onClick={() => {
										onOpen();
									}}
								/>
							</>
						)}
					</HStack>
				</HStack>
				<Divider />
				{infoEdit === false ? (
					<VStack align={"stretch"} px={5}>
						<HStack justifyContent={"space-between"} pt={2}>
							<VStack
								align={"stretch"}
								justifyContent="flex-start"
								pb={5}
								pt={3}
							>
								<Heading as="h2" size="xl">
									{orgProfile.organization_name}
								</Heading>
								<Text>Organization ID: {orgProfile.org_id}</Text>
								<Text>Org Short Name: {orgProfile.org_name_short}</Text>
								<Text>
									Public: <Badge colorScheme="green">{orgProfile.public}</Badge>
								</Text>
							</VStack>
							<Image
								width={"250px"}
								borderRadius="3xl"
								boxShadow="xl"
								src={`${orgProfile.image}`}
								alt={orgProfile.organization_name}
							/>
						</HStack>
						<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
							<Badge>Organization Type</Badge>
							<Text pl={10}>{orgType.type_name}</Text>
						</VStack>
						<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
							<Badge>Description</Badge>
							<Text
								px={10}
								dangerouslySetInnerHTML={{ __html: orgProfile.description }}
							/>
						</VStack>
					</VStack>
				) : (
					<VStack py={2} gap={2} mt={5}>
						<VStack gap={3} w={"full"} px={5}>
							<FormControl isRequired>
								<FormLabel>Organization Name</FormLabel>
								<Input
									type="text"
									name="organization_name"
									value={infoObj.organization_name}
									onChange={handleInfoChange}
								/>
							</FormControl>
							<HStack gap={5} w={"full"}>
								<FormControl>
									<FormLabel>Organization (Short)</FormLabel>
									<Input
										type="text"
										name="org_name_short"
										value={infoObj.org_name_short}
										onChange={handleInfoChange}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Organization ID (Number)</FormLabel>
									<Input
										type="number"
										name="org_id"
										value={infoObj.org_id}
										onChange={handleInfoChange}
									/>
								</FormControl>
							</HStack>
							<HStack gap={5} w={"full"}>
								<FormControl isRequired>
									<FormLabel>Organization Type</FormLabel>
									<ChakraSelect
										name="organization_type_id"
										placeholder="Select Organization Type"
										onChange={handleInfoChange}
										value={infoObj.organization_type_id}
									>
										{orgTypeList.map((orgT, idx) => (
											<option value={orgT.id} key={idx}>
												{orgT.type_name}
											</option>
										))}
									</ChakraSelect>
								</FormControl>
								<FormControl>
									<FormLabel>Organization Image</FormLabel>
									<Input
										pt={1}
										name="image"
										type="file"
										// size={"sm"}
										accept=".jpg,.png,.webp,.jpeg"
										onChange={async (e) => {
											let img = e.target.files[0];
											setOrgImg(img);
										}}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Public</FormLabel>
									<ChakraSelect
										name="public"
										placeholder="Select option"
										onChange={handleInfoChange}
										value={infoObj.public}
									>
										<option value="Yes">Yes</option>
										<option value="No">No</option>
									</ChakraSelect>
								</FormControl>
							</HStack>

							<FormControl>
								<FormLabel>People Multi Select</FormLabel>
								<Select
									isMulti
									placeholder="Search or Select multiple people"
									defaultValue={infoObj.defPeople}
									options={peopleList?.map((i) => {
										return {
											value: i.id,
											label: `${i.first_name} ${i.last_name}`,
										};
									})}
									onChange={(e) => {
										let peopleL = [];
										e.forEach((l) => {
											peopleL.push(l.value);
										});
										setInfoObj((prev) => {
											return { ...prev, people: peopleL };
										});
									}}
								/>
							</FormControl>

							<FormControl>
								<FormLabel>Research Tasks Multi Select</FormLabel>
								<Select
									isMulti
									placeholder="Search or Select multiple Research Task"
									defaultValue={infoObj.defResearch}
									options={researchTaskList?.map((i) => {
										return {
											value: i.id,
											label: i.task_name,
										};
									})}
									onChange={(e) => {
										let researchTaskL = [];
										e.forEach((l) => {
											researchTaskL.push(l.value);
										});
										setInfoObj((prev) => {
											return { ...prev, researchTasks: researchTaskL };
										});
									}}
								/>
							</FormControl>

							<FormControl style={{ zIndex: 0 }}>
								<FormLabel>Description</FormLabel>
								<RichTextEditor
									value={infoObj.description}
									onChange={(e) => {
										setInfoObj((prev) => {
											return { ...prev, description: e };
										});
									}}
									id="rte"
								/>
							</FormControl>
						</VStack>
					</VStack>
				)}
			</Box>
		</form>
	);
};

export default InfoSec;
