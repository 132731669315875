import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Divider,
	Heading,
	HStack,
	VStack,
	Text,
	Link,
	UnorderedList,
	ListItem,
	IconButton,
	Button,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import PublicationTypeForm from "components/Forms/PublicationType";
import { DELETE, updatePublicationType } from "utils/api";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate } from "react-router-dom";
import CustomAlertDialog from "components/CustomAlertDialog";

function PublicationType({ publicationTypeData }) {
	const [publicationType, setPublicationType] = useState(publicationTypeData);
	const [isEditing, setEditing] = useState(false);
	const { toastMsg } = useContext(AccountContext);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		setPublicationType(publicationTypeData);
	}, [publicationTypeData]);

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await DELETE(`/api/publications/categories/${publicationType.id}`).then(
				(data) => {
					setIsOpen(false);
					toastMsg({
						title: "Success!",
						desc: data.message,
						type: "success",
					});
					setIsLoading(false);
					navigate("/publication-types");
					return;
				}
			);
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<VStack gap={1} align="stretch" pb={20}>
			<Box
				padding="10px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Publication Type
						</Heading>
					</Badge>
					<IconButton
						onClick={() => {
							setEditing(!isEditing);
						}}
						size="xs"
						icon={<EditIcon />}
					/>
				</HStack>
				<Divider />
				{isEditing ? (
					<PublicationTypeForm
						category={publicationType.category}
						onCancel={() => {
							setEditing(false);
						}}
						onSubmit={async (body) =>
							updatePublicationType(publicationType.id, {
								newCategory: body.category,
							})
						}
						onSuccess={(res) => {
							setPublicationType(res.category);
							setEditing(false);
						}}
						submitBtnText="Update Publication Type"
					/>
				) : (
					<Box>
						<CustomAlertDialog
							title="Delete Publication Type"
							message="Are you sure?  Confirm to delete"
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							onConfirm={handleDelete}
							isLoading={isLoading}
							confirmText="Delete"
						/>
						<VStack align={"stretch"} px={5}>
							<VStack
								align={"stretch"}
								justifyContent="flex-start"
								pb={3}
								pt={3}
							>
								<Heading as="h2" size="xl">
									{publicationType.category}
								</Heading>
								<Heading as="h4" size="md">
									Publication Type ID: {publicationType.id}
								</Heading>
								{/* <br /> */}
							</VStack>
							<Divider pb={2} />
							<HStack justifyContent={"center"} pt={2}>
								<Button
									rightIcon={<DeleteIcon />}
									w={"10%"}
									onClick={() => {
										setIsOpen(true);
									}}
								>
									Delete
								</Button>
							</HStack>
						</VStack>
					</Box>
				)}
			</Box>
		</VStack>
	);
}

export default PublicationType;
