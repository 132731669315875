import React, { useContext, useEffect, useState } from "react";
import { getBaseUrl } from "base";
import Navigation from "components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate, useParams } from "react-router-dom";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	FormLabel,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { RichTextEditor } from "@mantine/rte";
import { EditIcon, CloseIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import Select from "react-select";

const ProgramProfile = () => {
	const { apiCall, toastMsg } = useContext(AccountContext);
	const { id } = useParams();
	const [programProfile, setProgramProfile] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const [infoObj, setInfoObj] = useState({});
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const [thrustList, setThrustList] = useState([]);
	const navigate = useNavigate();

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	//func to get single thrust profile
	const getProgramProfile = async (id) => {
		let url = `${getBaseUrl()}/api/researchPrograms/getResearchProgram/${id}`;
		const { data } = await apiCall(url);
		setProgramProfile(data);
		return;
	};

	//func to get list of thrust
	const getAllResearchThrust = async () => {
		let url = `${getBaseUrl()}/api/researchThrusts/getAllResearchThrust`;
		const { data } = await apiCall(url);
		return data;
	};

	useEffect(() => {
		getProgramProfile(id);
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		getAllResearchThrust().then((res) => {
			setThrustList(res);
		});
	}, []);

	useEffect(() => {
		let centerVal = programProfile.centers?.map((i) => {
			return { value: i, label: i.toUpperCase() };
		});
		let thrustVal = [];

		programProfile.researchThrusts?.forEach((e) => {
			let tObj = thrustList.find((i) => i.id === e.research_thrust_id);
			if (tObj !== undefined) {
				thrustVal.push({ value: tObj.id, label: tObj.thrust_name });
			}
		});

		setInfoObj({
			id: id,
			program_name: programProfile.program_name,
			description: programProfile.description,
			is_public: programProfile.is_public,
			research_thrusts: programProfile.researchThrusts?.map(
				(i) => i.research_thrust_id
			),
			defCT: centerVal,
			defRT: thrustVal,
		});
	}, [programProfile]);

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//func to update thrust
	const updateprogram = async () => {
		try {
			let url = `${getBaseUrl()}/api/researchPrograms/updateResearchProgram`;
			delete infoObj.defCT;
			delete infoObj.defRT;
			await apiCall(url, "POST", infoObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getProgramProfile(id);
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getProgramProfile(id);
			toggleInfoEdit();
			return;
		}
	};

	//func to delete project
	const deleteProgram = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/researchPrograms/deleteResearchProgram/${id}`;
			await apiCall(url, "POST").then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/research-programs");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			<VStack gap={1} align={"stretch"} pb={20}>
				<form>
					<AlertDialog
						isOpen={isDeleteOpen}
						onClose={() => onDeleteClose()}
						closeOnOverlayClick={false}
						motionPreset="slideInBottom"
					>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									Delete Research Program
								</AlertDialogHeader>
								<AlertDialogCloseButton />
								<AlertDialogBody>
									Are you sure?
									<br />
									Confirm to delete Research Program
								</AlertDialogBody>

								<AlertDialogFooter>
									<Button size={"sm"} onClick={() => onDeleteClose()}>
										Cancel
									</Button>
									<Button
										type="submit"
										size={"sm"}
										isLoading={addBtnStatus}
										loadingText="Deleting"
										onClick={() => {
											deleteProgram();
											onDeleteClose();
										}}
										ml={3}
									>
										Delete
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>

					<AlertDialog
						isOpen={isUpdateOpen}
						onClose={() => onUpdateClose()}
						closeOnOverlayClick={false}
						motionPreset="slideInBottom"
					>
						<AlertDialogOverlay>
							<AlertDialogContent>
								<AlertDialogHeader fontSize="lg" fontWeight="bold">
									Update Research Program
								</AlertDialogHeader>

								<AlertDialogBody>
									Are you sure?
									<br />
									Confirm to update.
								</AlertDialogBody>
								<AlertDialogCloseButton />
								<AlertDialogFooter>
									<Button size={"sm"} onClick={() => onUpdateClose()}>
										Cancel
									</Button>
									<Button
										type="submit"
										size={"sm"}
										onClick={() => {
											updateprogram();
											onUpdateClose();
										}}
										ml={3}
									>
										Update
									</Button>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialogOverlay>
					</AlertDialog>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
					>
						<HStack justifyContent={"space-between"}>
							<Badge>
								<Heading as="h6" size="xs">
									Research Program Information
								</Heading>
							</Badge>
							<HStack>
								{infoEdit === false ? (
									<IconButton
										onClick={toggleInfoEdit}
										size="xs"
										icon={<EditIcon />}
									/>
								) : (
									<>
										<IconButton
											onClick={toggleInfoEdit}
											size="xs"
											icon={<CloseIcon />}
										/>
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => {
												onUpdateOpen();
											}}
										/>
									</>
								)}
							</HStack>
						</HStack>
						<Divider />
						{infoEdit === false ? (
							<VStack align={"stretch"} px={5}>
								<VStack
									align={"stretch"}
									justifyContent="flex-start"
									pb={5}
									pt={3}
								>
									<Heading as="h2" size="xl">
										{programProfile.program_name}
									</Heading>
									<VStack
										align={"stretch"}
										justifyContent="flex-start"
										pb={5}
										// pt={5}
									>
										<Text>
											Public:{" "}
											<Badge colorScheme="green">
												{programProfile.is_public}
											</Badge>
										</Text>{" "}
										<UnorderedList pl={5}>
											<ListItem>
												Created on:{" "}
												{new Date(
													programProfile.created_at
												).toLocaleDateString()}
											</ListItem>
											<ListItem>
												Updated on:{" "}
												{new Date(
													programProfile.updated_at
												).toLocaleDateString()}
											</ListItem>
										</UnorderedList>
									</VStack>

									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Center</Badge>
										<UnorderedList pl={10}>
											{programProfile.centers?.map((c, idx) => (
												<ListItem key={idx}>
													<Text>{c.toUpperCase()}</Text>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>

									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Research Projects</Badge>
										<UnorderedList pl={10}>
											{programProfile.projects?.map((rp) => (
												<ListItem key={rp.id}>
													<Text>{rp.project_title}</Text>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>

									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Research Thrusts</Badge>
										<UnorderedList pl={10}>
											{programProfile.researchThrusts?.map((rt) => (
												<ListItem key={rt.research_thrust_id}>
													<Text>{rt.thrust_name}</Text>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>

									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>Description</Badge>
										<Text
											px={10}
											dangerouslySetInnerHTML={{
												__html: programProfile.description,
											}}
										/>
									</VStack>

									<Divider />
									<br />
									<HStack justifyContent={"center"}>
										<Button
											rightIcon={<DeleteIcon />}
											w={"10%"}
											onClick={() => {
												onDeleteOpen();
											}}
										>
											Delete
										</Button>
									</HStack>
								</VStack>
							</VStack>
						) : (
							<VStack py={2} gap={2} mt={5}>
								<VStack gap={3} w={"full"} px={5} pb={5}>
									<FormControl isRequired>
										<FormLabel>Research Program Name</FormLabel>
										<Input
											type="text"
											name="program_name"
											value={infoObj.program_name}
											onChange={handleInfoChange}
										/>
									</FormControl>
									<HStack gap={5} w={"full"}>
										<FormControl isRequired>
											<FormLabel>Public</FormLabel>
											<ChakraSelect
												name="is_public"
												placeholder="Select option"
												value={infoObj.is_public}
												onChange={handleInfoChange}
											>
												<option value="Yes">Yes</option>
												<option value="No">No</option>
											</ChakraSelect>
										</FormControl>
										<FormControl isRequired>
											<FormLabel>Center</FormLabel>
											<Select
												isMulti
												placeholder="Select Center (Multi Select)"
												defaultValue={infoObj.defCT}
												options={[
													{ value: "serc", label: "SERC" },
													{ value: "airc", label: "AIRC" },
												]}
												onChange={(e) => {
													let cList = [];
													e.forEach((c) => {
														cList.push(c.value);
													});
													setInfoObj((prev) => {
														return {
															...prev,
															centers: cList,
														};
													});
												}}
											/>
										</FormControl>
									</HStack>

									<FormControl>
										<FormLabel>Research Programs (Multi Select)</FormLabel>
										<Select
											isMulti
											placeholder="Search or Select multiple Research Programs"
											defaultValue={infoObj.defRT}
											options={thrustList?.map((i) => {
												return {
													value: i.id,
													label: i.thrust_name,
												};
											})}
											onChange={(e) => {
												let rtL = [];
												e.forEach((l) => {
													rtL.push(l.value);
												});
												setInfoObj((prev) => {
													return { ...prev, research_thrusts: rtL };
												});
											}}
										/>
									</FormControl>

									<FormControl style={{ zIndex: 0 }} isRequired>
										<FormLabel>Description</FormLabel>
										<RichTextEditor
											value={infoObj.description}
											onChange={(e) => {
												setInfoObj((prev) => {
													return { ...prev, description: e };
												});
											}}
											id="rte1"
										/>
									</FormControl>
								</VStack>
							</VStack>
						)}
					</Box>
				</form>
			</VStack>
		</Navigation>
	);
};

export default ProgramProfile;
