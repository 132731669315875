import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Box,
	HStack,
	Badge,
	Heading,
	Button,
	Divider,
	VStack,
	FormControl,
	FormLabel,
	Input,
	TableContainer,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	Checkbox,
	Thead,
	IconButton,
} from "@chakra-ui/react";
import Select from "react-select";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import { CloseIcon } from "@chakra-ui/icons";

const CreateGroup = ({
	toggleAddGroup,
	userList,
	permissionList,
	getGroupList,
}) => {
	const { apiCall, toastMsg } = useContext(AccountContext);
	const [groupName, setGroupName] = useState("");
	const [uList, setUList] = useState([]);
	const [pList, setPList] = useState([]);
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const [selectedPermissions, setSelectedPermissions] = useState([]);

	//func to create group
	const createGroup = async (gObj) => {
		let url = `${getBaseUrl()}/api/accessRoles`;
		const { data } = await apiCall(url, "POST", gObj);
		return data;
	};

	const handleuserList = (ulist) => {
		let userL = [];
		ulist.forEach((u) => {
			userL.push(u.value);
		});
		setUList(userL);
	};

	const handleCheckboxChange = (type, category) => {
		const newSelectedPermissions = [...selectedPermissions];
		const categoryIndex = newSelectedPermissions.findIndex(
			(p) => p.category === category
		);

		if (categoryIndex === -1) {
			newSelectedPermissions.push({ category, types: [type] });
		} else {
			const newTypes = [...newSelectedPermissions[categoryIndex].types];
			const typeIndex = newTypes.indexOf(type);
			if (typeIndex === -1) {
				newTypes.push(type);
			} else {
				newTypes.splice(typeIndex, 1);
			}
			if (newTypes.length === 0) {
				newSelectedPermissions.splice(categoryIndex, 1);
			} else {
				newSelectedPermissions[categoryIndex] = { category, types: newTypes };
			}
		}

		setSelectedPermissions(newSelectedPermissions);
	};

	const handleCreateGroup = async (e) => {
		try {
			setAddBtnStatus(true);
			e.preventDefault();
			let groupObj = {
				name: groupName,
				users: uList,
				permissions: selectedPermissions,
			};
			await createGroup(groupObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				getGroupList();
				toggleAddGroup();
				setAddBtnStatus(false);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Add Groups
					</Heading>
				</Badge>
				<IconButton
					size="xs"
					icon={<CloseIcon />}
					onClick={() => {
						setGroupName("");
						setPList([]);
						setUList([]);
						toggleAddGroup();
					}}
				/>
			</HStack>
			<Divider />
			<br />
			<form action="submit" onSubmit={handleCreateGroup}>
				<VStack gap={3} px={3}>
					<FormControl isRequired>
						<FormLabel>Group Name</FormLabel>
						<Input
							name="name"
							type="text"
							size={"sm"}
							placeholder="Group Name"
							onChange={(e) => {
								setGroupName(e.target.value);
							}}
						/>
					</FormControl>
					<FormControl isRequired>
						<FormLabel>Assign Users to the New Role</FormLabel>
						<Select
							isMulti
							placeholder="Search or Select multiple users"
							options={userList?.map((i) => {
								return {
									value: i.id,
									label: i.name,
								};
							})}
							onChange={(e) => {
								handleuserList(e);
							}}
						/>
					</FormControl>
					<FormControl>
						<FormLabel>Assign Permissions</FormLabel>
						<TableContainer
							width={"100%"}
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
						>
							<Table variant="simple">
								<Thead>
									<Tr>
										<Th></Th>
										<Th>READ</Th>
										<Th>CREATE</Th>
										<Th>UPDATE</Th>
										<Th>DELETE</Th>
									</Tr>
								</Thead>
								<Tbody>
									{permissionList?.map((p, idx) => (
										<Tr key={idx}>
											<Th>{p.category}</Th>
											{p.types?.map((t, idxx) => (
												<Td key={idxx}>
													<Checkbox
														value={t}
														isChecked={selectedPermissions.some(
															(pp) =>
																pp.category === p.category &&
																pp.types.includes(t)
														)}
														onChange={() => handleCheckboxChange(t, p.category)}
													/>
												</Td>
											))}
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</FormControl>

					<HStack gap={3} justifyContent={"center"} pt={5} pb={3}>
						<Button
							size="xs"
							onClick={() => {
								setGroupName("");
								setPList([]);
								setUList([]);
								toggleAddGroup();
							}}
						>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={addBtnStatus}
							loadingText="Creating Group"
						>
							Add Group
						</Button>
					</HStack>
				</VStack>
			</form>
		</Box>
	);
};

export default CreateGroup;
