import React, { useContext, useEffect, useState } from "react";
import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Icon,
} from "@chakra-ui/react";
import {
	BsFillPersonFill,
	BsFillPeopleFill,
	BsFillShieldLockFill,
} from "react-icons/bs";
import Navigation from "components/Layout/Navigation";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import Users from "./UsersTab/Users";
import Groups from "./GroupsTab/Groups";
import Permissions from "./PermissionsTab/Permissions";
import CreateGroup from "./GroupsTab/CreateGroup";
import AccessDenied from "pages/AccessDenied";
import CreateUser from "./UsersTab/CreateUser";

const UsersGroups = () => {
	const [userList, setUserList] = useState([]);
	const [groupList, setGroupList] = useState([]);
	const [permissionList, setPermissionList] = useState([]);
	const { apiCall, session } = useContext(AccountContext);

	const [addGroup, setAddGroup] = useState(false);
	const [addUser, setAddUser] = useState(false);

	//func to get all user list
	const getUserList = async () => {
		let url = `${getBaseUrl()}/api/users/getAllUsers`;
		const { data } = await apiCall(url);
		const options = {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: true,
		};
		data.forEach((dt) => {
			dt.last_login =
				dt.last_login === null
					? "NA"
					: new Date(dt.last_login).toLocaleTimeString("en-us", options);
		});
		setUserList(data);
		return;
	};

	//func to get all group list
	const getGroupList = async () => {
		let url = `${getBaseUrl()}/api/accessRoles`;
		const { data } = await apiCall(url);
		setGroupList(data);
		return;
	};

	//func to get all permission list
	const getPermissionList = async () => {
		let url = `${getBaseUrl()}/api/permissions`;
		const { data } = await apiCall(url);
		setPermissionList(data);
		return;
	};

	useEffect(() => {
		getUserList();
		getGroupList();
		getPermissionList();
	}, []);

	const toggleAddGroup = () => {
		setAddGroup(!addGroup);
	};
	const toggleAddUser = () => {
		setAddUser(!addUser);
	};

	return (
		<Navigation>
			{session && session.isAdmin === true ? (
				<>
					<Tabs
						variant="soft-rounded"
						variantcolor="brand.100"
						orientation="vertical"
						size={"sm"}
					>
						<TabList gap={5} maxWidth={"200px"} minWidth={"200px"}>
							<Tab justifyContent={"left"}>
								<Icon mr="4" ml="2" as={BsFillPersonFill} /> Users
							</Tab>
							<Tab justifyContent={"left"}>
								<Icon mr="4" ml="2" as={BsFillPeopleFill} /> Groups
							</Tab>
							<Tab justifyContent={"left"}>
								<Icon mr="4" ml="2" as={BsFillShieldLockFill} /> Permissions
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel py={0} pr={0}>
								{addUser === false ? (
									<Users userList={userList} toggleAddUser={toggleAddUser} />
								) : (
									<CreateUser
										toggleAddUser={toggleAddUser}
										getUserList={getUserList}
									/>
								)}
							</TabPanel>
							<TabPanel py={0} pr={0}>
								{addGroup === false ? (
									<Groups
										groupList={groupList}
										toggleAddGroup={toggleAddGroup}
									/>
								) : (
									<CreateGroup
										toggleAddGroup={toggleAddGroup}
										userList={userList}
										permissionList={permissionList}
										getGroupList={getGroupList}
									/>
								)}
							</TabPanel>
							<TabPanel py={0} pr={0}>
								<Permissions permissionList={permissionList} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</>
			) : (
				<AccessDenied />
			)}
		</Navigation>
	);
};

export default UsersGroups;
