import React from "react";
import {
	Heading,
	HStack,
	VStack,
	Badge,
	Divider,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
} from "@chakra-ui/react";

const JobSec = ({ peopleProfile }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Job Title
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
				<TableContainer justifyContent="center" px={5}>
					<Table variant="simple" size={"sm"}>
						<Thead>
							<Tr>
								<Th>Title</Th>
								<Th>Organization</Th>
								<Th>Start Date</Th>
								<Th>End Date</Th>
								<Th>Current Position</Th>
								<Th>Public</Th>
							</Tr>
						</Thead>
						<Tbody size={"xs"}>
							{peopleProfile.jobTitles?.map((job) => (
								<Tr key={job.id}>
									<Td>{job.job_title}</Td>
									<Td>{job.organization_name}</Td>
									<Td>{job.start_date}</Td>
									<Td>{job.end_date}</Td>
									<Td>{job.current}</Td>
									<Td>{job.visibility}</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</VStack>
		</>
	);
};

export default JobSec;
