import { DeleteIcon, EditIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Divider,
	Heading,
	HStack,
	VStack,
	Text,
	Link,
	UnorderedList,
	ListItem,
	IconButton,
	Button,
} from "@chakra-ui/react";
import FileNameInput from "components/FileNameInput";
import CustomAlertDialog from "components/CustomAlertDialog";
import FileUploadBtn from "components/FileUploadBtn";
import PublicationForm from "components/Forms/Publication";
import PageSection from "components/PageSection";
import { AccountContext } from "contexts/AccountContext";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import {
	DELETE,
	updatePublication,
	updatePublicationDocument,
	updatePublicationImg,
} from "utils/api";

function Publication({ publicationData }) {
	const [publication, setPublication] = useState(publicationData);
	const [isEditing, setEditing] = useState(false);
	const { toastMsg } = useContext(AccountContext);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setPublication(publicationData);
	}, [publicationData]);
	const publicationValues = useMemo(() => {
		const values = { ...publication };
		Object.entries(values).forEach(([key, val]) => {
			if (Array.isArray(val) && val.length > 0 && typeof val[0].id === "number")
				values[key] = val.map((v) => v.id);
		});
		return values;
	}, [publication]);

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await DELETE(`/api/publications/${publication.id}`).then((data) => {
				setIsOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setIsLoading(false);
				navigate("/publications");
				return;
			});
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<VStack gap={1} align="stretch" pb={20}>
			<Box
				padding="10px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Publication
						</Heading>
					</Badge>
					<IconButton
						onClick={() => {
							setEditing(!isEditing);
						}}
						size="xs"
						icon={<EditIcon />}
					/>
				</HStack>
				<Divider />
				{isEditing ? (
					<PublicationForm
						{...publicationValues}
						isPublic={publicationValues.public}
						onCancel={() => {
							setEditing(false);
						}}
						onSubmit={async (body) => updatePublication(publication.id, body)}
						onSuccess={(res) => {
							setPublication(res.publication);
							setEditing(false);
						}}
						submitBtnText="Update Publication"
					/>
				) : (
					<Box>
						<CustomAlertDialog
							title="Delete Publication"
							message="Are you sure?  Confirm to delete"
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							onConfirm={handleDelete}
							isLoading={isLoading}
							confirmText="Delete"
						/>
						<HStack
							justifyContent="space-between"
							alignItems="flex-start"
							mt={2}
							gap={1}
						>
							<VStack gap={1} alignItems="flex-start">
								<Heading px={5} as="h1" size="xl">
									{publication.title}
								</Heading>
								<HStack px={5}>
									<Text as="b">Contract Number:</Text>
									<Text>{publication.contractNumber}</Text>
								</HStack>
								<HStack px={5}>
									<Text as="b">Publication Date:</Text>
									<Text>{publication.publicationDate}</Text>
								</HStack>
								{publication.location && (
									<HStack px={5}>
										<MdLocationOn />
										<Text>{publication.location}</Text>
									</HStack>
								)}
								<Text as="b" px={5}>
									Public:{" "}
									<Badge
										colorScheme={publication.public === "yes" ? "green" : "red"}
									>
										{publication.public}
									</Badge>
								</Text>
							</VStack>
							<VStack
								gap={1}
								pr={5}
								sx={{
									maxW: 300,
								}}
							>
								{publication.imageS3 && (
									<>
										<img alt={publication.title} src={publication.imageS3} />
										<FileNameInput
											fileName={publication.imageFilename}
											endpoint={`/api/publications/renamePublicationImg/${publication.id}`}
											onSuccess={(res) => {
												setPublication(res.publication);
											}}
										/>
									</>
								)}
								<FileUploadBtn
									btnLabel="Update Image"
									accept="image/*"
									onSubmit={async (img) =>
										updatePublicationImg({ id: publication.id, img })
									}
									onSuccess={(imageS3, imageFilename) => {
										setPublication({ ...publication, imageS3, imageFilename });
									}}
								/>
							</VStack>
						</HStack>
						<VStack px={5} gap={1} alignItems="flex-start" my={4} width="50%">
							{publication.fileS3 && (
								<FileNameInput
									fileName={publication.publicationFilename}
									endpoint={`/api/publications/renamePublicationFile/${publication.id}`}
									onSuccess={(res) => {
										setPublication(res.publication);
									}}
									isHyperLink
									externalLink={publication.fileS3}
								/>
							)}
							<FileUploadBtn
								btnLabel="Update Document"
								accept="application/pdf"
								onSubmit={async (documentFile) =>
									updatePublicationDocument({
										id: publication.id,
										documentFile,
									})
								}
								onSuccess={(fileS3, publicationFilename) => {
									setPublication({
										...publication,
										fileS3,
										publicationFilename,
									});
								}}
							/>
						</VStack>
						<PageSection title="Category">{publication.category}</PageSection>
						<PageSection title="Center">
							<UnorderedList>
								{publication.centers.map((c, idx) => (
									<ListItem key={idx}>{c.toUpperCase()}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Abstract">
							<Text
								dangerouslySetInnerHTML={{
									__html: publication.abstract,
								}}
							/>
						</PageSection>
						{/* <PageSection title="Research Thrusts">
							<UnorderedList>
								{publication.researchThrusts?.map((rt) => (
									<ListItem key={rt.id}>{rt.thrustName}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Research Programs">
							<UnorderedList>
								{publication.researchPrograms?.map((rp) => (
									<ListItem key={rp.researchProgramId}>
										{rp.programName}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Research Tasks">
							<UnorderedList>
								{publication.researchTasks?.map((rt) => (
									<ListItem key={rt.id}>{rt.taskName}</ListItem>
								))}
							</UnorderedList>
						</PageSection> */}
						<PageSection title="Organizations">
							<UnorderedList>
								{publication.organizations?.map((o) => (
									<ListItem key={o.id}>{o.organizationName}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Projects">
							<UnorderedList>
								{publication.projects?.map((p) => (
									<ListItem key={p.id}>{p.projectTitle}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Authors">
							<UnorderedList>
								{publication.primaryAuthors?.map((a) => (
									<ListItem key={a.id}>
										{a.firstName} {a.lastName}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Secondary Authors">
							<UnorderedList>
								{publication.secondaryAuthors?.map((sa) => (
									<ListItem key={sa.id}>
										{sa.firstName} {sa.lastName}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<Divider pb={2} />
						<HStack justifyContent={"center"} pt={2}>
							<Button
								rightIcon={<DeleteIcon />}
								w={"10%"}
								onClick={() => {
									setIsOpen(true);
								}}
							>
								Delete
							</Button>
						</HStack>
					</Box>
				)}
			</Box>
		</VStack>
	);
}

export default Publication;
