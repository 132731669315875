import React from "react";
import { Heading, HStack, VStack, Badge, Box, Divider } from "@chakra-ui/react";
import PublicationsDatatable from "components/Datatable/PublicationsDatatable";

const Publications = ({ peopleProfile }) => {
	return (
		<VStack gap={2} align={"stretch"}>
			<Box
				padding={"15px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent={"space-between"}>
					<Badge>
						<Heading as="h6" size="xs">
							Publications as Author
						</Heading>
					</Badge>
				</HStack>
				<Divider />
				<br />
				<PublicationsDatatable
					data={peopleProfile.publicationsAuthor}
					variant="author"
				/>
			</Box>
			<Box
				padding={"15px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent={"space-between"}>
					<Badge>
						<Heading as="h6" size="xs">
							Publications as Co-Author
						</Heading>
					</Badge>
				</HStack>
				<Divider />
				<br />
				<PublicationsDatatable
					data={peopleProfile.publicationCoAuthor}
					variant="author"
				/>
			</Box>
		</VStack>
	);
};

export default Publications;
