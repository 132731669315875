import { Button, HStack, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import { salutationList } from "pages/People/CreatePeople/CreatePeople";
import React from "react";
import { quickAddPerson } from "utils/api";

function AddPerson({ onCancel, onSuccess = () => {}, onError = () => {} }) {
	return (
		<Formik
			initialValues={{
				prefix: null,
				first_name: null,
				last_name: null,
				email: null,
			}}
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const res = await quickAddPerson(values);
					await onSuccess(res.person);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<VStack gap={3} alignItems="stretch">
						<HStack gap={3}>
							<Field
								name="prefix"
								component={MultiSelect}
								label="Prefix"
								placeholder="Select Prefix"
								options={(salutationList || []).map((s) => ({
									value: s,
									label: s,
								}))}
							/>
							<Field
								name="first_name"
								component={TextInput}
								label="First Name"
								placeholder="First Name"
								required
							/>
						</HStack>
						<HStack>
							<Field
								name="last_name"
								component={TextInput}
								label="Last Name"
								placeholder="Last Name"
								required
							/>
							<Field
								name="email"
								component={TextInput}
								type="email"
								label="E-Mail"
								placeholder="E-Mail"
								required
							/>
						</HStack>
						<HStack gap={3} justifyContent={"flex-end"}>
							<Button onClick={onCancel} size="xs">
								Cancel
							</Button>
							<Button
								colorScheme="teal"
								type="submit"
								isLoading={isSubmitting}
								size="xs"
							>
								Add Person
							</Button>
						</HStack>
					</VStack>
				</Form>
			)}
		</Formik>
	);
}

export default AddPerson;
