import React, { useContext, useState } from "react";
import {
	Flex,
	Box,
	FormControl,
	Input,
	Stack,
	Link,
	Button,
	Heading,
	useColorModeValue,
	Text,
	Image,
} from "@chakra-ui/react";
import mainLogo from "assets/serc-logo.png";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
	const { apiCall, toastMsg } = useContext(AccountContext);
	const [username, setUsername] = useState("");
	const [passRestStatus, setPassResetStatus] = useState(false);
	const navigate = useNavigate();

	//func to reset password
	const resetPassword = async (e) => {
		try {
			e.preventDefault();
			setPassResetStatus(true);
			let url = `${getBaseUrl()}/api/forgotPassword`;
			let passObj = {
				username: username,
			};
			await apiCall(url, "POST", passObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/");
				}, 1000);
				setPassResetStatus(false);
				return;
			});
		} catch (error) {
			setPassResetStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Flex minH={"100vh"} align={"center"} justify={"center"}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image src={mainLogo} alt="SERC" />
				</Stack>
				<Box
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"2xl"}
					p={8}
					borderWidth={1}
					borderRadius={8}
					rounded="2xl"
				>
					<form action="submit" onSubmit={resetPassword}>
						<Stack spacing={4}>
							<Heading
								fontSize={"3xl"}
								textAlign={"center"}
								color={"brand.700"}
								pb={5}
							>
								Forgot your password?
							</Heading>
							<Text
								px={2}
								pb={2}
								fontSize={{ base: "sm", sm: "sm" }}
								color={useColorModeValue("gray.800", "gray.400")}
							>
								Enter the email ID associated with your account and we'll send
								you a newly generated password.
							</Text>
							<FormControl id="email">
								<Input
									placeholder="your-email@example.com"
									_placeholder={{ color: "gray.500" }}
									type="email"
									name="email"
									onChange={(e) => setUsername(e.target.value)}
								/>
							</FormControl>
							<Stack spacing={10}>
								<Button
									type="submit"
									isLoading={passRestStatus}
									loadingText="Resetting password"
								>
									Request Reset
								</Button>
							</Stack>
							<Stack pt={6}>
								<Text align={"center"}>
									Back to{" "}
									<Link href="/" color={"blue.400"}>
										Login?
									</Link>
								</Text>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
};

export default ForgotPassword;
