import React from "react";
import { Heading, HStack, Badge, Box, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const PublicationTab = ({ orgProfile }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Publications
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<br />
			<Datatable
				data={orgProfile.publications}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Title",
						width: "50%",
						data: "title",
					},
					{
						className: "dt-body-left",
						title: "Category",
						data: "category",
					},
					{
						className: "dt-body-left",
						title: "Author",
						data: "author",
						render: (dt) => {
							return dt
								?.map((a) => `${a.first_name} ${a.last_name}`)
								.join(", ");
						},
					},
					{
						className: "dt-body-left",
						title: "Published Date",
						data: "publication_date",
						render: (dt) => getFormattedDateStr(new Date(dt)),
					},
				]}
				theaders={["ID", "Title", "Category", "Author", "Published Date"]}
				navigatePath="/publications"
				tableId="publicationsTab"
			/>
		</Box>
	);
};

export default PublicationTab;
