import React from "react";
import { Box, HStack, Badge, Heading, Button, Divider } from "@chakra-ui/react";
import Datatable from "components/Datatable";

const Users = ({ userList, toggleAddUser }) => {
	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Users
					</Heading>
				</Badge>
				<Button size="xs" onClick={() => toggleAddUser()}>
					Add User
				</Button>
			</HStack>
			<Divider mb={2} />
			<Datatable
				data={userList}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						width: "25%",
						title: "Name",
						data: "name",
					},
					{
						className: "dt-body-left",
						width: "25%",
						title: "Email ID",
						data: "email",
					},
					{
						className: "dt-body-left",
						title: "Admin",
						data: "is_admin",
						render: (data) =>
							data === 1
								? '<p style="color: green;">Yes</p>'
								: '<p style="color: red;">No</p>',
					},
					{
						className: "dt-body-left",
						title: "Status",
						data: "active",
						render: (data) =>
							data === "yes"
								? '<p style="color: green;">Active</p>'
								: '<p style="color: red;">Inactive</p>',
					},
					{
						className: "dt-body-left",
						title: "Account",
						data: "enabled",
						render: (data) =>
							data === 1
								? '<p style="color: green;">Enabled</p>'
								: '<p style="color: red;">Disabled</p>',
					},
					{
						className: "dt-body-left",
						width: "20%",
						title: "Last Login",
						data: "last_login",
					},
				]}
				theaders={[
					"ID",
					"Name",
					"Email ID",
					"Admin",
					"Status",
					"Account",
					"Last Login",
				]}
				navigatePath="/users"
				tableId="usersTab"
			/>
		</Box>
	);
};

export default Users;
