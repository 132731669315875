import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import theme from "./theme";
import AccountContextProvider from "contexts/AccountContext";
import ProtectedRoute from "components/ProtectedRoute";

import Login from "./components/Forms/Login";
import ForgotPassword from "./components/Forms/ForgotPassword";

import Homepage from "./pages/HomePage";
import Profile from "./pages/Profile/Profile";

import People from "pages/People";
import CreatePeople from "./pages/People/CreatePeople/CreatePeople";
import PeopleProfile from "./pages/People/PeopleProfile/PeopleProfile";

import Roles from "pages/Roles";
import CreateRoles from "./pages/Roles/CreateRoles/CreateRoles";
import RoleProfile from "pages/Roles/RoleProfile/RoleProfile";

import ResearchTaskRoles from "pages/ResearchTaskRoles";
import SingleResearchTaskRole from "pages/ResearchTaskRoles/SingleResearchTaskRole";
import CreateResearchTaskRoles from "pages/ResearchTaskRoles/CreateResearchTaskRoles";

import Organizations from "pages/Organizations";
import CreateOrgs from "./pages/Organizations/CreateOrgs/CreateOrgs";
import OrgsProfile from "./pages/Organizations/OrgsProfile/OrgsProfile";

import OrgTypes from "pages/OrgTypes";
import CreateOrgTypes from "./pages/OrgTypes/CreateOrgTypes/CreateOrgTypes";
import OrgTypesProfile from "./pages/OrgTypes/OrgTypesProfile/OrgTypesProfile";

import Projects from "./pages/Projects";
import CreateProjects from "./pages/Projects/CreateProjects/CreateProjects";
import ProjectProfile from "./pages/Projects/ProjectProfile/ProjectProfile";

import Specialties from "./pages/Specialties";

import Publications from "pages/Publications";
import SinglePublication from "pages/Publications/SinglePublication";
import CreatePublication from "pages/Publications/CreatePublication";

import PublicationTypes from "pages/PublicationTypes";
import CreatePublicationType from "pages/PublicationTypes/CreatePublicationType";
import SinglePublicationType from "pages/PublicationTypes/SinglePublicationType";

import ResearchTasks from "pages/ResearchTasks";
import SingleResearch from "pages/ResearchTasks/SingleResearch";
import CreateResearchTask from "pages/ResearchTasks/CreateResearchTask";
import ActiveResearchTasks from "pages/ResearchTasks/ActiveResearchTasks";
import ActiveResearchers from "pages/ResearchTasks/ActiveResearchers";

import ResearchThrusts from "pages/ResearchThrusts";
import CreateThrusts from "pages/ResearchThrusts/CreateThrusts/CreateThrusts";
import ThrustProfile from "pages/ResearchThrusts/ThrustProfile/ThrustProfile";

import ResearchPrograms from "pages/ResearchPrograms";
import CreatePrograms from "pages/ResearchPrograms/CreatePrograms/CreatePrograms";
import ProgramProfile from "pages/ResearchPrograms/ProgramProfile/ProgramProfile";

import TechnicalReports from "pages/TechnicalReports";
import SingleTechnicalReport from "pages/TechnicalReports/SingleTechnicalReport";
import CreateTechnicalReports from "pages/TechnicalReports/CreateTechnicalReports";

import Finance from "pages/Finance";
import SingleFinance from "pages/Finance/SingleFinance";
import CreateFinance from "pages/Finance/CreateFinance";

import UsersGroups from "pages/Users&Groups/Users&Groups";
import ViewGroup from "pages/Users&Groups/GroupsTab/ViewGroup";
import ViewUser from "pages/Users&Groups/UsersTab/ViewUser";

import PageNotFound from "pages/PageNotFound";
import Reports from "pages/Reports";

function App() {
	return (
		<ChakraProvider theme={theme}>
			<div className="App">
				<Router>
					<AccountContextProvider>
						<Routes>
							<Route exact path="/" element={<Login />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />

							{/* protected routes */}
							<Route element={<ProtectedRoute />}>
								{/* homepage */}
								<Route path="/homepage" element={<Homepage />} />

								{/* Profile Route */}
								<Route path="/profile" element={<Profile />} />

								{/* People Routes */}
								<Route path="/people">
									<Route index element={<People />} />
									<Route path=":id" element={<PeopleProfile />} />
									<Route path="create" element={<CreatePeople />} />
								</Route>

								{/* SERC/AIRC Roles Routes */}
								<Route path="/roles">
									<Route index element={<Roles />} />
									<Route path=":id" element={<RoleProfile />} />
									<Route path="create" element={<CreateRoles />} />
								</Route>

								{/* Research Task Roles Routes */}
								<Route path="/rt-roles">
									<Route index element={<ResearchTaskRoles />} />
									<Route path=":id" element={<SingleResearchTaskRole />} />
									<Route path="create" element={<CreateResearchTaskRoles />} />
								</Route>

								{/* Organizations Routes */}
								<Route path="/organizations">
									<Route index element={<Organizations />} />
									<Route path=":id" element={<OrgsProfile />} />
									<Route path="create" element={<CreateOrgs />} />
								</Route>

								{/* Organizations Type Routes */}
								<Route path="/organization-types">
									<Route index element={<OrgTypes />} />
									<Route path=":id" element={<OrgTypesProfile />} />
									<Route path="create" element={<CreateOrgTypes />} />
								</Route>

								{/* Projects Routes */}
								<Route path="/projects">
									<Route index element={<Projects />} />
									<Route path=":id" element={<ProjectProfile />} />
									<Route path="create" element={<CreateProjects />} />
								</Route>

								{/* Specialties Routes */}
								<Route path="/specialties">
									<Route index element={<Specialties />} />
									{/* <Route path=":id" element={< />} />
									<Route path="create" element={< />} /> */}
								</Route>

								{/* Publications Routes */}
								<Route path="/publications">
									<Route index element={<Publications />} />
									<Route path=":id" element={<SinglePublication />} />
									<Route path="create" element={<CreatePublication />} />
								</Route>

								{/* Publications Routes */}
								<Route path="/publication-types">
									<Route index element={<PublicationTypes />} />
									<Route path=":id" element={<SinglePublicationType />} />
									<Route path="create" element={<CreatePublicationType />} />
								</Route>

								{/* Research Tasks Routes */}
								<Route path="/research-tasks">
									<Route index element={<ResearchTasks />} />
									<Route path=":id" element={<SingleResearch />} />
									<Route path="create" element={<CreateResearchTask />} />
									<Route path="active-rts" element={<ActiveResearchTasks />} />
									<Route
										path="active-researchers"
										element={<ActiveResearchers />}
									/>
								</Route>

								{/* Research Thrusts Routes */}
								<Route path="/research-thrusts">
									<Route index element={<ResearchThrusts />} />
									<Route path=":id" element={<ThrustProfile />} />
									<Route path="create" element={<CreateThrusts />} />
								</Route>

								{/* Research Programs Routes */}
								<Route path="/research-programs">
									<Route index element={<ResearchPrograms />} />
									<Route path=":id" element={<ProgramProfile />} />
									<Route path="create" element={<CreatePrograms />} />
								</Route>

								{/* Technical Reports Routes */}
								<Route path="/technical-reports">
									<Route index element={<TechnicalReports />} />
									<Route path=":id" element={<SingleTechnicalReport />} />
									<Route path="create" element={<CreateTechnicalReports />} />
								</Route>

								{/* Finance Routes */}
								<Route path="/finance">
									<Route index element={<Finance />} />
									<Route path=":id" element={<SingleFinance />} />
									<Route path="create" element={<CreateFinance />} />
								</Route>

								{/* Reports Routes */}
								<Route path="/reports">
									<Route index element={<Reports />} />
								</Route>

								{/* Users & Groups Route */}
								<Route
									path="/user-group-management"
									element={<UsersGroups />}
								/>
								<Route path="/groups/:id" element={<ViewGroup />} />
								<Route path="/users/:id" element={<ViewUser />} />
							</Route>

							{/* undefined routes */}
							<Route path="*" element={<PageNotFound />} />
						</Routes>
					</AccountContextProvider>
				</Router>
			</div>
		</ChakraProvider>
	);
}

export default App;
