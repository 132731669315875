import React, { useCallback } from "react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const ResearchThrusts = () => {
	const { data, loading, error } = useFetch(
		"/api/researchThrusts/getAllResearchThrust"
	);
	const navigate = useNavigate();

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Research Thrusts data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Thrust Name",
						data: "thrust_name",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={["ID", "Thrust Name", "Updated On"]}
				navigatePath="/research-thrusts"
				tableId="viewRThurst"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Research Thrusts"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Research Thrusts
					</Highlight>
				</Heading>
				<Button size="xs" onClick={() => navigate("/research-thrusts/create")}>
					Create
				</Button>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default ResearchThrusts;
