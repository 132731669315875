import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { CSVLink } from "react-csv";
import { getFormattedDateStr } from "utils/helpers";

function ActiveResearchTasks() {
	const { data, loading, error } = useFetch("/api/researchTask/allActiveRts");

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting research tasks data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
			if (Array.isArray(dt.researchThrusts))
				dt.researchThrusts = dt.researchThrusts
					.map((th) => th.research_thrust)
					.join(", ");
			if (Array.isArray(dt.centers)) {
				dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
			}
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Task Number",
						data: "task_number",
					},
					{
						className: "dt-body-left",
						title: "Task Name",
						data: "task_name",
						width: "30%",
					},
					{
						className: "dt-body-left",
						title: "Research Thurst",
						data: "researchThrusts",
						width: "30%",
					},
					{
						className: "dt-body-left",
						title: "Centers",
						data: "centers",
					},
					{
						className: "dt-body-left",
						title: "Period Start",
						data: "period_start",
					},
					{
						className: "dt-body-left",
						title: "Period End",
						data: "period_end",
					},
					{
						className: "dt-body-left",
						title: "Public",
						data: "public",
						width: "3%",
					},
					{
						className: "dt-body-left",
						title: "Updated At",
						data: "updated_at",
					},
				]}
				theaders={[
					"ID",
					"Task Number",
					"Task Name",
					"Research Thurst",
					"Centers",
					"Period Start",
					"Period End",
					"Public",
					"Updated At",
				]}
				navigatePath="/research-tasks"
				tableId="viewActiveResearch"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Active Research Tasks"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Active Research Tasks
					</Highlight>
				</Heading>

				<CSVLink
					data={data}
					headers={[
						{ label: "ID", key: "id" },
						{ label: "Task Number", key: "task_number" },
						{ label: "Task Name", key: "task_name" },
						{ label: "Research Thurst", key: "researchThrusts" },
						{ label: "Centers", key: "centers" },
						{ label: "Period Start", key: "period_start" },
						{ label: "Period End", key: "period_end" },
						{ label: "Public", key: "public" },
						{ label: "Updated On", key: "updated_at" },
					]}
					filename="SERC_Active_Research_Tasks_Data"
					target="_blank"
				>
					<Button size="xs">Export</Button>
				</CSVLink>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
}

export default ActiveResearchTasks;
