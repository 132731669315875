import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from "@chakra-ui/react";

const Alert = ({
	isOpen,
	onClose,
	onSuccessClick,
	header,
	body,
	successBtnText,
}) => (
	<AlertDialog
		isOpen={isOpen}
		onClose={onClose}
		closeOnOverlayClick={false}
		motionPreset="slideInBottom"
	>
		<AlertDialogOverlay>
			<AlertDialogContent>
				<AlertDialogHeader fontSize="lg" fontWeight="bold">
					{header}
				</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>{body}</AlertDialogBody>
				<AlertDialogFooter>
					<Button size={"sm"} onClick={onClose}>
						Cancel
					</Button>
					<Button
						type="submit"
						size={"sm"}
						onClick={() => {
							onSuccessClick();
							onClose();
						}}
						ml={3}
					>
						{successBtnText}
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialogOverlay>
	</AlertDialog>
);

export default Alert;
