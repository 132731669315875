import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Button,
	Heading,
	useColorModeValue,
	InputGroup,
	InputRightElement,
	Image,
} from "@chakra-ui/react";
import mainLogo from "assets/serc-logo.png";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { AccountContext } from "contexts/AccountContext";
import { getBaseUrl } from "base";
import axios from "axios";

const Login = () => {
	const { toastMsg, getUserPermissions, setSession } =
		useContext(AccountContext);
	let navigate = useNavigate();

	useEffect(() => {
		getUserPermissions().then((res) => {
			setSession(res);
			if (res !== null && res !== undefined) {
				navigate("/homepage");
			}
		});
	}, []);

	const [loginObj, setLoginObj] = useState({
		email: "",
		password: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [loginBtn, setLoginBtn] = useState(false);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setLoginObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//func to login user
	const loginUser = async (username, password) => {
		const basicAuth = `Basic ${btoa(`${username}:${password}`)}`;
		let url = `${getBaseUrl()}/api/login`;

		// try {
		const { data } = await axios.post(
			url,
			{},
			{
				withCredentials: true,
				headers: {
					Authorization: basicAuth,
				},
			}
		);
		return data;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let request = loginObj;
		const { email, password } = request;
		if (!email || !password) {
			toastMsg({
				title: "Warning!",
				desc: "Email or Password missing!",
				type: "warning",
			});
			return;
		}
		if (email.trim().length === 0 || password.trim().length === 0) {
			toastMsg({
				title: "Warning!",
				desc: "Email or Password missing!",
				type: "warning",
			});
			return;
		}
		setLoginBtn(true);
		loginUser(email, password)
			.then((d) => {
				setSession(d);
				toastMsg({
					title: "SERC",
					desc: "Login Successful, Welcome to SERC",
					type: "success",
				});
				setTimeout(() => {
					navigate("/homepage");
				}, 2000);
			})
			.catch((error) => {
				setLoginBtn(false);
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			});
	};

	return (
		<Flex minH={"100vh"} align={"center"} justify={"center"}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Image src={mainLogo} alt="SERC" />
				</Stack>
				<Box
					bg={useColorModeValue("white", "gray.700")}
					boxShadow={"2xl"}
					p={8}
					borderWidth={1}
					borderRadius={8}
					rounded="2xl"
				>
					<form action="submit" onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<Heading
								fontSize={"3xl"}
								textAlign={"center"}
								color={"brand.700"}
								pb={3}
							>
								Login
							</Heading>
							<FormControl id="email">
								<FormLabel>Email address</FormLabel>
								<Input type="email" name="email" onChange={handleChange} />
							</FormControl>
							<FormControl id="password">
								<FormLabel>Password</FormLabel>
								<InputGroup>
									<Input
										name="password"
										type={showPassword ? "text" : "password"}
										onChange={handleChange}
									/>
									<InputRightElement h={"full"}>
										<Button
											variant={"ghost"}
											onClick={() =>
												setShowPassword((showPassword) => !showPassword)
											}
										>
											{showPassword ? <ViewIcon /> : <ViewOffIcon />}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>
							<Stack spacing={10}>
								<Stack
									direction={{ base: "column", sm: "row" }}
									align={"start"}
									justify={"space-between"}
								>
									{/* <Link href="/signup" color={"blue.400"}>
										Sign up
									</Link> */}
									<Link
										to="/forgot-password"
										style={{
											color: "var(--chakra-colors-blue-400)",
										}}
									>
										Forgot password?
									</Link>
								</Stack>
								<Button
									type="submit"
									loadingText="Logging In"
									isLoading={loginBtn}
								>
									Login
								</Button>
							</Stack>
						</Stack>
					</form>
				</Box>
			</Stack>
		</Flex>
	);
};

export default Login;
