import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	Divider,
	ListItem,
	UnorderedList,
} from "@chakra-ui/react";
import PageSection from "components/PageSection";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const Projects = ({ peopleProfile }) => {
	return (
		<VStack gap={2} align={"stretch"}>
			<Box
				padding={"15px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent={"space-between"}>
					<Badge>
						<Heading as="h6" size="xs">
							Projects as Principal Investigator
						</Heading>
					</Badge>
				</HStack>
				<Divider />
				<br />
				<Datatable
					data={peopleProfile.projectsPi}
					columns={[
						{
							className: "dt-body-left",
							title: "ID",
							data: "id",
						},
						{
							className: "dt-body-left",
							title: "Project Title",
							data: "project_title",
							width: "60%",
						},
						{
							className: "dt-body-left",
							title: "Updated On",
							data: "updated_at",
							render: function (dt) {
								return getFormattedDateStr(new Date(dt));
							},
						},
					]}
					theaders={["ID", "Project Title", "Updated On"]}
					navigatePath="/projects"
					tableId="viewPiProject"
				/>
			</Box>
			<Box
				padding={"15px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent={"space-between"}>
					<Badge>
						<Heading as="h6" size="xs">
							Projects as Co-Principal Investigator
						</Heading>
					</Badge>
				</HStack>
				<Divider />
				<br />
				<Datatable
					data={peopleProfile.projectsCoPi}
					columns={[
						{
							className: "dt-body-left",
							title: "ID",
							data: "id",
						},
						{
							className: "dt-body-left",
							title: "Project Title",
							data: "project_title",
							width: "60%",
						},
						{
							className: "dt-body-left",
							title: "Updated On",
							data: "updated_at",
							render: function (dt) {
								return getFormattedDateStr(new Date(dt));
							},
						},
					]}
					theaders={["ID", "Project Title", "Updated On"]}
					navigatePath="/projects"
					tableId="viewPiProject"
				/>
			</Box>
		</VStack>
	);
};

export default Projects;
