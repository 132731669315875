import React, { useCallback } from "react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const ResearchPrograms = () => {
	const { data, loading, error } = useFetch(
		"/api/researchPrograms/getAllResearchPrograms"
	);
	const navigate = useNavigate();

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Research Programs data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
			if (Array.isArray(dt.centers)) {
				dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
			}
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Program Name",
						data: "program_name",
						width: "60%",
					},
					{
						className: "dt-body-left",
						title: "Centers",
						data: "centers",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={["ID", "Program Name", "Centers", "Updated On"]}
				navigatePath="/research-programs"
				tableId="viewRProgram"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Research Programs"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Research Programs
					</Highlight>
				</Heading>
				<Button size="xs" onClick={() => navigate("/research-programs/create")}>
					Create
				</Button>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default ResearchPrograms;
