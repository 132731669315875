import React from "react";
import {
	Flex,
	HStack,
	Icon,
	IconButton,
	Stat,
	StatLabel,
	StatNumber,
	useColorModeValue,
} from "@chakra-ui/react";
import { GiArchiveResearch } from "react-icons/gi";
import { BsFillPeopleFill } from "react-icons/bs";
import useFetch from "hooks/useFetch";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const Stats = () => {
	const navigate = useNavigate();
		const tableBgColor = useColorModeValue("white", "gray.700");
		const tableTextColor = useColorModeValue("gray.700", "white");

		const { data: researchTaskCount } = useFetch(
			"/api/researchTask/reports/activeRtsCount"
		);
		const { data: researchersCount } = useFetch(
			"/api/researchTask/reports/activeResearchersCount"
		);

		return (
			<Flex justify="space-between" gap={3}>
				<Stat
					bg={tableBgColor}
					p="3"
					borderWidth={1}
					borderRadius="md"
					shadow="md"
					rounded="xl"
					textAlign="center"
					onClick={() => navigate("/research-tasks/active-rts")}
					_hover={{ cursor: "pointer" }}
				>
					<Flex flexDirection="column">
						<HStack>
							<Icon
								boxSize={6}
								color={"green.500"}
								ml="2"
								as={GiArchiveResearch}
							/>
							<StatLabel>Active Research Tasks</StatLabel>
						</HStack>
						<StatNumber
							alignItems="center"
							textColor={tableTextColor}
							fontSize="5xl"
							mt={10}
						>
							{researchTaskCount.activeRts}
						</StatNumber>
					</Flex>
				</Stat>
				<Stat
					bg={tableBgColor}
					p="3"
					borderWidth={1}
					borderRadius="md"
					shadow="md"
					rounded="xl"
					textAlign="center"
					onClick={() => navigate("/research-tasks/active-researchers")}
					_hover={{ cursor: "pointer" }}
				>
					<Flex flexDirection="column">
						<HStack>
							<Icon
								boxSize={6}
								color={"green.500"}
								ml="2"
								as={BsFillPeopleFill}
							/>
							<StatLabel>Active Researchers</StatLabel>
						</HStack>
						<StatNumber
							alignItems="center"
							textColor={tableTextColor}
							fontSize="5xl"
							mt={10}
						>
							{researchersCount.activePeople}
						</StatNumber>
					</Flex>
				</Stat>
			</Flex>
		);
};

export default Stats;
