import React, { useContext, useEffect, useState } from "react";
import Navigation from "../../components/Layout/Navigation";
import {
	Box,
	SimpleGrid,
	Stat,
	Heading,
	StatLabel,
	useColorModeValue,
} from "@chakra-ui/react";
import { AccountContext } from "contexts/AccountContext";
import Stats from "./Stats";
import TopResearchRask from "./TopResearchRask";
import TopPublication from "./TopPublication";
import TopTechnicalReports from "./TopTechnicalReports";

const Homepage = () => {
	const { session } = useContext(AccountContext);
	const [currentUser, setCurrentUser] = useState("");
	const tableBgColor = useColorModeValue("white", "gray.700");

	useEffect(() => {
		setCurrentUser(session.name);
	}, [session]);

	return (
		<Navigation>
			<Box>
				<Stat bg={tableBgColor} px="1" py={3} mb={10}>
					<StatLabel>Welcome!</StatLabel>
					<Heading as="h3" size="lg">
						{currentUser}
					</Heading>
				</Stat>

				<SimpleGrid columns={{ sm: 1, md: 2, lg: 2 }} gap={3}>
					<Stats />
					<TopResearchRask />
					<TopPublication />
					<TopTechnicalReports />
				</SimpleGrid>
			</Box>
		</Navigation>
	);
};

export default Homepage;
