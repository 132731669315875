import React from "react";
import {
	FormControl,
	FormLabel,
	Select as ChakraSelect,
	Input as ChakraInput,
	FormErrorMessage,
	InputGroup,
	InputLeftAddon,
} from "@chakra-ui/react";
import RichTextEditor from "@mantine/rte";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

const FieldWrapper = ({
	name,
	errors,
	label,
	required,
	children,
	...props
}) => (
	<FormControl isRequired={required} isInvalid={!!errors[name]} {...props}>
		<FormLabel>{label}</FormLabel>
		{children}
		<FormErrorMessage>{errors[name] ?? ""}</FormErrorMessage>
	</FormControl>
);

const ChakraFormikField = ({
	Component,
	field: { name, onBlur, onChange, value },
	form: { errors },
	label,
	placeholder,
	required = false,
	options = null,
	type,
	leftAddon,
	children,
	...props
}) => (
	<FieldWrapper name={name} errors={errors} label={label} required={required}>
		<InputGroup>
			{leftAddon && <InputLeftAddon children={leftAddon} />}
			<Component
				name={name}
				placeholder={placeholder}
				value={value || ""}
				onChange={onChange}
				onBlur={onBlur}
				type={type}
				{...props}
			>
				{children}
			</Component>
		</InputGroup>
	</FieldWrapper>
);

export const Select = (props) => (
	<ChakraFormikField Component={ChakraSelect} {...props} />
);

export const TextInput = (props) => (
	<ChakraFormikField Component={ChakraInput} {...props} />
);

// for this to work we need to initialize 2 values in formik - {name} and {name}_input and pass the {name}_input for this component
// it sets the value of {name}_input as the file input file and the value of {name} as the file which can be used for form submission
export const FileInput = ({ field, form, ...props }) => {
	const onChange = (e) => {
		const file = e.target.files[0];
		const value = e.target.value;
		form.setFieldValue(field.name, value);
		form.setFieldValue(field.name.replace("_input", ""), file);
	};
	field.onChange = onChange;
	return (
		<ChakraFormikField
			Component={ChakraInput}
			field={field}
			form={form}
			{...props}
		/>
	);
};

export const RichText = ({
	field: { name, value },
	form: { setFieldValue, errors, setFieldTouched },
	label,
	required = false,
	...props
}) => (
	<FieldWrapper
		name={name}
		errors={errors}
		label={label}
		required={required}
		style={{
			zIndex: 0,
		}}
	>
		<RichTextEditor
			name={name}
			id={name}
			value={value || ""}
			onChange={(newVal) => {
				setFieldValue(name, newVal);
			}}
			onBlur={() => {
				setFieldTouched(name, true, true);
			}}
			{...props}
		/>
	</FieldWrapper>
);

const ReactSelectFormik = ({
	field: { name, onBlur, value },
	form: { setFieldValue, errors },
	label,
	required = false,
	Component = { ReactSelect },
	...props
}) => (
	<FieldWrapper name={name} errors={errors} label={label} required={required}>
		<Component
			name={name}
			onChange={(newVal) => {
				const updatedVal = props.isMulti
					? newVal.map((v) => v.value)
					: newVal.value;
				setFieldValue(name, updatedVal);
			}}
			value={
				props.isMulti
					? props.options?.filter((option) => value?.includes(option.value))
					: props.options?.find((option) => option.value === value)
			}
			onBlur={onBlur}
			menuPlacement="auto"
			styles={{
				control: (provided, state) => ({
					...provided,
					background: "transparent",
				}),
			}}
			{...props}
		/>
	</FieldWrapper>
);

export const MultiSelect = ({ isCreatable = false, ...props }) => (
	<ReactSelectFormik
		{...props}
		Component={
			isCreatable && typeof props.onCreateOption === "function"
				? CreatableSelect
				: ReactSelect
		}
	/>
);
