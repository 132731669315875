import React, { createContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { getBaseUrl } from "../base";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export const AccountContext = createContext();

const AccountContextProvider = ({ children }) => {
	const toast = useToast();
	const navigate = useNavigate();

	const toastMsg = (toastData) => {
		const { title, desc, type } = toastData;
		toast({
			title: title,
			description: desc,
			status: type,
			variant: "subtle",
			duration: 5000,
			isClosable: true,
			position: "bottom-right",
		});
	};

	const [session, setSession] = useState(null);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getUserPermissions()
			.then((res) => {
				setSession(res);
			})
			.catch((error) => {
				handleSignOut();
				toastMsg({
					title: "SERC",
					desc: "Session expired!",
					type: "info",
				});
				navigate("/");
			})
			.finally(() => {
				setLoading(false);
			});

		// Check session status periodically using setInterval
		const intervalId = setInterval(() => {
			getUserPermissions()
				.then((res) => {
					setSession(res);
				})
				.catch((error) => {
					handleSignOut();
					toastMsg({
						title: "SERC",
						desc: "Session expired!",
						type: "info",
					});
					navigate("/");
					clearInterval(intervalId);
				});
		}, 60000); // Check every minute

		return () => clearInterval(intervalId); // Cleanup function to clear interval
	}, []);

	const apiCall = async (url, method = "GET", data = {}, headers = {}) => {
		// const token = session.token;
		return await axios({
			method,
			url,
			data,
			withCredentials: true,
			headers: {
				...headers,
				// Authorization: `Bearer ${token}`,
			},
		});
	};

	const handleSignOut = async () => {
		let url = `${getBaseUrl()}/api/users/logout`;
		try {
			const { data } = await apiCall(url, "POST");
			setSession(null);
			return data;
		} catch (error) {
			// console.log(error);
		}
	};

	//func to get user permission array
	const getUserPermissions = async () => {
		let url = `${getBaseUrl()}/api/users/getUserPermissions`;
		try {
			const { data } = await apiCall(url);
			return data;
		} catch (error) {
			navigate("/");
			return;
		}
	};

	return (
		<AccountContext.Provider
			value={{
				toastMsg,
				apiCall,
				session,
				loading,
				setSession,
				handleSignOut,
				getUserPermissions,
			}}
		>
			{children}
		</AccountContext.Provider>
	);
};

export default AccountContextProvider;
