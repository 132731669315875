import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Divider,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";

const EmailSec = ({ peopleProfile }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Email ID
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
				<UnorderedList pl={10}>
					{peopleProfile.emails?.map((ema) => (
						<ListItem key={ema.id}>
							<Text>
								{ema.email_address}{" "}
								<Badge size={"xs"} variant="outline">
									{ema.email_type}
								</Badge>
							</Text>
						</ListItem>
					))}
				</UnorderedList>
			</VStack>
		</>
	);
};

export default EmailSec;
