import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "components/Layout/Navigation";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
	VStack,
	Badge,
	UnorderedList,
	ListItem,
	Text,
	IconButton,
	FormControl,
	FormLabel,
	Input,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	useDisclosure,
	AlertDialogCloseButton,
	Icon,
	TableContainer,
	Thead,
	Table,
	Tbody,
	Tr,
	Th,
	Td,
	Checkbox,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import Select from "react-select";
import { FaKey } from "react-icons/fa";
import AccessDenied from "pages/AccessDenied";

const ViewGroup = () => {
	const { apiCall, toastMsg, session } = useContext(AccountContext);
	const { id } = useParams();
	const [groupObj, setGroupObj] = useState({});
	const [infoObj, setInfoObj] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const [userList, setUserList] = useState([]);
	const [permissionList, setPermissionList] = useState([]);
	const navigate = useNavigate();

	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();
	const {
		isOpen: isDeleteOpen,
		onOpen: onDeleteOpen,
		onClose: onDeleteClose,
	} = useDisclosure();

	//func to get single group
	const getGroup = async () => {
		let url = `${getBaseUrl()}/api/accessRoles/${id}`;
		const { data } = await apiCall(url);
		setGroupObj(data);
		return;
	};

	//func to get all user list
	const getUserList = async () => {
		let url = `${getBaseUrl()}/api/users/getAllUsers`;
		const { data } = await apiCall(url);
		setUserList(data);
		return;
	};

	//func to get all permission list
	const getPermissionList = async () => {
		let url = `${getBaseUrl()}/api/permissions`;
		const { data } = await apiCall(url);
		setPermissionList(data);
		return;
	};

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	useEffect(() => {
		getGroup();
		getUserList();
		getPermissionList();
	}, []);

	useEffect(() => {
		if (!groupObj || userList.length === 0 || permissionList.length === 0)
			return;
		let userVal = [];

		groupObj.users?.forEach((u) => {
			let userObj = userList.find((i) => i.id === u.id);
			if (userObj !== undefined) {
				userVal.push({
					value: userObj.id,
					label: userObj.name,
				});
			}
		});

		setInfoObj({
			name: groupObj.name,
			users: groupObj.users?.map((i) => i.id),
			permissions: groupObj.permissions,
			defUR: userVal,
		});
	}, [groupObj, userList, permissionList]);

	//func to handle permissions
	const handleCheckboxChange = (type, category) => {
		const newSelectedPermissions = [...infoObj.permissions];
		const categoryIndex = newSelectedPermissions.findIndex(
			(p) => p.category === category
		);

		if (categoryIndex === -1) {
			newSelectedPermissions.push({ category, types: [type] });
		} else {
			const newTypes = [...newSelectedPermissions[categoryIndex].types];
			const typeIndex = newTypes.indexOf(type);
			if (typeIndex === -1) {
				newTypes.push(type);
			} else {
				newTypes.splice(typeIndex, 1);
			}
			if (newTypes.length === 0) {
				newSelectedPermissions.splice(categoryIndex, 1);
			} else {
				newSelectedPermissions[categoryIndex] = { category, types: newTypes };
			}
		}

		setInfoObj({ ...infoObj, permissions: newSelectedPermissions });
	};

	//func to delete group
	const deleteGroup = async () => {
		try {
			setAddBtnStatus(true);
			let url = `${getBaseUrl()}/api/accessRoles/${id}`;
			await apiCall(url, "DELETE").then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/user-group-management");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to update group
	const updateGroup = async () => {
		try {
			let url = `${getBaseUrl()}/api/accessRoles/${id}`;
			delete infoObj.defUR;
			await apiCall(url, "PUT", infoObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getGroup();
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			getGroup();
			toggleInfoEdit();
			return;
		}
	};

	return (
		<Navigation>
			{session && session.isAdmin === true ? (
				<VStack gap={1} align={"stretch"} pb={20}>
					<form>
						<AlertDialog
							isOpen={isDeleteOpen}
							onClose={() => onDeleteClose()}
							closeOnOverlayClick={false}
							motionPreset="slideInBottom"
						>
							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize="lg" fontWeight="bold">
										Delete Group
									</AlertDialogHeader>
									<AlertDialogCloseButton />
									<AlertDialogBody>
										Are you sure?
										<br />
										Confirm to delete Group
									</AlertDialogBody>

									<AlertDialogFooter>
										<Button size={"sm"} onClick={() => onDeleteClose()}>
											Cancel
										</Button>
										<Button
											type="submit"
											size={"sm"}
											isLoading={addBtnStatus}
											loadingText="Deleting"
											onClick={() => {
												deleteGroup();
												onDeleteClose();
											}}
											ml={3}
										>
											Delete
										</Button>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>
						</AlertDialog>

						<AlertDialog
							isOpen={isUpdateOpen}
							onClose={() => onUpdateClose()}
							closeOnOverlayClick={false}
							motionPreset="slideInBottom"
						>
							<AlertDialogOverlay>
								<AlertDialogContent>
									<AlertDialogHeader fontSize="lg" fontWeight="bold">
										Update Project Info
									</AlertDialogHeader>

									<AlertDialogBody>
										Are you sure?
										<br />
										Confirm to update.
									</AlertDialogBody>
									<AlertDialogCloseButton />
									<AlertDialogFooter>
										<Button size={"sm"} onClick={() => onUpdateClose()}>
											Cancel
										</Button>
										<Button
											type="submit"
											size={"sm"}
											onClick={() => {
												updateGroup();
												onUpdateClose();
											}}
											ml={3}
										>
											Update
										</Button>
									</AlertDialogFooter>
								</AlertDialogContent>
							</AlertDialogOverlay>
						</AlertDialog>
						<HStack justifyContent={"space-between"}>
							<Heading as="h3" size="mx" mb={2}>
								<Highlight
									query="Group Management"
									styles={{
										px: "2",
										py: "1",
										rounded: "full",
										bg: "brand.100",
									}}
								>
									Group Management
								</Highlight>
							</Heading>
						</HStack>
						<Divider mb={2} />
						<Box
							padding={"15px"}
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
						>
							<HStack justifyContent={"space-between"}>
								<Badge>
									<Heading as="h6" size="xs">
										Information
									</Heading>
								</Badge>
								<HStack>
									{infoEdit === false ? (
										<IconButton
											onClick={toggleInfoEdit}
											size="xs"
											icon={<EditIcon />}
										/>
									) : (
										<></>
									)}
								</HStack>
							</HStack>
							<Divider />
							<br />
							{infoEdit === false ? (
								<VStack align={"stretch"} px={10}>
									<Heading as="h2" size="xl">
										<Icon as={FaKey} /> {groupObj.name}
									</Heading>
									<Text fontSize="xs">
										Created On:{" "}
										{new Date(groupObj.created_at).toLocaleDateString()}
									</Text>
									<Text fontSize="xs">
										Updated On:{" "}
										{new Date(groupObj.updated_at).toLocaleDateString()}
									</Text>
									<br />
									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>List of Users assigned</Badge>
										<UnorderedList pl={10}>
											{groupObj.users?.map((ur) => (
												<ListItem key={ur.id}>
													<Text>{ur.name}</Text>
												</ListItem>
											))}
										</UnorderedList>
									</VStack>
									<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
										<Badge>List of Permissions</Badge>
										{groupObj.permissions?.length ? (
											<TableContainer>
												<Table>
													{groupObj.permissions?.map((p) => (
														<Tr key={p.id}>
															<Th>{p.category}</Th>
															<Td>{p.types.map((a) => a).join(", ")}</Td>
														</Tr>
													))}
												</Table>
											</TableContainer>
										) : (
											<Text>NA</Text>
										)}
									</VStack>
									<HStack justifyContent={"center"}>
										<Button
											rightIcon={<DeleteIcon />}
											w={"10%"}
											onClick={() => {
												onDeleteOpen();
											}}
										>
											Delete
										</Button>
									</HStack>
								</VStack>
							) : (
								<VStack gap={3} px={10}>
									<FormControl isRequired>
										<FormLabel>Group Name</FormLabel>
										<Input
											name="name"
											type="text"
											value={infoObj.name}
											size={"sm"}
											placeholder="Group Name"
											onChange={(e) => {
												setInfoObj((prev) => {
													return {
														...prev,
														name: e.target.value,
													};
												});
											}}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Assign Users</FormLabel>
										<Select
											isMulti
											placeholder="Search or Select multiple users"
											defaultValue={infoObj.defUR}
											options={userList?.map((i) => {
												return {
													value: i.id,
													label: i.name,
												};
											})}
											onChange={(e) => {
												let userL = [];
												e.forEach((u) => {
													userL.push(u.value);
												});
												setInfoObj((prev) => {
													return { ...prev, users: userL };
												});
											}}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Assign Permissions</FormLabel>
										<TableContainer
											width={"100%"}
											borderWidth={1}
											borderRadius={8}
											boxShadow="lg"
											rounded="xl"
										>
											<Table variant="simple">
												<Thead>
													<Tr>
														<Th></Th>
														<Th>READ</Th>
														<Th>CREATE</Th>
														<Th>UPDATE</Th>
														<Th>DELETE</Th>
													</Tr>
												</Thead>
												<Tbody>
													{permissionList?.map((p, idx) => (
														<Tr key={idx}>
															<Th>{p.category}</Th>
															{p.types?.map((t, idxx) => (
																<Td key={idxx}>
																	<Checkbox
																		value={t}
																		isChecked={infoObj.permissions.some(
																			(pp) =>
																				pp.category === p.category &&
																				pp.types.includes(t)
																		)}
																		onChange={() =>
																			handleCheckboxChange(t, p.category)
																		}
																	/>
																</Td>
															))}
														</Tr>
													))}
												</Tbody>
											</Table>
										</TableContainer>
									</FormControl>
									<HStack gap={3} justifyContent={"center"} pt={5} pb={3}>
										<Button
											size="xs"
											onClick={() => {
												getGroup();
												toggleInfoEdit();
											}}
										>
											Cancel
										</Button>
										<Button
											size="xs"
											colorScheme="teal"
											onClick={() => {
												onUpdateOpen();
											}}
										>
											Update Group
										</Button>
									</HStack>
								</VStack>
							)}
						</Box>
					</form>
				</VStack>
			) : (
				<AccessDenied />
			)}
		</Navigation>
	);
};

export default ViewGroup;
