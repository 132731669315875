import React from "react";
import {
	Box,
	Heading,
	Highlight,
	HStack,
	IconButton,
	Table,
	Tbody,
	Td,
	Tr,
	useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useNavigate, Link } from "react-router-dom";
import useFetch from "hooks/useFetch";

const TopPublication = () => {
	const navigate = useNavigate();
	const tableBgColor = useColorModeValue("white", "gray.700");
	const tableTextColor = useColorModeValue("gray.700", "white");

	const { data: topPublications } = useFetch(
		"/api/publications/reports/topPublication"
	);

	return (
		<Box
			borderWidth={1}
			bg={tableBgColor}
			px="2"
			py="2"
			borderRadius="md"
			shadow="md"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"} py={1}>
				<Heading as="h6" size="xs" mb={2}>
					<Highlight
						query="Recent Publications"
						styles={{
							px: "2",
							py: "1",
							rounded: "md",
							bg: "brand.100",
						}}
					>
						Recent Publications
					</Highlight>
				</Heading>
				<IconButton
					size={"xs"}
					icon={<ExternalLinkIcon />}
					aria-label="Add researcher"
					variant="ghost"
					onClick={() => navigate("/publications")}
				/>
			</HStack>

			<Table size="sm">
				<Tbody>
					{topPublications.map((pb) => (
						<Tr key={pb.id}>
							<Td textColor={tableTextColor}>
								<Link to={`/publications/${pb.id}`}>{pb.title}</Link>
							</Td>
						</Tr>
					))}
				</Tbody>
			</Table>
		</Box>
	);
};

export default TopPublication;
