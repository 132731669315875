import { Button, HStack, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import Modal from "components/Modal";
import { AccountContext } from "contexts/AccountContext";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React, { useContext } from "react";
import { quickCreateOrg } from "utils/api";

function QuickCreateOrgModal({
	isOpen,
	onClose = () => {},
	onSuccess = () => {},
}) {
	const { data: orgTypes } = useFetch("/api/org/getAllOrgTypes");
	const { toastMsg } = useContext(AccountContext);
	return (
		<Modal isOpen={isOpen} onClose={onClose} header="Create Organization">
			<Formik
				initialValues={{
					organizationName: "",
					organizationTypeId: null,
				}}
				onSubmit={async (
					{ organizationName, organizationTypeId },
					{ setSubmitting }
				) => {
					try {
						setSubmitting(true);
						const res = await quickCreateOrg(
							organizationName,
							organizationTypeId
						);
						await onSuccess(res.org);
					} catch (e) {
						console.error(e);
						toastMsg({
							title: "Error!",
							desc: "Error creating new organization",
							type: "error",
						});
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({ isSubmitting }) => (
					<Form>
						<VStack gap={3} alignItems="stretch">
							<Field
								name="organizationName"
								label="Organization Name"
								placeholder="Organization Name"
								component={TextInput}
								required
							/>
							<Field
								name="organizationTypeId"
								component={MultiSelect}
								label="Organization Type"
								placeholder="Organization Type"
								options={(orgTypes || []).map((o) => ({
									value: o.id,
									label: o.type_name,
								}))}
								required
							/>
							<HStack gap={3} justifyContent={"flex-end"}>
								<Button onClick={onClose}>Cancel</Button>
								<Button
									colorScheme="teal"
									type="submit"
									isLoading={isSubmitting}
								>
									Add Organization
								</Button>
							</HStack>
						</VStack>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

export default QuickCreateOrgModal;
