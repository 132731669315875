import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	IconButton,
	VStack,
} from "@chakra-ui/react";
import {
	MultiSelect,
	RichText,
	Select,
	TextInput,
} from "components/ChakraFormikComponents";
import Modal from "components/Modal";
import { AccountContext } from "contexts/AccountContext";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AddPerson from "./AddPerson";
import QuickCreateOrgModal from "../QuickCreateOrgModal";

function ResearchTask({
	id = null,
	isPublic = null,
	task_name = null,
	task_number = null,
	abstract = null,
	description = null,
	transition = null,
	deliverables = null,
	organizations = [],
	secondary_sponsors = [],
	primary_sponsors = [],
	research_projects = [],
	roles = [],
	centers = [],
	period_start = null,
	period_end = null,
	relatedResearchTasks = [],
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	onCancel = () => {},
	submitBtnText = "Add Research Task",
	hideTeamMembersSection = false,
}) {
	const { toastMsg } = useContext(AccountContext);
	const { data: organizationsData } = useFetch("/api/org/getAllOrgs");
	const { data: sponsors } = useFetch(
		"/api/researchTask/getResearchTaskSponsors"
	);
	const { data: projects } = useFetch("/api/projects/getAllProjects");
	const { data: rolesData } = useFetch(
		"/api/researchTask/getResearchTaskRoles"
	);
	const { data: peopleData } = useFetch("/api/people/getAllPeopleWithOrg");
	const { data: researchTasks } = useFetch(
		"/api/researchTask/getAllResearchTasks"
	);

	const [addPersonModal, setAddPersonModal] = useState(false);
	const [people, setPeople] = useState([]);
	const [orgs, setOrgs] = useState([]);
	const [createOrgModal, setCreateOrgModal] = useState(false);

	useEffect(() => {
		setPeople(peopleData);
	}, [peopleData]);

	useEffect(() => {
		setOrgs(organizationsData);
	}, [organizationsData]);

	const handleCreatePerson = useCallback(
		async (newOption, onSuccess) => {
			setPeople([...people, newOption]);
			onSuccess(newOption);
			toastMsg({
				title: "Success!",
				desc: "Created person successfully",
				type: "success",
			});
			setAddPersonModal(false);
			return;
		},
		[people, toastMsg]
	);

	return (
		<>
			<Formik
				initialValues={{
					public: isPublic,
					task_name,
					task_number,
					abstract,
					description,
					transition,
					deliverables,
					organizations,
					secondary_sponsors,
					primary_sponsors,
					research_projects,
					roles,
					centers,
					period_start,
					period_end,
					relatedResearchTasks,
				}}
				enableReinitialize
				onSubmit={async (values, { setSubmitting }) => {
					try {
						setSubmitting(true);
						const body = {
							...values,
							primary_sponsors: Array.isArray(values.primary_sponsors)
								? values.primary_sponsors
								: [values.primary_sponsors],
						};
						if (hideTeamMembersSection) delete body.roles;
						const res = await onSubmit(body);
						onSuccess(res);
					} catch (e) {
						onError(e);
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({ values, isSubmitting, setFieldValue }) => (
					<Form>
						<Box
							padding={"10px"}
							borderWidth={1}
							borderRadius={8}
							boxShadow="lg"
							rounded="xl"
							pb={5}
						>
							<VStack gap={3} alignItems="stretch">
								<Field
									name="public"
									component={Select}
									label="Public"
									placeholder="Select option"
									required
								>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
								</Field>
								<Field
									name="task_name"
									component={TextInput}
									label="Task Name"
									placeholder="Task Name"
									required
								/>
								<HStack gap={3}>
									<Field
										name="task_number"
										component={TextInput}
										label="Task Number"
										placeholder="Task Number"
										required
									/>
									<Field
										name="centers"
										component={MultiSelect}
										label="Center"
										placeholder="Select Center (Multi Select)"
										options={(["serc", "airc"] || []).map((c) => ({
											value: c,
											label: c.toUpperCase(),
										}))}
										isMulti
										required
									/>
								</HStack>
								<Field
									name="primary_sponsors"
									component={MultiSelect}
									label="Primary Sponsor"
									placeholder="Select Primary Sponsor"
									options={(sponsors || []).map((sponsor) => ({
										value: sponsor.id,
										label: sponsor.organization_name,
									}))}
								/>
								<Field
									name="secondary_sponsors"
									component={MultiSelect}
									label="Secondary Sponsors"
									placeholder="Select Secondary Sponsors (Multi Select)"
									options={(sponsors || []).map((sponsor) => ({
										value: sponsor.id,
										label: sponsor.organization_name,
									}))}
									isMulti
								/>
								<Field
									name="organizations"
									component={MultiSelect}
									label="Organizations"
									placeholder="Select Organizations (Multi Select)"
									options={(orgs || []).map((org) => ({
										value: org.id,
										label: org.organization_name,
									}))}
									isMulti
									isCreatable
									onCreateOption={() => {
										setCreateOrgModal(true);
									}}
									isLoading={createOrgModal}
									isDisabled={createOrgModal}
								/>
								<QuickCreateOrgModal
									isOpen={createOrgModal}
									onClose={() => {
										setCreateOrgModal(false);
									}}
									onSuccess={async (org) => {
										setOrgs([...orgs, org]);
										setFieldValue("organizations", [
											...values.organizations,
											org.id,
										]);
										toastMsg({
											title: "Success!",
											desc: "Created organization successfully",
											type: "success",
										});
										setCreateOrgModal(false);
										return;
									}}
								/>
								<Field
									name="research_projects"
									component={MultiSelect}
									label="Research Projects"
									placeholder="Select Research Projects (Multi Select)"
									options={(projects || []).map((project) => ({
										value: project.id,
										label: project.project_title,
									}))}
									isMulti
								/>
								<Field
									name="relatedResearchTasks"
									component={MultiSelect}
									label="Related Research Tasks"
									placeholder="Select Related Research Tasks (Multi Select)"
									options={(researchTasks || [])
										.filter((rt) => rt.id !== id)
										.map((rt) => ({
											value: rt.id,
											label: rt.task_name,
										}))}
									isMulti
								/>
								{!hideTeamMembersSection && (
									<>
										<Divider />
										<Heading as="h3" size="md">
											Team Members
										</Heading>
										{values.roles.map((val, index) => (
											<HStack
												alignItems="center"
												key={`${val.person_id}-${index}`}
											>
												<Field
													name={`roles[${index}].person_id`}
													component={MultiSelect}
													label="Person"
													placeholder="Select Person"
													options={(people || []).map((person) => ({
														value: person.id,
														label: `${person.first_name} ${person.last_name}`,
													}))}
													isCreatable
													onCreateOption={() => {
														setAddPersonModal(`roles[${index}].person_id`);
													}}
													isLoading={addPersonModal}
													isDisabled={addPersonModal}
													required
												/>
												<Field
													name={`roles[${index}].task_role`}
													component={MultiSelect}
													label="Role"
													placeholder="Select Role"
													options={(rolesData || []).map((role) => ({
														value: role.role_name?.toLowerCase(),
														label: role.role_name,
													}))}
													required
												/>
												<Field
													name={`roles[${index}].start_date`}
													component={TextInput}
													label="Start Date"
													type="date"
													required
												/>
												<Field
													name={`roles[${index}].end_date`}
													component={TextInput}
													label="End Date"
													type="date"
													required
												/>
												<IconButton
													size="sm"
													icon={<DeleteIcon />}
													style={{
														marginLeft: "1rem",
													}}
													onClick={() => {
														const updatedValue = [...values.roles];
														updatedValue.splice(index, 1);
														setFieldValue("roles", updatedValue);
													}}
													variant="outline"
												/>
											</HStack>
										))}
										<Modal
											isOpen={Boolean(addPersonModal)}
											header="Add Person"
											onClose={() => {
												setAddPersonModal(false);
											}}
										>
											<AddPerson
												onCancel={() => {
													setAddPersonModal(false);
												}}
												onSuccess={async (person) =>
													handleCreatePerson(person, (person) => {
														setFieldValue(addPersonModal, person.id);
													})
												}
												onError={() => {
													toastMsg({
														title: "Error!",
														desc: "Error creating new person",
														type: "error",
													});
												}}
											/>
										</Modal>
										<Button
											leftIcon={<AddIcon />}
											size="sm"
											style={{
												marginTop: "1.5rem",
												alignSelf: "flex-start",
											}}
											onClick={() => {
												setFieldValue("roles", [
													...values.roles,
													{
														person_id: null,
														task_role: null,
														start_date: null,
														end_date: null,
													},
												]);
											}}
										>
											Add Team Member
										</Button>
										<Divider />
									</>
								)}
								<Field name="abstract" component={RichText} label="Abstract" />
								<Field
									name="description"
									component={RichText}
									label="Description"
								/>
								<Field
									name="transition"
									component={RichText}
									label="Transition"
								/>
								<Field
									name="deliverables"
									component={RichText}
									label="Deliverables"
								/>
								<HStack gap={3}>
									<Field
										name="period_start"
										component={TextInput}
										label="Period of Performance - Start Date"
										type="date"
										required
									/>
									<Field
										name="period_end"
										component={TextInput}
										label="Period of Performance - End Date"
										type="date"
										required
									/>
								</HStack>
							</VStack>
						</Box>
						<HStack gap={3} justifyContent={"center"} pt={5}>
							<Button size="xs" onClick={onCancel}>
								Cancel
							</Button>
							<Button
								size="xs"
								colorScheme="teal"
								type="submit"
								isLoading={isSubmitting}
							>
								{submitBtnText}
							</Button>
						</HStack>
					</Form>
				)}
			</Formik>
		</>
	);
}

export default ResearchTask;
