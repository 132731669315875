import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	IconButton,
	ListItem,
	Text,
	UnorderedList,
	VStack,
} from "@chakra-ui/react";
import PageSection from "components/PageSection";
import React, { useContext, useEffect, useState } from "react";
import ResearchTaskForm from "components/Forms/ResearchTask";
import { DELETE, updateResearchTask } from "utils/api";
import { AccountContext } from "contexts/AccountContext";
import CustomAlertDialog from "components/CustomAlertDialog";
import { useNavigate } from "react-router-dom";

function ResearchTask({ researchData }) {
	const [research, setResearch] = useState(researchData);
	const [isEditing, setEditing] = useState(false);
	const { toastMsg } = useContext(AccountContext);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		setResearch(researchData);
	}, [researchData]);

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await DELETE(`/api/researchTask/${research.id}`).then((data) => {
				setIsOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setIsLoading(false);
				navigate("/research-tasks");
				return;
			});
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<VStack gap={1} align="stretch" pb={20}>
			<Box
				padding="10px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Research Task
						</Heading>
					</Badge>
					<IconButton
						onClick={() => {
							setEditing(!isEditing);
						}}
						size="xs"
						icon={<EditIcon />}
					/>
				</HStack>
				<Divider />
				{isEditing ? (
					<ResearchTaskForm
						onCancel={() => {
							setEditing(false);
						}}
						{...research}
						isPublic={research.public}
						primary_sponsors={research.primarySponsors?.find((p) => p.id)?.id}
						secondary_sponsors={research.secondarySponsors?.map((s) => s.id)}
						organizations={research.orgs?.map((o) => o.id)}
						research_projects={research.projects?.map((p) => p.id)}
						relatedResearchTasks={research.relatedResearchTasks?.map(
							(rt) => rt.id
						)}
						submitBtnText="Update Research Task"
						hideTeamMembersSection
						onSubmit={async (body) =>
							updateResearchTask({ id: research.id, ...body })
						}
						onSuccess={(res) => {
							setResearch(res.researchTask);
							setEditing(false);
						}}
					/>
				) : (
					<Box>
						<CustomAlertDialog
							title="Delete Research Task"
							message="Are you sure?  Confirm to delete"
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
							onConfirm={handleDelete}
							isLoading={isLoading}
							confirmText="Delete"
						/>
						<VStack align="stretch" px={5}>
							<VStack align="stretch" justifyContent="flex-start" pb={5} pt={3}>
								<Heading as="h1" size="xl">
									{research.task_name}
								</Heading>
							</VStack>
						</VStack>
						<Text px={5} mb={2} as="b">
							Public:{" "}
							<Badge colorScheme={research.public ? "green" : "red"}>
								{research.public}
							</Badge>
						</Text>
						<HStack px={5} mb={5}>
							<Text as="b">Task Number:</Text>
							<Text>{research.task_number}</Text>
						</HStack>
						<PageSection title="Center">
							<UnorderedList>
								{research.centers.map((c, idx) => (
									<ListItem key={idx}>{c.toUpperCase()}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Abstract">
							<Text
								dangerouslySetInnerHTML={{
									__html: research.abstract,
								}}
							/>
						</PageSection>
						<PageSection title="Description">
							<Text
								dangerouslySetInnerHTML={{
									__html: research.description,
								}}
							/>
						</PageSection>
						<PageSection title="Transition">
							<Text
								dangerouslySetInnerHTML={{
									__html: research.transition,
								}}
							/>
						</PageSection>
						<PageSection title="Deliverables">
							<Text
								dangerouslySetInnerHTML={{
									__html: research.deliverables,
								}}
							/>
						</PageSection>
						<PageSection title="Organizations">
							<UnorderedList>
								{research.orgs.map((organization) => (
									<ListItem key={organization.id}>
										{organization.organization_name}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Primary Sponsors">
							<UnorderedList>
								{research.primarySponsors.map((sponsor) => (
									<ListItem key={sponsor.id}>
										{sponsor.organization_name}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Secondary Sponsors">
							<UnorderedList>
								{research.secondarySponsors.map((sponsor) => (
									<ListItem key={sponsor.id}>
										{sponsor.organization_name}
									</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Projects">
							<UnorderedList>
								{research.projects.map((project) => (
									<ListItem key={project.id}>{project.project_title}</ListItem>
								))}
							</UnorderedList>
						</PageSection>
						<PageSection title="Performance Period">
							<Text>Start Date: {research.period_start}</Text>
							<Text>End Date: {research.period_end}</Text>
						</PageSection>
						<Divider pb={2} />
						<HStack justifyContent={"center"} pt={2}>
							<Button
								rightIcon={<DeleteIcon />}
								w={"10%"}
								onClick={() => {
									setIsOpen(true);
								}}
							>
								Delete
							</Button>
						</HStack>
					</Box>
				)}
			</Box>
		</VStack>
	);
}

export default ResearchTask;
