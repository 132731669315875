import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Divider,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";

const PhoneSec = ({ peopleProfile }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Phone
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
				<UnorderedList pl={10}>
					{peopleProfile.phones?.map((ph) => (
						<ListItem key={ph.id}>
							<Text>
								{ph.phone_number}{" "}
								<Badge size={"xs"} variant="outline">
									{ph.phone_type}
								</Badge>
							</Text>
						</ListItem>
					))}
				</UnorderedList>
			</VStack>
		</>
	);
};

export default PhoneSec;
