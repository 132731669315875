import { Button, Divider, Heading, HStack, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import useFetch from "hooks/useFetch";
import React from "react";

function isNumberKey(evt) {
	const charCode = evt.which ? evt.which : evt.keyCode;
	if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
	return true;
}

function FinanceForm({
	contract = null,
	thrust = null,
	taskOrder = null,
	kuali = null,
	researchTaskId = null,
	taskCorrelation = null,
	awardDate = null,
	incrementCount = "00",
	totalAwardAmount = null,
	cores = null,
	sponsors = null,
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	onCancel = () => {},
	submitBtnText = "Add Finance Record",
	hideAwardsSection = false,
}) {
	const { data: researchThrusts } = useFetch(
		"/api/researchThrusts/getAllResearchThrust"
	);
	const { data: researchTasks } = useFetch(
		"/api/researchTask/getAllResearchTasks"
	);
	const { data: sponsorsData } = useFetch(
		"/api/researchTask/getResearchTaskSponsors"
	);

	return (
		<Formik
			initialValues={{
				contract,
				thrust,
				taskOrder,
				kuali,
				researchTaskId,
				taskCorrelation,
				awardDate,
				incrementCount,
				totalAwardAmount,
				cores,
				sponsors,
			}}
			enableReinitialize
			onSubmit={async (values, { setSubmitting }) => {
				try {
					setSubmitting(true);
					if (hideAwardsSection) {
						delete values.awardDate;
						delete values.incrementCount;
						delete values.totalAwardAmount;
						delete values.cores;
						delete values.sponsors;
					} else {
						values.incrementCount = parseInt(values.incrementCount, 10);
					}
					const res = await onSubmit(values);
					onSuccess(res);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<VStack gap={3} alignItems="stretch">
						<HStack gap={3}>
							<Field
								name="contract"
								component={TextInput}
								label="Contract"
								placeholder="Contract"
								required
							/>
							<Field
								name="taskOrder"
								component={TextInput}
								label="DO Number"
								placeholder="DO Number"
								type="number"
								required
							/>
						</HStack>
						<Field
							name="researchTaskId"
							component={MultiSelect}
							label="Research Task Title"
							placeholder="Select Research Task"
							options={(researchTasks || []).map((rt) => ({
								value: rt.id,
								label: rt.task_name,
							}))}
							required
						/>
						<Field
							name="thrust"
							component={MultiSelect}
							label="Research Thrusts"
							placeholder="Select Research Thrust"
							options={(researchThrusts || []).map((rt) => ({
								value: rt.id,
								label: rt.thrust_name,
							}))}
						/>
						<Field
							name="kuali"
							component={TextInput}
							label="Kuali"
							placeholder="Kuali"
						/>
						<Field
							name="taskCorrelation"
							component={TextInput}
							label="Task Correlation"
							placeholder="Task Correlation"
						/>
						<Divider />
						{!hideAwardsSection && (
							<>
								<Heading as="h3" size="md">
									Award Details
								</Heading>
								<Field
									name="sponsors"
									component={MultiSelect}
									label="Sponsors"
									placeholder="Select Sponsors (Multi Select)"
									options={(sponsorsData || []).map((sponsor) => ({
										value: sponsor.id,
										label: sponsor.organization_name,
									}))}
									isMulti
									required
								/>
								<HStack gap={3}>
									<Field
										name="cores"
										component={MultiSelect}
										label="Core"
										placeholder="Select Core (Multi Select)"
										options={[
											{ value: "Core", label: "Core" },
											{
												value: "Non-Core",
												label: "Non-Core",
											},
										]}
										isMulti
										required
									/>
									<Field
										name="incrementCount"
										component={TextInput}
										label="Increment Number"
										placeholder="Increment Number"
										onKeyDown={isNumberKey}
										required
									/>
								</HStack>
								<HStack gap={3}>
									<Field
										name="totalAwardAmount"
										component={TextInput}
										label="Award Amount"
										placeholder="Award Amount"
										leftAddon="$"
										required
									/>
									<Field
										name="awardDate"
										component={TextInput}
										label="Award Date"
										type="date"
									/>
								</HStack>
							</>
						)}
					</VStack>
					<HStack gap={3} justifyContent={"center"} pt={10}>
						<Button size="xs" onClick={onCancel}>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={isSubmitting}
						>
							{submitBtnText}
						</Button>
					</HStack>
				</Form>
			)}
		</Formik>
	);
}

export default FinanceForm;
