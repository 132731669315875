import { Badge, Box, Button, Divider, HStack, VStack } from "@chakra-ui/react";
import { TextInput } from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import React from "react";

function PublicationTypeForm({
	category = null,
	onSubmit = () => {},
	onSuccess = () => {},
	onError = () => {},
	onCancel = () => {},
	submitBtnText = "Add Publication Type",
}) {
	return (
		<Formik
			initialValues={{
				category,
			}}
			enableReinitialize
			onSubmit={async (category, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const res = await onSubmit(category);
					onSuccess(res);
				} catch (e) {
					onError(e);
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form>
					<Box
						padding={"15px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						pb={8}
					>
						<VStack gap={3} alignItems="stretch">
							<Box>
								<Badge sx={{ mb: 2 }}>Category</Badge>
								<Divider />
							</Box>

							<Field
								name="category"
								component={TextInput}
								label="Publication type"
								placeholder="Enter Publication type"
								required
							/>
						</VStack>
					</Box>
					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button onClick={onCancel} size="xs">
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={isSubmitting}
							loadingText="Adding Publication Type"
						>
							{submitBtnText}
						</Button>
					</HStack>
				</Form>
			)}
		</Formik>
	);
}

export default PublicationTypeForm;
