import React, { useContext, useEffect, useState } from "react";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Highlight,
	HStack,
	Input,
	VStack,
	Select as ChakraSelect,
	Table,
	TableContainer,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	IconButton,
	Divider,
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import Select from "react-select";
import Navigation from "../../../components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import { getBaseUrl } from "../../../base";
import countryList from "utils/data/Country-State.json";
import Creatable from "react-select/creatable";
import QuickCreateOrgModal from "components/Forms/QuickCreateOrgModal";

export const salutationList = [
	"Mr.",
	"Mrs.",
	"Ms.",
	"Dr.",
	"CAPT",
	"COL",
	"Col",
	"Gen",
	"LTC",
	"LtCol",
	"LTG",
	"Maj Gen",
	"MG",
	"MAJ",
	"Maj",
];

const CreatePeople = () => {
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [rolesList, setRolesList] = useState([]);
	const [orgsList, setOrgsList] = useState([]);
	const [createOrgModal, setCreateOrgModal] = useState(false);
	const navigate = useNavigate();

	//func to create people
	const createPerson = async (peopleFormData, config) => {
		let url = `${getBaseUrl()}/api/people/createPerson`;
		const { data } = await apiCall(url, "POST", peopleFormData, config);
		return data;
	};

	//func to get list of orgs
	const getAllOrgs = async () => {
		let url = `${getBaseUrl()}/api/org/getAllOrgs`;
		const { data } = await apiCall(url);

		return data;
	};

	//func to get list of roles
	const getAllRoles = async () => {
		let url = `${getBaseUrl()}/api/roles/getAllRoles`;
		const { data } = await apiCall(url);
		return data;
	};

	useEffect(() => {
		getAllRoles().then((res) => {
			setRolesList(res);
		});
	}, []);

	useEffect(() => {
		getAllOrgs().then((res) => {
			setOrgsList(res);
		});
	}, []);

	//Information------------------------------
	const [infoObj, setInfoObj] = useState({
		profile: "",
		public: "",
		prefix: "",
		first_name: "",
		middle_name: "",
		last_name: "",
		suffix: "",
		biography: "",
	});

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//Organizations-----------------------------
	const [orgList, setOrgList] = useState([]);

	const handleOrgList = (llist) => {
		setOrgList(llist);
	};

	//roles-------------------------------------
	const [roleList, setRoleList] = useState([]);

	const handleRoleList = (rlist) => {
		let roleL = [];
		rlist.forEach((r) => {
			roleL.push(r.value);
		});
		setRoleList(roleL);
	};

	//Emails--------------------------------------
	const [emailList, setEmailList] = useState([
		{
			email_address: "",
			email_type: "",
		},
	]);

	const handleAddEmail = () => {
		setEmailList([
			...emailList,
			{
				email_address: "",
				email_type: "",
			},
		]);
	};

	const handleEmailChange = (e, index) => {
		const { name, value } = e.target;
		const newEmailList = [...emailList];
		newEmailList[index][name] = value;
		setEmailList(newEmailList);
	};

	const handleRemoveEmail = (idx) => {
		const newEmailList = [...emailList];
		newEmailList.splice(idx, 1);
		setEmailList(newEmailList);
	};

	//Job titles--------------------------------
	const [jobList, setJobList] = useState([]);

	const handleAddJob = () => {
		setJobList([
			...jobList,
			{
				job_title: "",
				organization_id: "",
				start_date: "",
				end_date: "",
				current: "No",
				visibility: "",
			},
		]);
	};

	const handleJobChange = (e, index) => {
		const { name, value } = e.target;
		const newJobList = [...jobList];
		newJobList[index][name] = value;
		setJobList(newJobList);
	};

	const handleRemoveJob = (idx) => {
		const newJobList = [...jobList];
		newJobList.splice(idx, 1);
		setJobList(newJobList);
	};

	//Phones--------------------------------------
	const [phoneList, setPhoneList] = useState([]);

	const handleAddPhone = () => {
		setPhoneList([
			...phoneList,
			{
				phone_number: "",
				phone_type: "",
			},
		]);
	};

	const handlePhoneChange = (e, index) => {
		const { name, value } = e.target;
		const newPhoneList = [...phoneList];
		newPhoneList[index][name] = value;
		setPhoneList(newPhoneList);
	};

	const handleRemovePhone = (idx) => {
		const newPhoneList = [...phoneList];
		newPhoneList.splice(idx, 1);
		setPhoneList(newPhoneList);
	};

	//Webiste--------------------------------------
	const [websiteList, setWebsiteList] = useState([]);

	const handleAddWebsite = () => {
		setWebsiteList([...websiteList, ""]);
	};

	const handleWebisteChange = (e, index) => {
		const { name, value } = e.target;
		const newWebsiteList = [...websiteList];
		newWebsiteList[index] = value;
		setWebsiteList(newWebsiteList);
	};

	const handleRemoveWebsite = (idx) => {
		const newWebsiteList = [...websiteList];
		newWebsiteList.splice(idx, 1);
		setWebsiteList(newWebsiteList);
	};

	//Address---------------------------------------
	const [addressList, setAddressList] = useState([]);

	const handleAddAddress = () => {
		setAddressList([
			...addressList,
			{
				address_1: "",
				address_2: "",
				city_town: "",
				state_province_region: "",
				zip_postal_code: "",
				country: "",
				address_type: "",
				stateList: [],
			},
		]);
	};

	const handleAddressChange = (e, index) => {
		const { name, value } = e.target;
		const newAddressList = [...addressList];
		newAddressList[index][name] = value;
		setAddressList(newAddressList);
	};

	const handleRemoveAddress = (idx) => {
		const newAddressList = [...addressList];
		newAddressList.splice(idx, 1);
		setAddressList(newAddressList);
	};

	const [addBtnStatus, setAddBtnStatus] = useState(false);

	const handleCreatePeople = async (e) => {
		try {
			e.preventDefault();

			setAddBtnStatus(true);

			addressList.forEach((add) => {
				delete add.stateList;
			});

			let peopleFormData = new FormData();

			peopleFormData.append("profile", infoObj.profile);
			peopleFormData.append("public", infoObj.public);
			peopleFormData.append("prefix", infoObj.prefix);
			peopleFormData.append("first_name", infoObj.first_name);
			peopleFormData.append("middle_name", infoObj.middle_name);
			peopleFormData.append("last_name", infoObj.last_name);
			peopleFormData.append("suffix", infoObj.suffix);
			peopleFormData.append("biography", infoObj.biography);
			peopleFormData.append(
				"organizations",
				JSON.stringify(orgList.map((o) => o.value))
			);
			peopleFormData.append("roles", JSON.stringify(roleList));
			peopleFormData.append("jobTitles", JSON.stringify(jobList));
			peopleFormData.append("emails", JSON.stringify(emailList));
			peopleFormData.append("phones", JSON.stringify(phoneList));
			peopleFormData.append("websites", JSON.stringify(websiteList));
			peopleFormData.append("addresses", JSON.stringify(addressList));

			const config = {
				"Content-Type": "multipart/form-data",
			};
			await createPerson(peopleFormData, config).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/people");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	// console.log("infoObj", infoObj);
	// console.log("roleList", roleList);
	// console.log("orgList", orgList);
	// console.log("jobList", jobList);
	// console.log("emailList", emailList);
	// console.log("phoneList", phoneList);
	// console.log("websiteList", websiteList);
	// console.log("addressList", addressList);
	// console.log("countryList", countryList);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create People"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create People
					</Highlight>
				</Heading>

				<Button size="xs" onClick={() => navigate("/people")}>
					Cancel
				</Button>
			</HStack>

			<form action="submit" onSubmit={handleCreatePeople}>
				<VStack gap={1} align={"stretch"} pb={10}>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						// my={2}
						pb={5}
					>
						<Badge>Information</Badge>
						<Divider />
						<VStack py={2} gap={2} mt={5}>
							<HStack gap={5} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Public</FormLabel>
									<ChakraSelect
										name="public"
										placeholder="Select option"
										// size={"sm"}
										onChange={handleInfoChange}
									>
										<option value="Yes">Yes</option>
										<option value="No">No</option>
									</ChakraSelect>
								</FormControl>
								<FormControl>
									<FormLabel>Profile image</FormLabel>
									<Input
										pt={1}
										name="profile"
										type="file"
										// size={"sm"}
										accept=".jpg,.png,.webp,.jpeg"
										onChange={async (e) => {
											let img = e.target.files[0];
											setInfoObj((prev) => {
												return {
													...prev,
													profile: img,
												};
											});
										}}
									/>
								</FormControl>
							</HStack>
							<HStack gap={5} w={"full"} px={5}>
								<FormControl w={"25%"}>
									<FormLabel>Salutation</FormLabel>
									<ChakraSelect
										name="prefix"
										placeholder="Select prefix"
										// size={"sm"}
										onChange={handleInfoChange}
									>
										{salutationList.map((px, idx) => (
											<option value={px} key={idx}>
												{px}
											</option>
										))}
									</ChakraSelect>
								</FormControl>
							</HStack>
							<HStack gap={5} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>First Name</FormLabel>
									<Input
										type="text"
										// size={"sm"}
										name="first_name"
										onChange={handleInfoChange}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Middle Name</FormLabel>
									<Input
										type="text"
										// size={"sm"}
										name="middle_name"
										onChange={handleInfoChange}
									/>
								</FormControl>
							</HStack>
							<HStack gap={5} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Last Name</FormLabel>
									<Input
										type="text"
										// size={"sm"}
										name="last_name"
										onChange={handleInfoChange}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Suffix</FormLabel>
									<Input
										type="text"
										// size={"sm"}
										name="suffix"
										onChange={handleInfoChange}
									/>
								</FormControl>
							</HStack>
							<VStack gap={5} w={"full"} px={5}>
								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Biography</FormLabel>
									<RichTextEditor
										value={infoObj.biography}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, biography: e };
											});
										}}
										id="rte"
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Organizations (Multi Select)</FormLabel>
									<Creatable
										isMulti
										placeholder="Search or Select multiple organizations"
										value={orgList}
										options={orgsList?.map((i) => {
											return { value: i.id, label: i.organization_name };
										})}
										id="organizations"
										onChange={(e) => {
											handleOrgList(e);
										}}
										onCreateOption={() => {
											setCreateOrgModal(true);
										}}
										isLoading={createOrgModal}
										isDisabled={createOrgModal}
									/>
								</FormControl>
								<FormControl>
									<FormLabel>Roles (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple roles"
										options={rolesList?.map((i) => {
											return { value: i.id, label: i.role_name };
										})}
										onChange={(e) => {
											handleRoleList(e);
										}}
									/>
								</FormControl>
							</VStack>
						</VStack>
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Email</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddEmail}
							/>
						</HStack>
						<Divider />
						{emailList && emailList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{emailList?.map((em, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Email Address</FormLabel>
											<Input
												name="email_address"
												type="email"
												placeholder="Email Address"
												size={"sm"}
												value={em.email_address}
												onChange={(e) => {
													handleEmailChange(e, idx);
												}}
											/>
										</FormControl>
										<FormControl isRequired>
											<FormLabel> Email Type</FormLabel>
											<ChakraSelect
												name="email_type"
												placeholder="Type"
												size={"sm"}
												value={em.email_type}
												onChange={(e) => {
													handleEmailChange(e, idx);
												}}
											>
												<option value="Personal">Personal</option>
												<option value="Work">Work</option>
												<option value="Other">Other</option>
											</ChakraSelect>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveEmail(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Job Title</Badge>
							<IconButton size="xs" icon={<AddIcon />} onClick={handleAddJob} />
						</HStack>
						<Divider />
						{jobList && jobList.length > 0 && (
							<TableContainer mt={5} justifyContent="center">
								<Table size={"sm"} variant="simple">
									<Thead>
										<Tr>
											<Th>Title</Th>
											<Th>Organization</Th>
											<Th>Start Date</Th>
											<Th>End Date</Th>
											<Th>Current</Th>
											<Th>Visibility</Th>
											<Th>Action</Th>
										</Tr>
									</Thead>
									<Tbody>
										{jobList?.map((jb, idx) => (
											<Tr key={idx}>
												<Td>
													<Input
														name="job_title"
														value={jb.job_title}
														placeholder="Job Title"
														size={"sm"}
														onChange={(e) => {
															handleJobChange(e, idx);
														}}
													/>
												</Td>
												<Td>
													<ChakraSelect
														name="organization_id"
														value={jb.organization_id}
														placeholder="Select Org"
														size={"sm"}
														onChange={(e) => {
															handleJobChange(e, idx);
														}}
													>
														{orgsList?.map((org) => (
															<option key={org.id} value={org.id}>
																{org.organization_name}
															</option>
														))}
													</ChakraSelect>
												</Td>
												<Td>
													<Input
														name="start_date"
														value={jb.start_date}
														type="date"
														size={"sm"}
														onChange={(e) => {
															handleJobChange(e, idx);
														}}
													/>
												</Td>
												<Td>
													<Input
														name="end_date"
														value={jb.end_date}
														type="date"
														size={"sm"}
														disabled={jb.current === "Yes"}
														onChange={(e) => {
															handleJobChange(e, idx);
														}}
													/>
												</Td>
												<Td>
													<ChakraSelect
														name="current"
														value={jb.current}
														placeholder="Y/N"
														size={"sm"}
														onChange={(e) => {
															jb.end_date = "";
															handleJobChange(e, idx);
														}}
													>
														<option value="Yes">Yes</option>
														<option value="No">No</option>
													</ChakraSelect>
												</Td>
												<Td>
													<ChakraSelect
														name="visibility"
														value={jb.visibility}
														placeholder="Y/N"
														size={"sm"}
														onChange={(e) => {
															handleJobChange(e, idx);
														}}
													>
														<option value="Yes">Yes</option>
														<option value="No">No</option>
													</ChakraSelect>
												</Td>
												<Td>
													<IconButton
														variant="outline"
														size="sm"
														icon={<DeleteIcon />}
														onClick={() => handleRemoveJob(idx)}
													/>
												</Td>
											</Tr>
										))}
									</Tbody>
								</Table>
							</TableContainer>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Phone</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddPhone}
							/>
						</HStack>
						<Divider />
						{phoneList && phoneList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{phoneList?.map((ph, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Phone Number</FormLabel>
											<Input
												name="phone_number"
												value={ph.phone_number}
												type="number"
												placeholder="Phone Number"
												size={"sm"}
												onChange={(e) => {
													handlePhoneChange(e, idx);
												}}
											/>
										</FormControl>
										<FormControl isRequired>
											<FormLabel>Phone Type</FormLabel>
											<ChakraSelect
												name="phone_type"
												value={ph.phone_type}
												placeholder="Type"
												size={"sm"}
												onChange={(e) => {
													handlePhoneChange(e, idx);
												}}
											>
												<option value="Personal">Personal</option>
												<option value="Work">Work</option>
												<option value="Other">Other</option>
											</ChakraSelect>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemovePhone(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Website</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddWebsite}
							/>
						</HStack>
						<Divider />
						{websiteList && websiteList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{websiteList?.map((wb, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Url</FormLabel>
											<Input
												name="webiste_url"
												value={wb}
												type="url"
												placeholder="https://example.com"
												size={"sm"}
												onChange={(e) => {
													handleWebisteChange(e, idx);
												}}
											/>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveWebsite(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Address</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddAddress}
							/>
						</HStack>
						<Divider />
						{addressList && addressList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{addressList?.map((ad, idx) => (
									<HStack w={"full"} px={5} pb={8} key={idx}>
										<VStack key={idx} gap={2} pr={5} w={"full"}>
											<Divider />
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>Address 1</FormLabel>
													<Input
														name="address_1"
														type="text"
														size={"sm"}
														value={ad.address_1}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl>
													<FormLabel>Address 2</FormLabel>
													<Input
														name="address_2"
														type="text"
														size={"sm"}
														value={ad.address_2}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
											</HStack>
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>Country</FormLabel>
													<ChakraSelect
														name="country"
														type="text"
														placeholder="Select Country"
														size={"sm"}
														value={ad.country}
														onChange={(e) => {
															const cName = e.target.value;
															const newAddressList = [...addressList];
															let country = countryList.find(
																(i) => i.country_name === cName
															);
															newAddressList[idx]["country"] =
																country.country_name;
															newAddressList[idx]["stateList"] = country.states;
															setAddressList(newAddressList);
														}}
													>
														{countryList?.map((c) => (
															<option key={c.country_id} value={c.country_name}>
																{c.country_name}
															</option>
														))}
													</ChakraSelect>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>State/Province/Region</FormLabel>
													<ChakraSelect
														name="state_province_region"
														type="text"
														placeholder="Select State/Province/Region"
														size={"sm"}
														value={ad.state_province_region}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													>
														{ad.stateList?.map((s) => (
															<option key={s.state_id} value={s.state_name}>
																{s.state_name}
															</option>
														))}
													</ChakraSelect>
												</FormControl>
											</HStack>
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>City/Town</FormLabel>
													<Input
														name="city_town"
														type="text"
														size={"sm"}
														value={ad.city_town}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>Zip/Postal Code</FormLabel>
													<Input
														name="zip_postal_code"
														type="text"
														size={"sm"}
														value={ad.zip_postal_code}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>Address Type</FormLabel>
													<ChakraSelect
														name="address_type"
														placeholder="select one"
														size={"sm"}
														value={ad.address_type}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													>
														<option value="Personal">Personal</option>
														<option value="Work">Work</option>
														<option value="Other">Other</option>
													</ChakraSelect>
												</FormControl>
											</HStack>
										</VStack>
										<Divider height="200px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveAddress(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button size="xs" onClick={() => navigate("/people")}>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={addBtnStatus}
							loadingText="Adding people"
						>
							Add Person
						</Button>
					</HStack>
				</VStack>
				<QuickCreateOrgModal
					isOpen={createOrgModal}
					onClose={() => {
						setCreateOrgModal(false);
					}}
					onSuccess={async (org) => {
						const formattedOrg = {
							value: org.id,
							label: org.organization_name,
						};
						setOrgsList([...orgsList, org]);
						setOrgList([...orgList, formattedOrg]);
						toastMsg({
							title: "Success!",
							desc: "Created organization successfully",
							type: "success",
						});
						setCreateOrgModal(false);
						return;
					}}
				/>
			</form>
		</Navigation>
	);
};

export default CreatePeople;
