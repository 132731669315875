export const getFormattedDateStr = (date) => {
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");

	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

export const isValidFileName = (fileName) => {
	const chars = fileName.split("");
	for (let char of chars) {
		if (char.charCodeAt(0) >= 0 && char.charCodeAt <= 31)
			return `${char} is not allowed in filename`;
		if (
			char === "/" ||
			char === "\\" ||
			char === "(" ||
			char === ")" ||
			char === "*" ||
			char === "+"
		)
			return `${char} is not allowed in filename`;
	}
	return "";
};
