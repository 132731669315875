import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	IconButton,
	Text,
	VStack,
	FormControl,
	FormLabel,
	Input,
	InputLeftAddon,
	InputGroup,
} from "@chakra-ui/react";
import { AccountContext } from "contexts/AccountContext";
import useFetch from "hooks/useFetch";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { POST } from "utils/api";
import EditAward from "./EditAward";

const Awards = ({ financeData, refetch }) => {
	const { toastMsg } = useContext(AccountContext);
	const { data: sponsors } = useFetch(
		"/api/researchTask/getResearchTaskSponsors"
	);
	const [isEditing, setEditing] = useState(false);
	const [awardObj, setAwardObj] = useState({});
	const [addBtnStatus, setAddBtnStatus] = useState(false);

	const addAwardIncrement = async (body) => {
		const data = POST("/api/finance/awards", {
			...body,
			financeId: financeData.id,
		});
		return data;
	};

	const toggleInfoEdit = () => {
		setEditing(!isEditing);
	};

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setAwardObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//core--------------------------------
	const [coreList, setCoreList] = useState([]);

	const handleCoreList = (clist) => {
		let coreL = [];
		clist.forEach((c) => {
			coreL.push(c.value);
		});
		setAwardObj((prev) => {
			return {
				...prev,
				cores: coreL,
			};
		});
	};

	//sponsors-----------------------------
	const [sponsorList, setSponsorList] = useState([]);

	const handleSprList = (slist) => {
		let sprL = [];
		slist.forEach((s) => {
			sprL.push(s.value);
		});
		setAwardObj((prev) => {
			return {
				...prev,
				sponsors: sprL,
			};
		});
	};

	useEffect(() => {
		setAwardObj({
			cores: [],
			sponsors: [],
			awardDate: "",
			totalAwardAmount: 0,
			incrementCount: financeData.awards.length,
		});
	}, [isEditing === false]);

	const handleSubmitincrement = async (e) => {
		try {
			e.preventDefault();
			setAddBtnStatus(true);
			await addAwardIncrement(awardObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				refetch();
				setAddBtnStatus(false);
				setAwardObj({});
				toggleInfoEdit();
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			setAwardObj({});
			toggleInfoEdit();
			return;
		}
	};

	return (
		<VStack gap={1} align="stretch" pb={20}>
			<Box
				padding={"15px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Awards
						</Heading>
					</Badge>
					<HStack>
						{isEditing === false ? (
							<Button
								size={"xs"}
								leftIcon={<AddIcon />}
								onClick={toggleInfoEdit}
							>
								Increment
							</Button>
						) : (
							<IconButton
								size="xs"
								icon={<CloseIcon />}
								onClick={() => {
									toggleInfoEdit();
								}}
							/>
						)}
					</HStack>
				</HStack>
				<Divider />
				<br />
				{isEditing === false ? (
					<VStack align={"stretch"} justifyContent="flex-start">
						<Text pt={1}>
							Total amount awarded:{" "}
							<Badge colorScheme="green">
								$
								{financeData.awards.reduce(
									(acc, curr) => acc + curr.totalAwardAmount,
									0
								)}
								.00
							</Badge>
						</Text>
						<Divider />
						<VStack gap={0}>
							{financeData.awards?.map((awd, idx) => (
								<EditAward
									awd={awd}
									key={idx}
									sponsorList={sponsors}
									refetch={refetch}
								/>
							))}
						</VStack>
					</VStack>
				) : (
					<form action="submit" onSubmit={handleSubmitincrement}>
						<VStack gap={4} w={"full"} px={5} pb={5}>
							<FormControl isRequired>
								<FormLabel>Sponsors (Multi Select)</FormLabel>
								<Select
									isMulti
									placeholder="Search or Select multiple sponsors"
									options={sponsors?.map((i) => {
										return { value: i.id, label: i.organization_name };
									})}
									onChange={(e) => {
										handleSprList(e);
									}}
								/>
							</FormControl>
							<FormControl isRequired>
								<FormLabel>Cores (Multi Select)</FormLabel>
								<Select
									isMulti
									placeholder="Select multiple cores"
									options={[
										{ value: "Core", label: "Core" },
										{
											value: "Non-Core",
											label: "Non-Core",
										},
									]}
									onChange={(e) => {
										handleCoreList(e);
									}}
								/>
							</FormControl>
							<HStack gap={5} w={"full"}>
								<FormControl isRequired>
									<FormLabel>Award Amount</FormLabel>
									<InputGroup>
										<InputLeftAddon children="$" />
										<Input
											name="totalAwardAmount"
											type="number"
											placeholder="Enter amount"
											onChange={handleInfoChange}
										/>
									</InputGroup>
								</FormControl>
								<FormControl isRequired>
									<FormLabel>Increment Number</FormLabel>
									<InputGroup>
										<InputLeftAddon children="#" />
										<Input
											value={awardObj.incrementCount}
											name="incrementCount"
											type="number"
											placeholder="Enter amount"
											onChange={handleInfoChange}
										/>
									</InputGroup>
								</FormControl>
								<FormControl>
									<FormLabel>Award Date</FormLabel>
									<Input
										type="date"
										name="awardDate"
										onChange={handleInfoChange}
									/>
								</FormControl>
							</HStack>
							<HStack gap={3} justifyContent={"center"} pt={5}>
								<Button
									size="xs"
									onClick={() => {
										toggleInfoEdit();
									}}
								>
									Cancel
								</Button>
								<Button
									size="xs"
									colorScheme="teal"
									type="submit"
									isLoading={addBtnStatus}
									loadingText="Adding Increment"
								>
									Add Increment
								</Button>
							</HStack>
						</VStack>
					</form>
				)}
			</Box>
		</VStack>
	);
};

export default Awards;
