import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
// import PublicationsDatatable from "components/Datatable/PublicationsDatatable";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

function PublicationTypes() {
	const { data, loading, error } = useFetch("/api/publications/categories");

	const navigate = useNavigate();
	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting publications types data</p>;
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Category",
						data: "category",
					},
				]}
				theaders={["ID", "Category"]}
				navigatePath="/publication-types"
				tableId="viewPubTypes"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Publication Types"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Publication Types
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button
						size="xs"
						onClick={() => {
							navigate("/publication-types/create");
						}}
					>
						Create
					</Button>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
}
export default PublicationTypes;
