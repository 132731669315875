import React, { useEffect, useState, useContext } from "react";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Highlight,
	HStack,
	Input,
	VStack,
	Select as ChakraSelect,
	IconButton,
	Divider,
} from "@chakra-ui/react";
import Navigation from "../../../components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import Select from "react-select";
import { getBaseUrl } from "../../../base";
import { AccountContext } from "contexts/AccountContext";

const CreateProjects = () => {
	const navigate = useNavigate();
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [peopleList, setPeopleList] = useState([]);
	const [rpList, setRP] = useState([]);
	const [tList, setT] = useState([]);

	//func to get all tag
	const getAllTag = async () => {
		let url = `${getBaseUrl()}/api/tag/getAllTags`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to get all tag
	const getAllResearchPrograms = async () => {
		let url = `${getBaseUrl()}/api/researchPrograms/getAllResearchPrograms`;
		const { data } = await apiCall(url);
		return data;
	};

	// func to get all people
	const getAllPeople = async () => {
		let url = `${getBaseUrl()}/api/people/getAllPeopleWithOrg`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to create project
	const createProject = async (projetObj) => {
		let url = `${getBaseUrl()}/api/projects/createProject`;
		const { data } = await apiCall(url, "POST", projetObj);
		return data;
	};

	useEffect(() => {
		getAllTag().then((res) => {
			setT(res);
		});
	}, []);

	useEffect(() => {
		getAllResearchPrograms().then((res) => {
			setRP(res);
		});
	}, []);

	useEffect(() => {
		getAllPeople().then((res) => {
			setPeopleList(res);
		});
	}, []);

	const [infoObj, setInfoObj] = useState({
		project_title: "",
		project_unique_id: "",
		abstract: "",
		deliverable_summary: "",
		transition: "",
		is_public: "",
		centers: [],
	});

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//Research Programs-----------------------------
	const [researchProgramList, setRPList] = useState([]);

	const handleResearchProgramList = (plist) => {
		let rpL = [];
		plist.forEach((p) => {
			rpL.push(p.value);
		});
		setRPList(rpL);
	};

	//Principal Investigator-----------------------------
	const [principalList, setPiList] = useState([]);

	const handlePrincipalInvestigatorList = (plist) => {
		let piL = [];
		plist.forEach((p) => {
			piL.push(p.value);
		});
		setPiList(piL);
	};

	//Co-Principal Investigator-----------------------------
	const [coPrincipalList, setCoPiList] = useState([]);

	const handleCoPrincipalInvestigatorList = (plist) => {
		let copiL = [];
		plist.forEach((p) => {
			copiL.push(p.value);
		});
		setCoPiList(copiL);
	};

	//Tag-----------------------------
	const [tagList, setTagList] = useState([]);

	const handlesetTagList = (plist) => {
		let tL = [];
		plist.forEach((p) => {
			tL.push(p.value);
		});
		setTagList(tL);
	};

	const [addBtnStatus, setAddBtnStatus] = useState(false);

	// console.log("infoObj", infoObj);
	// console.log("researchProgramList", researchProgramList);
	// console.log("principalList", principalList);
	// console.log("coPrincipalList", coPrincipalList);
	// console.log("tagList", tagList);

	const handleCreateProject = async (e) => {
		try {
			e.preventDefault();
			setAddBtnStatus(true);

			let projectObj = {
				...infoObj,
				research_programs: researchProgramList,
				pi: principalList,
				copi: coPrincipalList,
				tags: tagList,
			};

			await createProject(projectObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/projects");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Projects"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Projects
					</Highlight>
				</Heading>

				<Button size="xs" onClick={() => navigate("/projects")}>
					Cancel
				</Button>
			</HStack>

			<form action="submit" onSubmit={handleCreateProject}>
				<VStack gap={1} align={"stretch"} pb={10}>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						// my={2}
						pb={5}
					>
						<Badge>Information</Badge>
						<Divider />
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Projet Title</FormLabel>
									<Input
										type="text"
										name="project_title"
										onChange={handleInfoChange}
									/>
								</FormControl>
								<HStack gap={5} w={"full"}>
									<FormControl>
										<FormLabel>Project Unique ID</FormLabel>
										<Input
											type="number"
											name="project_unique_id"
											onChange={handleInfoChange}
										/>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Public</FormLabel>
										<ChakraSelect
											name="is_public"
											placeholder="Select option"
											// size={"sm"}
											onChange={handleInfoChange}
										>
											<option value="Yes">Yes</option>
											<option value="No">No</option>
										</ChakraSelect>
									</FormControl>
									<FormControl isRequired>
										<FormLabel>Center</FormLabel>
										<Select
											isMulti
											placeholder="Select Center (Multi Select)"
											options={[
												{ value: "serc", label: "SERC" },
												{ value: "airc", label: "AIRC" },
											]}
											onChange={(e) => {
												let cList = [];
												e.forEach((c) => {
													cList.push(c.value);
												});
												setInfoObj((prev) => {
													return {
														...prev,
														centers: cList,
													};
												});
											}}
										/>
									</FormControl>
								</HStack>

								<FormControl>
									<FormLabel>Research Programs (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Research Programs"
										options={rpList?.map((i) => {
											return {
												value: i.id,
												label: i.program_name,
											};
										})}
										onChange={(e) => {
											handleResearchProgramList(e);
										}}
									/>
								</FormControl>

								<FormControl isRequired>
									<FormLabel>Principal Investigator (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Principal Investigator"
										options={peopleList?.map((i) => {
											return {
												value: i.id,
												label: `${i.first_name} ${i.last_name}`,
											};
										})}
										onChange={(e) => {
											handlePrincipalInvestigatorList(e);
										}}
									/>
								</FormControl>

								<FormControl>
									<FormLabel>
										Co-Principal Investigator (Multi Select)
									</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Co-Principal Investigator"
										options={peopleList?.map((i) => {
											return {
												value: i.id,
												label: `${i.first_name} ${i.last_name}`,
											};
										})}
										onChange={(e) => {
											handleCoPrincipalInvestigatorList(e);
										}}
									/>
								</FormControl>

								<FormControl>
									<FormLabel>Tag (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Tag"
										options={tList?.map((i) => {
											return {
												value: i.id,
												label: i.tag_title,
											};
										})}
										onChange={(e) => {
											handlesetTagList(e);
										}}
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }} isRequired>
									<FormLabel>Abstract</FormLabel>
									<RichTextEditor
										value={infoObj.abstract}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, abstract: e };
											});
										}}
										id="rte1"
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Deliverable Summary</FormLabel>
									<RichTextEditor
										value={infoObj.deliverable_summary}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, deliverable_summary: e };
											});
										}}
										id="rte2"
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Transition</FormLabel>
									<RichTextEditor
										value={infoObj.transition}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, transition: e };
											});
										}}
										id="rte3"
									/>
								</FormControl>
							</VStack>
						</VStack>
					</Box>

					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button size="xs" onClick={() => navigate("/projects")}>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={addBtnStatus}
							loadingText="Adding org"
						>
							Add Project
						</Button>
					</HStack>
				</VStack>
			</form>
		</Navigation>
	);
};

export default CreateProjects;
