import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
	Heading,
	HStack,
	Box,
	Divider,
	Highlight,
	Button,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
import { CSVLink } from "react-csv";
import { getFormattedDateStr } from "utils/helpers";

const TechnicalReports = () => {
	const { data, loading, error } = useFetch("/api/technicalReports");
	const navigate = useNavigate();

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Technical Reports data</p>;
		data.forEach((dt) => {
			dt.updatedAt = getFormattedDateStr(new Date(dt.updatedAt));
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Title",
						data: "title",
						width: "30%",
					},
					{
						className: "dt-body-left",
						title: "Report Number",
						data: "reportNumber",
					},
					{
						className: "dt-body-left",
						title: "Contract Number",
						data: "contractNumber",
					},
					{
						className: "dt-body-left",
						title: "Published Date",
						data: "publicationDate",
					},
					{
						className: "dt-body-left",
						title: "Period Start",
						data: "periodStart",
					},
					{
						className: "dt-body-left",
						title: "Period End",
						data: "periodEnd",
					},
					{
						className: "dt-body-left",
						title: "Updated On",
						data: "updatedAt",
					},
				]}
				theaders={[
					"ID",
					"Title",
					"Report Number",
					"Contract Number",
					"Published Date",
					"Period Start",
					"Period End",
					"Updated On",
				]}
				navigatePath="/technical-reports"
				tableId="viewTechnicalReports"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Technical Reports"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Technical Reports
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button
						size="xs"
						onClick={() => {
							navigate("/technical-reports/create");
						}}
					>
						Create
					</Button>
					<CSVLink
						data={data}
						headers={[
							{ label: "ID", key: "id" },
							{ label: "Title", key: "title" },
							{ label: "Report Number", key: "reportNumber" },
							{ label: "Contract Number", key: "contractNumber" },
							{ label: "Published Date", key: "publicationDate" },
							{ label: "Period Start", key: "periodStart" },
							{ label: "Period End", key: "periodEnd" },
							{ label: "Updated On", key: "updatedAt" },
						]}
						filename="SERC_Technical_Report_Data"
						target="_blank"
					>
						<Button size="xs">Export</Button>
					</CSVLink>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default TechnicalReports;
