import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import PublicationsDatatable from "components/Datatable/PublicationsDatatable";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";

function Publications() {
	const { data, loading, error } = useFetch(
		"/api/publications/getAllPublications"
	);
	const navigate = useNavigate();

	const [formatData, setFormatData] = useState([]);

	useEffect(() => {
		let formattedData = [];
		if (Array.isArray(data)) formattedData = [...data];
		formattedData?.forEach((dt) => {
			dt.updatedAt = new Date(dt.updatedAt).toLocaleDateString();
			if (Array.isArray(dt.primaryAuthors))
				dt.primaryAuthors = dt.primaryAuthors
					.map((a) => `${a.firstName} ${a.lastName}`)
					.join(", ");
			if (Array.isArray(dt.secondaryAuthors))
				dt.secondaryAuthors = dt.secondaryAuthors
					.map((a) => `${a.firstName} ${a.lastName}`)
					.join(", ");
			if (Array.isArray(dt.centers)) {
				dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
			}
		});
		setFormatData(formattedData);
	}, [data, loading, error]);

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting publications data</p>;
		return <PublicationsDatatable data={data} />;
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Publications"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Publications
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button
						size="xs"
						onClick={() => {
							navigate("/publications/create");
						}}
					>
						Create
					</Button>
					<CSVLink
						data={formatData}
						headers={[
							{ label: "ID", key: "id" },
							{ label: "Title", key: "title" },
							{ label: "Category", key: "category" },
							{ label: "Primary Authors", key: "primaryAuthors" },
							{ label: "Secondary Authors", key: "secondaryAuthors" },
							{ label: "Centers", key: "centers" },
							{ label: "Publication Date", key: "publicationDate" },
							{ label: "Updated On", key: "updatedAt" },
						]}
						filename="SERC_Publication_Data"
						target="_blank"
					>
						<Button size="xs">Export</Button>
					</CSVLink>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
}

export default Publications;
