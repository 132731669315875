import React, { useCallback } from "react";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";
import { getFormattedDateStr } from "utils/helpers";

const Projects = () => {
	const { data, loading, error } = useFetch("/api/projects/getAllProjects");
	const navigate = useNavigate();
	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Projects data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
			if (Array.isArray(dt.pi)) {
				dt.pi = dt.pi.map((p) => `${p.first_name} ${p.last_name}`).join(", ");
			}
			if (Array.isArray(dt.copi)) {
				dt.copi = dt.copi
					.map((c) => `${c.first_name} ${c.last_name}`)
					.join(", ");
			}
			if (Array.isArray(dt.centers)) {
				dt.centers = dt.centers.map((c) => c.toUpperCase()).join(", ");
			}
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Project Title",
						data: "project_title",
						width: "50%",
					},
					{
						className: "dt-body-left",
						title: "PI",
						data: "pi",
						width: "18%",
					},
					{
						className: "dt-body-left",
						title: "CO-PI",
						data: "copi",
						width: "18%",
					},
					{
						className: "dt-body-left",
						title: "Centers",
						data: "centers",
					},
					{
						className: "dt-body-left",
						width: "8%",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={[
					"ID",
					"Project Title",
					"PI",
					"CO-PI",
					"Centers",
					"Updated On",
				]}
				navigatePath="/projects"
				tableId="viewProject"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Projects"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Projects
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button size="xs" onClick={() => navigate("/projects/create")}>
						Create
					</Button>
					<CSVLink
						data={data}
						headers={[
							{ label: "ID", key: "id" },
							{ label: "Project Title", key: "project_title" },
							{ label: "PI", key: "pi" },
							{ label: "CO-PI", key: "copi" },
							{ label: "Centers", key: "centers" },
							{ label: "Updated On", key: "updated_at" },
						]}
						filename="SERC_Project_Data"
						target="_blank"
					>
						<Button size="xs">Export</Button>
					</CSVLink>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default Projects;
