import React, {
	useEffect,
	useState,
	useContext,
	useRef,
	useCallback,
} from "react";
import { useParams } from "react-router-dom";
import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Image,
	Divider,
	Icon,
} from "@chakra-ui/react";
import { CgOrganisation } from "react-icons/cg";
import { BsFillPeopleFill } from "react-icons/bs";
import { MdPublish } from "react-icons/md";
import { GiArchiveResearch } from "react-icons/gi";
import { AccountContext } from "contexts/AccountContext";
import { getBaseUrl } from "../../../base";
import Navigation from "../../../components/Layout/Navigation";
import Profile from "./ProfileTab/Profile";
import PeopleTab from "./PeopleTab";
import ResearchTaskTab from "./ResearchTaskTab";
import PublicationTab from "./PublicationTab";
import FileNameInput from "components/FileNameInput";

const OrgsProfile = () => {
	const { id } = useParams();
	const [orgProfile, setOrgProfile] = useState({});
	const [orgType, setOrgType] = useState("");
	const { toastMsg, apiCall } = useContext(AccountContext);

	//func to get single org profile
	const getOrgProfile = async (orgID) => {
		let url = `${getBaseUrl()}/api/org/getOrgInfo/${orgID}`;
		const { data } = await apiCall(url);
		setOrgProfile(data);
		return;
	};

	useEffect(() => {
		getOrgProfile(id);
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const getOrgType = async (orgProfile) => {
			let url = `${getBaseUrl()}/api/org/getOrgTypeById/${
				orgProfile.organization_type_id
			}`;
			await apiCall(url).then((res) => {
				setOrgType(res.data);
				return;
			});
		};
		if (orgProfile.organization_type_id !== null) {
			getOrgType(orgProfile);
		}
	}, [orgProfile]);

	return (
		<Navigation>
			<Tabs
				variant="soft-rounded"
				variantcolor="brand.100"
				orientation="vertical"
				size={"sm"}
			>
				<TabList gap={5} maxWidth={"200px"} minWidth={"200px"}>
					{orgProfile.image != null ? (
						<>
							<Image
								borderRadius="3xl"
								boxShadow="xl"
								src={`${orgProfile.image}?time=${new Date().getTime()}`}
								alt={orgProfile.organization_name}
							/>
							<FileNameInput
								fileName={orgProfile.imageFilename}
								endpoint={`/api/org/renameImage/${orgProfile.id}`}
								onSuccess={(res) => {
									setOrgProfile(res.org);
								}}
							/>
						</>
					) : (
						<></>
					)}

					<Divider />
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={CgOrganisation} /> Org Profile
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={BsFillPeopleFill} /> People
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={GiArchiveResearch} /> Research Task
					</Tab>
					<Tab justifyContent={"left"}>
						<Icon mr="4" ml="2" as={MdPublish} /> Publications
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel py={0} pr={0}>
						<Profile
							id={id}
							getOrgProfile={getOrgProfile}
							orgProfile={orgProfile}
							orgType={orgType}
						/>
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<PeopleTab orgProfile={orgProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<ResearchTaskTab orgProfile={orgProfile} />
					</TabPanel>
					<TabPanel py={0} pr={0}>
						<PublicationTab orgProfile={orgProfile} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Navigation>
	);
};

export default OrgsProfile;
