import React from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Divider,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react";

const WebsiteSec = ({ peopleProfile }) => {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Website
					</Heading>
				</Badge>
			</HStack>
			<Divider />
			<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
				<UnorderedList pl={10}>
					{peopleProfile.websites?.map((web) => (
						<ListItem key={web.id}>
							<Text>{web.website_url}</Text>
						</ListItem>
					))}
				</UnorderedList>
			</VStack>
		</>
	);
};

export default WebsiteSec;
