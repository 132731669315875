import React, { useEffect, useState, useContext } from "react";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Highlight,
	HStack,
	Input,
	VStack,
	Select as ChakraSelect,
	IconButton,
	Divider,
} from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";
import Select from "react-select";
import Navigation from "../../../components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { RichTextEditor } from "@mantine/rte";
import { useNavigate } from "react-router-dom";
import { getBaseUrl } from "../../../base";
import axios from "axios";
import countryList from "utils/data/Country-State.json";

const CreateOrgs = () => {
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [orgTypeList, setOrgTypeList] = useState([]);
	const [peopleList, setPeopleList] = useState([]);
	const [researchTaskList, setReserchTaskList] = useState([]);
	const navigate = useNavigate();

	//func to get all org types
	const getAllOrgType = async () => {
		let url = `${getBaseUrl()}/api/org/getAllOrgTypes`;
		const { data } = await apiCall(url);
		return data;
	};

	// func to get all people
	const getAllPeople = async () => {
		let url = `${getBaseUrl()}/api/people/getAllPeopleWithOrg`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to get all research task
	const getAllResearchTask = async () => {
		let url = `${getBaseUrl()}/api/researchTask/getAllResearchTasks`;
		const { data } = await apiCall(url);
		return data;
	};

	//func to create org
	const createOrg = async (orgFormData, config) => {
		let url = `${getBaseUrl()}/api/org/createOrg`;
		const { data } = await apiCall(url, "POST", orgFormData, config);
		return data;
	};

	useEffect(() => {
		getAllOrgType().then((res) => {
			setOrgTypeList(res);
		});
	}, []);

	useEffect(() => {
		getAllPeople().then((res) => {
			setPeopleList(res);
		});
	}, []);

	useEffect(() => {
		getAllResearchTask().then((res) => {
			setReserchTaskList(res);
		});
	}, []);

	//Information------------------------------
	const [infoObj, setInfoObj] = useState({
		organization_name: "",
		orgImg: "",
		org_name_short: "",
		org_id: "",
		organization_type_id: "",
		description: "",
		public: "",
	});

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setInfoObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	//People-----------------------------
	const [pList, setPList] = useState([]);

	const handlePeopleList = (plist) => {
		let peopleL = [];
		plist.forEach((p) => {
			peopleL.push(p.value);
		});
		setPList(peopleL);
	};

	//Research-------------------------------------
	const [rsList, setRSList] = useState([]);

	const handleResearchTaskList = (rlist) => {
		let researchL = [];
		rlist.forEach((r) => {
			researchL.push(r.value);
		});
		setRSList(researchL);
	};

	//Emails--------------------------------------
	const [emailList, setEmailList] = useState([
		// {
		// 	email_address: "",
		// 	email_type: "",
		// },
	]);

	const handleAddEmail = () => {
		setEmailList([
			...emailList,
			{
				email_address: "",
				email_type: "",
			},
		]);
	};

	const handleEmailChange = (e, index) => {
		const { name, value } = e.target;
		const newEmailList = [...emailList];
		newEmailList[index][name] = value;
		setEmailList(newEmailList);
	};

	const handleRemoveEmail = (idx) => {
		const newEmailList = [...emailList];
		newEmailList.splice(idx, 1);
		setEmailList(newEmailList);
	};

	//Phones--------------------------------------
	const [phoneList, setPhoneList] = useState([]);

	const handleAddPhone = () => {
		setPhoneList([
			...phoneList,
			{
				phone_number: "",
				phone_type: "",
			},
		]);
	};

	const handlePhoneChange = (e, index) => {
		const { name, value } = e.target;
		const newPhoneList = [...phoneList];
		newPhoneList[index][name] = value;
		setPhoneList(newPhoneList);
	};

	const handleRemovePhone = (idx) => {
		const newPhoneList = [...phoneList];
		newPhoneList.splice(idx, 1);
		setPhoneList(newPhoneList);
	};

	//Webiste--------------------------------------
	const [websiteList, setWebsiteList] = useState([]);

	const handleAddWebsite = () => {
		setWebsiteList([...websiteList, ""]);
	};

	const handleWebisteChange = (e, index) => {
		const { name, value } = e.target;
		const newWebsiteList = [...websiteList];
		newWebsiteList[index] = value;
		setWebsiteList(newWebsiteList);
	};

	const handleRemoveWebsite = (idx) => {
		const newWebsiteList = [...websiteList];
		newWebsiteList.splice(idx, 1);
		setWebsiteList(newWebsiteList);
	};

	//Address---------------------------------------
	const [addressList, setAddressList] = useState([]);

	const handleAddAddress = () => {
		setAddressList([
			...addressList,
			{
				address_1: "",
				address_2: "",
				city_town: "",
				state_province_region: "",
				zip_postal_code: "",
				country: "",
				address_type: "",
				stateList: [],
			},
		]);
	};

	const handleAddressChange = (e, index) => {
		const { name, value } = e.target;
		const newAddressList = [...addressList];
		newAddressList[index][name] = value;
		setAddressList(newAddressList);
	};

	const handleRemoveAddress = (idx) => {
		const newAddressList = [...addressList];
		newAddressList.splice(idx, 1);
		setAddressList(newAddressList);
	};

	const [addBtnStatus, setAddBtnStatus] = useState(false);

	const handleCreateOrg = async (e) => {
		try {
			e.preventDefault();
			setAddBtnStatus(true);

			addressList.forEach((add) => {
				delete add.stateList;
			});

			let orgFormData = new FormData();
			orgFormData.append("organization_name", infoObj.organization_name);
			orgFormData.append("orgImg", infoObj.orgImg);
			orgFormData.append("org_name_short", infoObj.org_name_short);
			orgFormData.append("org_id", infoObj.org_id);
			orgFormData.append("organization_type_id", infoObj.organization_type_id);
			orgFormData.append("description", infoObj.description);
			orgFormData.append("public", infoObj.public);
			orgFormData.append("emails", JSON.stringify(emailList));
			orgFormData.append("phones", JSON.stringify(phoneList));
			orgFormData.append("websites", JSON.stringify(websiteList));
			orgFormData.append("addresses", JSON.stringify(addressList));
			orgFormData.append("researchTasks", JSON.stringify(rsList));
			orgFormData.append("people", JSON.stringify(pList));

			const config = {
				"Content-Type": "multipart/form-data",
			};

			await createOrg(orgFormData, config).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/organizations");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
			return;
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	// console.log("infoObj", infoObj);
	// console.log("emailList", emailList);
	// console.log("phoneList", phoneList);
	// console.log("websiteList", websiteList);
	// console.log("addressList", addressList);
	// console.log("ResearchTask", rsList);
	// console.log("PeopleList", pList);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Organizations"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Organizations
					</Highlight>
				</Heading>

				<Button size="xs" onClick={() => navigate("/organizations")}>
					Cancel
				</Button>
			</HStack>

			<form action="submit" onSubmit={handleCreateOrg}>
				<VStack gap={1} align={"stretch"} pb={10}>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						// my={2}
						pb={5}
					>
						<Badge>Information</Badge>
						<Divider />
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Organization Name</FormLabel>
									<Input
										type="text"
										name="organization_name"
										onChange={handleInfoChange}
									/>
								</FormControl>
								<HStack gap={5} w={"full"}>
									<FormControl>
										<FormLabel>Organization (Short)</FormLabel>
										<Input
											type="text"
											name="org_name_short"
											onChange={handleInfoChange}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Organization ID (Number)</FormLabel>
										<Input
											type="number"
											name="org_id"
											onChange={handleInfoChange}
										/>
									</FormControl>
								</HStack>
								<HStack gap={5} w={"full"}>
									<FormControl isRequired>
										<FormLabel>Organization Type</FormLabel>
										<ChakraSelect
											name="organization_type_id"
											placeholder="Select Organization Type"
											onChange={handleInfoChange}
										>
											{orgTypeList.map((orgT, idx) => (
												<option value={orgT.id} key={idx}>
													{orgT.type_name}
												</option>
											))}
										</ChakraSelect>
									</FormControl>
									<FormControl>
										<FormLabel>Organization Image</FormLabel>
										<Input
											pt={1}
											name="image"
											type="file"
											// size={"sm"}
											accept=".jpg,.png,.webp,.jpeg"
											onChange={async (e) => {
												let img = e.target.files[0];
												setInfoObj((prev) => {
													return {
														...prev,
														orgImg: img,
													};
												});
											}}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>Public</FormLabel>
										<ChakraSelect
											name="public"
											placeholder="Select option"
											onChange={handleInfoChange}
										>
											<option value="Yes">Yes</option>
											<option value="No">No</option>
										</ChakraSelect>
									</FormControl>
								</HStack>

								<FormControl>
									<FormLabel>People (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple people"
										options={peopleList?.map((i) => {
											return {
												value: i.id,
												label: `${i.first_name} ${i.last_name}`,
											};
										})}
										onChange={(e) => {
											handlePeopleList(e);
										}}
									/>
								</FormControl>

								<FormControl>
									<FormLabel>Research Tasks (Multi Select)</FormLabel>
									<Select
										isMulti
										placeholder="Search or Select multiple Research Task"
										options={researchTaskList?.map((i) => {
											return {
												value: i.id,
												label: i.task_name,
											};
										})}
										onChange={(e) => {
											handleResearchTaskList(e);
										}}
									/>
								</FormControl>

								<FormControl style={{ zIndex: 0 }}>
									<FormLabel>Description</FormLabel>
									<RichTextEditor
										value={infoObj.description}
										onChange={(e) => {
											setInfoObj((prev) => {
												return { ...prev, description: e };
											});
										}}
										id="rte"
									/>
								</FormControl>
							</VStack>
						</VStack>
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Email</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddEmail}
							/>
						</HStack>
						<Divider />
						{emailList && emailList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{emailList?.map((em, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Email Address</FormLabel>
											<Input
												name="email_address"
												type="email"
												placeholder="Email Address"
												size={"sm"}
												value={em.email_address}
												onChange={(e) => {
													handleEmailChange(e, idx);
												}}
											/>
										</FormControl>
										<FormControl isRequired>
											<FormLabel> Email Type</FormLabel>
											<ChakraSelect
												name="email_type"
												placeholder="Type"
												size={"sm"}
												value={em.email_type}
												onChange={(e) => {
													handleEmailChange(e, idx);
												}}
											>
												<option value="Personal">Personal</option>
												<option value="Work">Work</option>
												<option value="Other">Other</option>
											</ChakraSelect>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveEmail(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Phone</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddPhone}
							/>
						</HStack>
						<Divider />
						{phoneList && phoneList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{phoneList?.map((ph, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Phone Number</FormLabel>
											<Input
												name="phone_number"
												value={ph.phone_number}
												type="number"
												placeholder="Phone Number"
												size={"sm"}
												onChange={(e) => {
													handlePhoneChange(e, idx);
												}}
											/>
										</FormControl>
										<FormControl isRequired>
											<FormLabel>Phone Type</FormLabel>
											<ChakraSelect
												name="phone_type"
												value={ph.phone_type}
												placeholder="Type"
												size={"sm"}
												onChange={(e) => {
													handlePhoneChange(e, idx);
												}}
											>
												<option value="Personal">Personal</option>
												<option value="Work">Work</option>
												<option value="Other">Other</option>
											</ChakraSelect>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemovePhone(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Website</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddWebsite}
							/>
						</HStack>
						<Divider />
						{websiteList && websiteList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{websiteList?.map((wb, idx) => (
									<HStack gap={5} w={"full"} px={5} key={idx}>
										<FormControl isRequired>
											<FormLabel>Url</FormLabel>
											<Input
												name="webiste_url"
												value={wb}
												type="url"
												placeholder="https://example.com"
												size={"sm"}
												onChange={(e) => {
													handleWebisteChange(e, idx);
												}}
											/>
										</FormControl>
										<Divider height="50px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveWebsite(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
					>
						<HStack justifyContent={"space-between"}>
							<Badge>Address</Badge>
							<IconButton
								size="xs"
								icon={<AddIcon />}
								onClick={handleAddAddress}
							/>
						</HStack>
						<Divider />
						{addressList && addressList.length > 0 && (
							<VStack py={2} gap={2} mt={5}>
								{addressList?.map((ad, idx) => (
									<HStack w={"full"} px={5} pb={8} key={idx}>
										<VStack key={idx} gap={2} pr={5} w={"full"}>
											<Divider />
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>Address 1</FormLabel>
													<Input
														name="address_1"
														type="text"
														size={"sm"}
														value={ad.address_1}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl>
													<FormLabel>Address 2</FormLabel>
													<Input
														name="address_2"
														type="text"
														size={"sm"}
														value={ad.address_2}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
											</HStack>
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>Country</FormLabel>
													<ChakraSelect
														name="country"
														type="text"
														placeholder="Select Country"
														size={"sm"}
														value={ad.country}
														onChange={(e) => {
															const cName = e.target.value;
															const newAddressList = [...addressList];
															let country = countryList.find(
																(i) => i.country_name === cName
															);
															newAddressList[idx]["country"] =
																country.country_name;
															newAddressList[idx]["stateList"] = country.states;
															setAddressList(newAddressList);
														}}
													>
														{countryList?.map((c) => (
															<option key={c.country_id} value={c.country_name}>
																{c.country_name}
															</option>
														))}
													</ChakraSelect>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>State/Province/Region</FormLabel>
													<ChakraSelect
														name="state_province_region"
														type="text"
														placeholder="Select State/Province/Region"
														size={"sm"}
														value={ad.state_province_region}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													>
														{ad.stateList?.map((s) => (
															<option key={s.state_id} value={s.state_name}>
																{s.state_name}
															</option>
														))}
													</ChakraSelect>
												</FormControl>
											</HStack>
											<HStack gap={5} w={"full"}>
												<FormControl isRequired>
													<FormLabel>City/Town</FormLabel>
													<Input
														name="city_town"
														type="text"
														size={"sm"}
														value={ad.city_town}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>Zip/Postal Code</FormLabel>
													<Input
														name="zip_postal_code"
														type="text"
														size={"sm"}
														value={ad.zip_postal_code}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													/>
												</FormControl>
												<FormControl isRequired>
													<FormLabel>Address Type</FormLabel>
													<ChakraSelect
														name="address_type"
														placeholder="select one"
														size={"sm"}
														value={ad.address_type}
														onChange={(e) => {
															handleAddressChange(e, idx);
														}}
													>
														<option value="Personal">Personal</option>
														<option value="Work">Work</option>
														<option value="Other">Other</option>
													</ChakraSelect>
												</FormControl>
											</HStack>
										</VStack>
										<Divider height="200px" orientation="vertical" />
										<IconButton
											variant="outline"
											size="sm"
											icon={<DeleteIcon />}
											onClick={() => handleRemoveAddress(idx)}
										/>
									</HStack>
								))}
							</VStack>
						)}
					</Box>

					<HStack gap={3} justifyContent={"center"} pt={5}>
						<Button size="xs" onClick={() => navigate("/organizations")}>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={addBtnStatus}
							loadingText="Adding org"
						>
							Add Organization
						</Button>
					</HStack>
				</VStack>
			</form>
		</Navigation>
	);
};

export default CreateOrgs;
