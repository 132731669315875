import React, { useContext, useEffect, useState } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import {
	EditIcon,
	CloseIcon,
	CheckIcon,
	DeleteIcon,
	AddIcon,
} from "@chakra-ui/icons";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";
import axios from "axios";

const EmailSec = ({ orgProfile, getOrgProfile, id }) => {
    const { toastMsg, apiCall } = useContext(AccountContext);

		const {
			isOpen: isUpdateOpen,
			onOpen: onUpdateOpen,
			onClose: onUpdateClose,
		} = useDisclosure();
		const {
			isOpen: isDeleteOpen,
			onOpen: onDeleteOpen,
			onClose: onDeleteClose,
		} = useDisclosure();

		const [deleteObj, setDeleteObj] = useState({ id: "" });

		const {
			isOpen: isAddOpen,
			onOpen: onAddOpen,
			onClose: onAddClose,
		} = useDisclosure();

	const [emailEdit, setEmailEdit] = useState(false);
	const toggleEmailEdit = () => {
		setEmailEdit(!emailEdit);
	};

	const [oldEmailList, setOldEmailList] = useState([]);

	useEffect(() => {
		setOldEmailList(
			orgProfile.emails?.map((em) => {
				return {
					id: em.id,
					email_address: em.email_address,
					email_type: em.email_type,
				};
			})
		);
	}, [orgProfile]);

	const handleOldEmailChange = (e, index) => {
		const { name, value } = e.target;
		const newEmailList = [...oldEmailList];
		newEmailList[index][name] = value;
		setOldEmailList(newEmailList);
	};

	const [emailList, setEmailList] = useState([]);

	const handleAddEmail = () => {
		setEmailList([
			...emailList,
			{
				organization_id: orgProfile.id,
				email_address: "",
				email_type: "",
			},
		]);
	};

	const handleEmailChange = (e, index) => {
		const { name, value } = e.target;
		const newEmailList = [...emailList];
		newEmailList[index][name] = value;
		setEmailList(newEmailList);
	};

	const handleRemoveEmail = (idx) => {
		const newEmailList = [...emailList];
		newEmailList.splice(idx, 1);
		setEmailList(newEmailList);
	};

	//func to update email id
	const updateOrgEmail = async () => {
		let url = `${getBaseUrl()}/api/org/updateOrgEmail`;
		oldEmailList.forEach(async (em, idx) => {
			try {
				await apiCall(url, "POST", em).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleEmailEdit();
		return;
	};

	//func to delete email id
	const deleteOrgEmail = async () => {
		try {
			let url = `${getBaseUrl()}/api/org/deleteOrgEmail`;
			await apiCall(url, "POST", deleteObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getOrgProfile(id);
				toggleEmailEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to add email id
	const addOrgEmail = async () => {
		let url = `${getBaseUrl()}/api/org/addOrgEmail`;
		emailList.forEach(async (em, idx) => {
			try {
				await apiCall(url, "POST", em).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		toggleEmailEdit();
		setEmailList([]);
		return;
	};

	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Email ID
					</Heading>
				</Badge>
				{emailEdit === false ? (
					<IconButton onClick={toggleEmailEdit} size="xs" icon={<EditIcon />} />
				) : (
					<IconButton
						onClick={toggleEmailEdit}
						size="xs"
						icon={<CloseIcon />}
					/>
				)}
			</HStack>
			<Divider />
			{emailEdit === false ? (
				<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
					<UnorderedList pl={10}>
						{orgProfile.emails?.map((ema) => (
							<ListItem key={ema.id}>
								<Text>
									{ema.email_address}{" "}
									<Badge size={"xs"} variant="outline">
										{ema.email_type}
									</Badge>
								</Text>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>
			) : (
				<>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isDeleteOpen}
								onClose={() => onDeleteClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Delete Email ID
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to delete the email id
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onDeleteClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													deleteOrgEmail();
													onDeleteClose();
												}}
												ml={3}
											>
												Delete
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>

							<AlertDialog
								isOpen={isUpdateOpen}
								onClose={() => onUpdateClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Update Email ID
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to update the email ids
										</AlertDialogBody>
										<AlertDialogCloseButton />
										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onUpdateClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													updateOrgEmail();
													onUpdateClose();
												}}
												ml={3}
											>
												Update
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Edit Email</Badge>
								<IconButton
									size="xs"
									icon={<CheckIcon />}
									onClick={() => onUpdateOpen()}
								/>
							</HStack>
							<Divider />
							{oldEmailList && oldEmailList.length > 0 && (
								<VStack gap={0} mt={2}>
									{oldEmailList?.map((em, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="email_address"
													type="email"
													placeholder="Email Address"
													size={"sm"}
													value={em.email_address}
													onChange={(e) => {
														handleOldEmailChange(e, idx);
													}}
												/>
											</FormControl>
											<FormControl isRequired>
												<ChakraSelect
													name="email_type"
													placeholder="Type"
													size={"sm"}
													value={em.email_type}
													onChange={(e) => {
														handleOldEmailChange(e, idx);
													}}
												>
													<option value="Personal">Personal</option>
													<option value="Work">Work</option>
													<option value="Other">Other</option>
												</ChakraSelect>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => {
													setDeleteObj({ id: em.id });
													onDeleteOpen();
												}}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isAddOpen}
								onClose={() => onAddClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Add Email ID
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to Add new email ids
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onAddClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													addOrgEmail();
													onAddClose();
												}}
												ml={3}
											>
												Add
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Add New Email</Badge>
								<HStack>
									<IconButton
										size="xs"
										icon={<AddIcon />}
										onClick={handleAddEmail}
									/>
									{emailList && emailList.length > 0 && (
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => onAddOpen()}
										/>
									)}
								</HStack>
							</HStack>
							<Divider />
							{emailList && emailList.length > 0 && (
								<VStack gap={0} mt={2}>
									{emailList?.map((em, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="email_address"
													type="email"
													placeholder="Email Address"
													size={"sm"}
													value={em.email_address}
													onChange={(e) => {
														handleEmailChange(e, idx);
													}}
												/>
											</FormControl>
											<FormControl isRequired>
												<ChakraSelect
													name="email_type"
													placeholder="Type"
													size={"sm"}
													value={em.email_type}
													onChange={(e) => {
														handleEmailChange(e, idx);
													}}
												>
													<option value="Personal">Personal</option>
													<option value="Work">Work</option>
													<option value="Other">Other</option>
												</ChakraSelect>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => handleRemoveEmail(idx)}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
				</>
			)}
		</Box>
	);
};

export default EmailSec;
