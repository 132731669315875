import React from "react";
import {
	Modal as ChakraModal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";

function Modal({ isOpen, onClose, size = "4xl", header = "", children }) {
	return (
		<ChakraModal
			closeOnOverlayClick={false}
			isOpen={isOpen}
			size={size}
			onClose={onClose}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody sx={{ pb: "2rem" }}>{children}</ModalBody>
			</ModalContent>
		</ChakraModal>
	);
}

export default Modal;
