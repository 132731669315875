import React, { useContext } from "react";
import {
	IconButton,
	Avatar,
	Box,
	CloseButton,
	Flex,
	HStack,
	VStack,
	useColorModeValue,
	Drawer,
	DrawerContent,
	Text,
	useDisclosure,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Image,
	Container,
	Divider,
	Icon,
	Portal,
	Button,
} from "@chakra-ui/react";
import { FiMenu, FiChevronDown } from "react-icons/fi";
import { FaHome, FaSignOutAlt } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mainLogo from "assets/serc-logo.png";
import { AccountContext } from "contexts/AccountContext";
import { FaCheckCircle, FaTimesCircle, FaArrowLeft } from "react-icons/fa";
import { BsPersonCircle } from "react-icons/bs";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Navigation({ children }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { toastMsg, handleSignOut, session } = useContext(AccountContext);

	return (
		<Box
			minH="100vh"
			// bg={useColorModeValue("gray.100", "gray.900")}
			// bg="#Fffdfd"
			bg="white"
		>
			<SidebarContent
				session={session}
				onClose={() => onClose}
				display={{ base: "none", md: "block" }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full"
			>
				<DrawerContent>
					<SidebarContent onClose={onClose} />
				</DrawerContent>
			</Drawer>
			<TopNav
				onOpen={onOpen}
				session={session}
				toastMsg={toastMsg}
				handleSignOut={handleSignOut}
			/>
			<Box ml={{ base: 0, md: 40 }} py="2" height="full">
				<Container maxW={"1500px"}>
					{/* <Container maxW={{ base: "100%", md: "80rem", lg: "100%" }}> */}
					{children}
				</Container>
			</Box>
		</Box>
	);
}

const SidebarContent = ({ session, onClose, ...rest }) => {
	let navigate = useNavigate();
	return (
		<Box
			transition="3s ease"
			bg="white"
			borderRight="1px"
			borderRightColor={useColorModeValue("gray.200", "gray.700")}
			w={{ base: "full", md: 40 }}
			// width="200px"
			pos="fixed"
			h="full"
			{...rest}
		>
			<Flex
				h="20"
				alignItems="center"
				mx="4"
				justifyContent="space-between"
				// mb="3"
			>
				<Image
					cursor={"pointer"}
					src={mainLogo}
					width={"200px"}
					alt="SERC"
					onClick={() => navigate("/homepage")}
				/>
				<CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
			</Flex>
			<Divider mb={3} />
			<Box h="full">
				<NavItem key={"View People"} refName={"/people"}>
					People
				</NavItem>
				<CustomMenu name={"Roles"}>
					<NavItem key={"View Roles"} refName={"/roles"}>
						SERC/AIRC Roles
					</NavItem>
					<NavItem key={"View RT Roles"} refName={"/rt-roles"}>
						RT Roles
					</NavItem>
				</CustomMenu>
				<CustomMenu name={"Organizations"}>
					<NavItem key={"View Organizations"} refName={"/organizations"}>
						Organizations
					</NavItem>
					<NavItem
						key={"View Organization Types"}
						refName={"/organization-types"}
					>
						Organization Types
					</NavItem>
				</CustomMenu>
				<NavItem key={"View Projects"} refName={"/projects"}>
					Projects
				</NavItem>
				<NavItem key={"View Specialties"} refName={"/specialties"}>
					Specialties
				</NavItem>
				<CustomMenu name={"Publications"}>
					<NavItem key="View Publications" refName="/publications">
						Publications
					</NavItem>
					<NavItem key="View Pub Types" refName="/publication-types">
						Publication Types
					</NavItem>
				</CustomMenu>
				<NavItem key="View Research Tasks" refName="/research-tasks">
					Research Tasks
				</NavItem>
				<NavItem key="View Research Thrusts" refName="/research-thrusts">
					Research Thrusts
				</NavItem>
				<NavItem key="View Research Programs" refName="/research-programs">
					Research Programs
				</NavItem>
				<NavItem key="View Technical Reports" refName="/technical-reports">
					Technical Reports
				</NavItem>
				<NavItem key="View Finance" refName="/finance">
					Finance
				</NavItem>
				<NavItem key="View Reports" refName="/reports">
					Reports
				</NavItem>
				<NavItem key="View Users-Groups" refName="/user-group-management">
					Users & Groups
				</NavItem>
			</Box>
		</Box>
	);
};

const CustomMenu = ({ name, children }) => {
	return (
		<Menu my="2">
			<MenuButton
				textAlign={"left"}
				mt={"1"}
				w={"90%"}
				pl="3"
				h="7"
				mx="2"
				borderRadius="lg"
				role="group"
				cursor="pointer"
				_hover={{
					bg: "brand.500",
					color: "white",
				}}
				_expanded={{ bg: "brand.500", color: "white" }}
				color={"brand.700"}
				bg={"brand.100"}
				as={Button}
				fontSize={"13"}
				rightIcon={<ChevronDownIcon />}
			>
				{name}
			</MenuButton>
			<Portal>
				<MenuList>{children}</MenuList>
			</Portal>
		</Menu>
	);
};

const NavItem = ({ icon, children, refName, ...rest }) => {
	const location = useLocation();

	const activeStyle = {
		color: "brand.500",
		fColor: "white",
	};

	const inactiveStyle = {
		color: "brand.100",
		fColor: "brand.700",
	};

	const style = location.pathname.includes(refName)
		? activeStyle
		: inactiveStyle;
	return (
		<Link
			to={refName}
			style={{ textDecoration: "none" }}
			_focus={{ boxShadow: "none" }}
		>
			<Flex
				align="center"
				pl="3"
				h="7"
				mx="2"
				mt={1}
				borderRadius="lg"
				role="group"
				cursor="pointer"
				_hover={{
					bg: "brand.500",
					color: "white",
				}}
				color={style.fColor}
				bg={style.color}
				{...rest}
				fontSize={"13"}
			>
				{icon && (
					<Icon
						mr="2"
						fontSize="12"
						_groupHover={{
							color: "white",
						}}
						as={icon}
					/>
				)}
				{children}
			</Flex>
		</Link>
	);
};

const TopNav = ({ session, toastMsg, handleSignOut, onOpen, ...rest }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	return (
		<Flex
			ml={{ base: 0, md: 40 }}
			px={{ base: 4, md: 4 }}
			p={6}
			height="10"
			alignItems="center"
			bg={useColorModeValue("white", "gray.900")}
			borderBottomWidth="1px"
			borderBottomColor={useColorModeValue("gray.200", "gray.700")}
			justifyContent={{ base: "space-between", md: "flex-end" }}
			position="sticky"
			top={0}
			zIndex={"2"}
			{...rest}
		>
			<IconButton
				display={{ base: "flex", md: "none" }}
				onClick={onOpen}
				variant="ghost"
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Image
				display={{ base: "flex", md: "none" }}
				cursor={"pointer"}
				w={"80px"}
				src={mainLogo}
				width="150px"
				alt="SERC"
			/>

			<HStack
				spacing={{ base: "0", md: "6" }}
				justifyContent={"space-between"}
				w="full"
			>
				<HStack>
					<IconButton
						// display={{ base: "flex", md: "none" }}
						size={"sm"}
						position={"left"}
						onClick={() => navigate("/homepage")}
						variant="ghost"
						aria-label="open menu"
						icon={<FaHome />}
					/>
					{pathname !== "/homepage" && (
						<IconButton
							size={"sm"}
							disabled={pathname === "/homepage"}
							position={"left"}
							onClick={() => {
								navigate(-1);
							}}
							variant="ghost"
							aria-label="go back"
							icon={<FaArrowLeft />}
						/>
					)}
				</HStack>

				<Flex alignItems={"center"}>
					<Menu>
						<MenuButton
							py={2}
							transition="all 0.3s"
							_focus={{ boxShadow: "none" }}
						>
							{session && (
								<HStack>
									<Avatar
										size={"sm"}
										name={session.name}
										bg="teal.200"
										// src="https://bit.ly/broken-link"
									/>
									<VStack
										display={{ base: "none", md: "flex" }}
										alignItems="flex-start"
										spacing="1px"
										ml="2"
									>
										<Text fontSize="sm">{session.name}</Text>
										<Flex alignItems="center" gap={2}>
											<Text fontSize="xs" color="gray.600">
												Admin
											</Text>
											{session.isAdmin ? (
												<Icon
													boxSize={3}
													as={FaCheckCircle}
													color="green.400"
												/>
											) : (
												<Icon boxSize={3} as={FaTimesCircle} color="red.400" />
											)}
										</Flex>
									</VStack>
									<Box display={{ base: "none", md: "flex" }}>
										<FiChevronDown />
									</Box>
								</HStack>
							)}
						</MenuButton>
						<MenuList
							bg={useColorModeValue("white", "gray.900")}
							borderColor={useColorModeValue("gray.200", "gray.700")}
						>
							<MenuItem
								icon={<BsPersonCircle />}
								onClick={() => navigate("/profile")}
							>
								Profile
							</MenuItem>
							<MenuDivider />
							<MenuItem
								icon={<FaSignOutAlt />}
								onClick={() => {
									handleSignOut().then((d) => {
										toastMsg({
											title: "SERC",
											desc: "Logout Successful!",
											type: "success",
										});
										navigate("/");
										return;
									});
								}}
							>
								Sign out
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};
