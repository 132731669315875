import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, HStack, IconButton, VStack } from "@chakra-ui/react";
import { MultiSelect, TextInput } from "components/ChakraFormikComponents";
import { Field } from "formik";
import React from "react";

function EmailPhoneSection({
	personId,
	list = [],
	listName = "emailList",
	fieldOneName = "email_address",
	fieldOneLabel = "Email Address",
	fieldOneType = "text",
	typeFieldName = "email_type",
	setFieldValue = () => {},
}) {
	return (
		<>
			{list?.map((item, index) => (
				<VStack
					key={`${personId}-item-${index}`}
					sx={{
						background: "rgba(232,233,235,0.35)",
						borderRadius: "0.5rem",
						padding: "1rem",
					}}
					width="100%"
					gap={2}
				>
					<IconButton
						size="sm"
						icon={<DeleteIcon />}
						sx={{
							alignSelf: "flex-end",
						}}
						onClick={() => {
							const updatedValue = [...list];
							updatedValue.splice(index, 1);
							setFieldValue(updatedValue);
						}}
						variant="outline"
					/>
					<HStack gap={4} width="100%">
						<Field
							component={TextInput}
							name={`${listName}[${index}].${fieldOneName}`}
							label={fieldOneLabel}
							type={fieldOneType}
							required
						/>
						<Field
							component={MultiSelect}
							name={`${listName}[${index}].${typeFieldName}`}
							label="Type"
							placeholder="Select option"
							options={[
								{ value: "Personal", label: "Personal" },
								{ value: "Work", label: "Work" },
								{ value: "Other", label: "Other" },
							]}
							required
						/>
					</HStack>
				</VStack>
			))}
			<Button
				leftIcon={<AddIcon />}
				size="sm"
				style={{
					marginTop: "1.5rem",
					alignSelf: "flex-start",
				}}
				onClick={() => {
					setFieldValue([
						...list,
						{
							[fieldOneName]: "",
							[typeFieldName]: "",
						},
					]);
				}}
			>
				Add {fieldOneLabel}
			</Button>
		</>
	);
}

export default EmailPhoneSection;
