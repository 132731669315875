import { Button, Heading, Highlight, HStack } from "@chakra-ui/react";
import PublicationTypeForm from "components/Forms/PublicationType";
import Navigation from "components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { createPublicationType } from "utils/api";

function CreatePubType() {
	const navigate = useNavigate();
	const onCancel = () => {
		navigate("/publication-types");
	};
	const { toastMsg } = useContext(AccountContext);
	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Publication types"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Publication Types
					</Highlight>
				</Heading>
				<Button onClick={onCancel} size="xs">
					Cancel
				</Button>
			</HStack>
			<PublicationTypeForm
				onSubmit={createPublicationType}
				onSuccess={(res) => {
					navigate(`/publication-types/${res.category.id}`);
				}}
				onError={(e) => {
					toastMsg({
						title: "Error!",
						desc: e.response.data.message,
						type: "error",
					});
				}}
				onCancel={onCancel}
			/>
		</Navigation>
	);
}

export default CreatePubType;
