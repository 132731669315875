import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
	Badge,
	Box,
	Button,
	Divider,
	Heading,
	HStack,
	IconButton,
	Table,
	TableContainer,
	Tbody,
	Td,
	Tr,
	VStack,
} from "@chakra-ui/react";
import CustomAlertDialog from "components/CustomAlertDialog";
import FinanceForm from "components/Forms/Finance";
import { AccountContext } from "contexts/AccountContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DELETE, PUT } from "utils/api";

const FinanceProfile = ({ financeData, refetch }) => {
	const [finance, setFinance] = useState(financeData);
	const { toastMsg } = useContext(AccountContext);
	const [isEditing, setEditing] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		setFinance(financeData);
	}, [financeData]);

	const toggleInfoEdit = () => {
		setEditing(!isEditing);
	};

	const handleDelete = async () => {
		try {
			setIsLoading(true);
			await DELETE(`/api/finance/${finance.id}`).then((data) => {
				setIsOpen(false);
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setIsLoading(false);
				navigate("/finance");

				return;
			});
		} catch (error) {
			setIsOpen(false);
			setIsLoading(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<VStack gap={1} align="stretch" pb={20}>
			<CustomAlertDialog
				title="Delete Finance Record"
				message="Are you sure?  Confirm to delete"
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				onConfirm={handleDelete}
				isLoading={isLoading}
				confirmText="Delete"
			/>
			<Box
				padding={"10px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<HStack justifyContent="space-between" mb={1}>
					<Badge>
						<Heading as="h6" size="xs">
							Finance Profile
						</Heading>
					</Badge>
					<HStack>
						{isEditing === false && (
							<IconButton
								onClick={toggleInfoEdit}
								size="xs"
								icon={<EditIcon />}
							/>
						)}
					</HStack>
				</HStack>
				<Divider />
				{isEditing === false ? (
					<VStack align={"stretch"} px={5} pb={2}>
						<VStack align={"stretch"} justifyContent="flex-start" pb={6} pt={3}>
							<Heading as="h2" size="xl">
								{finance.title}
							</Heading>
							<TableContainer px={10}>
								<Table>
									<Tbody>
										<Tr>
											<Td>Contract</Td>
											<Td>{finance.contract}</Td>
										</Tr>
										<Tr>
											<Td>Kuali Number</Td>
											<Td>{finance.kuali}</Td>
										</Tr>
										<Tr>
											<Td>Thrust</Td>
											<Td>{finance.thrust}</Td>
										</Tr>
										<Tr>
											<Td>Task Number</Td>
											<Td>{finance.taskNumber}</Td>
										</Tr>
										<Tr>
											<Td>Start Date</Td>
											<Td>
												{new Date(finance.startDate).toLocaleDateString()}
											</Td>
										</Tr>
										<Tr>
											<Td>End Date</Td>
											<Td>{new Date(finance.endDate).toLocaleDateString()}</Td>
										</Tr>
										<Tr>
											<Td>PI</Td>
											<Td>
												{finance.pis?.map((pi, idx) => (
													<p key={idx}>{pi.name}</p>
												))}
											</Td>
										</Tr>
										<Tr>
											<Td>DO Number</Td>
											<Td>{finance.taskOrder}</Td>
										</Tr>
										<Tr>
											<Td>Task Correlation</Td>
											<Td>{finance.taskCorrelation}</Td>
										</Tr>
										<Tr>
											<Td>Total No. of Awards</Td>
											<Td>{finance.awards.length}</Td>
										</Tr>
									</Tbody>
								</Table>
							</TableContainer>
						</VStack>
						<HStack justifyContent={"center"}>
							<Button
								rightIcon={<DeleteIcon />}
								w={"10%"}
								onClick={() => {
									setIsOpen(true);
								}}
							>
								Delete
							</Button>
						</HStack>
					</VStack>
				) : (
					<>
						<br />
						<FinanceForm
							onCancel={() => {
								setEditing(false);
							}}
							contract={finance.contract}
							thrust={finance.thrustId}
							researchTaskId={finance.researchTaskId}
							taskOrder={finance.taskOrder}
							kuali={finance.kuali}
							taskCorrelation={finance.taskCorrelation}
							submitBtnText="Update Finance Record"
							hideAwardsSection
							onSubmit={async (body) => PUT(`/api/finance/${finance.id}`, body)}
							onSuccess={(data) => {
								toastMsg({
									title: "Success!",
									desc: data.message,
									type: "success",
								});
								refetch();
								setEditing(false);
							}}
						/>
					</>
				)}
			</Box>
		</VStack>
	);
};

export default FinanceProfile;
