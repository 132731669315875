import React from "react";
import { Heading, HStack, Badge, Box, Divider } from "@chakra-ui/react";
import PublicationsDatatable from "components/Datatable/PublicationsDatatable";

const PublicationTab = ({ projectProfile }) => {
	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Publications
					</Heading>
				</Badge>
			</HStack>
			<Divider sx={{ mb: 2 }} />
			<PublicationsDatatable
				data={projectProfile.publications}
				variant="project"
			/>
		</Box>
	);
};

export default PublicationTab;
