import React, { useContext, useState } from "react";
import { getBaseUrl } from "base";
import Navigation from "components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate } from "react-router-dom";
import { RichTextEditor } from "@mantine/rte";
import {
	Badge,
	Box,
	Button,
	FormControl,
	FormLabel,
	Heading,
	Highlight,
	HStack,
	Input,
	VStack,
	Select as ChakraSelect,
	IconButton,
	Divider,
} from "@chakra-ui/react";

const CreateThrusts = () => {
	const navigate = useNavigate();
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [thrustObj, setThrustObj] = useState({
		thrust_name: "",
		description: "",
		is_public: "",
	});
	const [addBtnStatus, setAddBtnStatus] = useState(false);

	//func to create Research Thurst
	const createResearchThrust = async (rtObj) => {
		let url = `${getBaseUrl()}/api/researchThrusts/createResearchThrust`;
		const { data } = await apiCall(url, "POST", rtObj);
		return data;
	};

	const handleInfoChange = (e) => {
		const { name, value } = e.target;
		setThrustObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleCreateThrust = async (e) => {
		try {
			e.preventDefault();
			setAddBtnStatus(true);

			await createResearchThrust(thrustObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/research-thrusts");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Navigation>
			<HStack justifyContent={"space-between"} pb={2}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Create Research Thrusts"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Create Research Thrusts
					</Highlight>
				</Heading>

				<Button size="xs" onClick={() => navigate("/research-thrusts")}>
					Cancel
				</Button>
			</HStack>
			<form action="submit" onSubmit={handleCreateThrust}>
				<VStack gap={1} align={"stretch"} pb={10}>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						// my={2}
						pb={5}
					>
						<Badge>Information</Badge>
						<Divider />
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Research Thrust Name</FormLabel>
									<Input
										type="text"
										name="thrust_name"
										onChange={handleInfoChange}
									/>
								</FormControl>
								<HStack gap={5} w={"full"}>
									<FormControl w={"25%"} isRequired>
										<FormLabel>Public</FormLabel>
										<ChakraSelect
											name="is_public"
											placeholder="Select option"
											onChange={handleInfoChange}
										>
											<option value="Yes">Yes</option>
											<option value="No">No</option>
										</ChakraSelect>
									</FormControl>
								</HStack>

								<FormControl style={{ zIndex: 0 }} isRequired>
									<FormLabel>Description</FormLabel>
									<RichTextEditor
										value={thrustObj.description}
										onChange={(e) => {
											setThrustObj((prev) => {
												return { ...prev, description: e };
											});
										}}
										id="rte1"
									/>
								</FormControl>

								<HStack gap={3} justifyContent={"center"} pt={5}>
									<Button
										size="xs"
										onClick={() => navigate("/research-thrusts")}
									>
										Cancel
									</Button>
									<Button
										size="xs"
										colorScheme="teal"
										type="submit"
										isLoading={addBtnStatus}
										loadingText="Adding Research Thrust"
									>
										Add Research Thrust
									</Button>
								</HStack>
							</VStack>
						</VStack>
					</Box>
				</VStack>
			</form>
		</Navigation>
	);
};

export default CreateThrusts;
