import React, { useContext, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AccountContext } from "contexts/AccountContext";
import Navigation from "./Layout/Navigation";
import AccessDenied from "pages/AccessDenied";

const permissions = {
	people: "people",
	finance: "finance",
	roles: "sercAircRoles",
	"rt-roles": "researchTaskRoles",
	organizations: "org",
	"organization-types": "org-types",
	projects: "project",
	specialties: "specialty",
	publications: "publication",
	"publication-types": "publicationCategory",
	"research-tasks": "researchTasks",
	"research-programs": "researchPrograms",
	"technical-reports": "technicalReports",
};

const ProtectedRoute = () => {
	/**
	 * @typedef {Object} CustomAccountContext
	 * @property {import("../utils/types/index").Session | null} session
	 * @property {boolean} loading
	 */

	/**
	 * @type {CustomAccountContext}
	 */
	const { session, loading } = useContext(AccountContext);

	const { pathname } = useLocation();

	const isUnauthorized = useMemo(() => {
		if (session && session.isAdmin) return false;

		const parts = pathname.split("/").slice(1);

		if (!permissions[`${parts[0]}`]) return false;

		let permission = permissions[`${parts[0]}`];

		// for (let part of parts) {
		// 	if (permission[`/${part}`]) permission = permission[`/${part}`];
		// 	else {
		// 		if (permission["/"]) permission = permission["/"];
		// 		break;
		// 	}
		// }
		if (parts.includes("create")) permission += ".CREATE";

		if (session) {
			const { permissions: userPermissions } = session;
			const [key, requiredPermission] = permission.split(".");

			if (!requiredPermission) return !Boolean(userPermissions[key]);

			return !(
				Boolean(userPermissions[key]) &&
				userPermissions[key].includes(requiredPermission)
			);
		}
	}, [pathname, session]);

	if (loading) return <p>Loading...</p>;

	if (!session) return <Navigate to="/" />;

	if (isUnauthorized)
		return (
			<Navigation>
				<AccessDenied />
			</Navigation>
		);

	return <Outlet />;
};

export default ProtectedRoute;
