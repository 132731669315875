import {
	CheckIcon,
	CloseIcon,
	EditIcon,
	ExternalLinkIcon,
} from "@chakra-ui/icons";
import { HStack, IconButton, Link, Text } from "@chakra-ui/react";
import { TextInput } from "components/ChakraFormikComponents";
import { Field, Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import { AccountContext } from "contexts/AccountContext";
import { PUT, handleError } from "utils/api";
import { isValidFileName } from "utils/helpers";

/**
 * @typedef Props
 * @prop {string} fileName
 * @prop {string} endpoint - required
 * @prop {(res: import("axios").AxiosResponse["data"]) => void} onSuccess
 * @prop {boolean} isHyperLink
 * @prop {string} externalLink
 */

/**
 * @type {React.FunctionComponent}
 * @param {Props} props
 * @returns {React.ReactElement}
 * @example
 * <FileNameInput
 *		fileName={peopleProfile.imageFilename}
 *		endpoint={`/api/people/renameProfile/${peopleProfile.id}`}
 *		onSuccess={(res) => {
 *			setPeopleProfile(res.person);
 *		}}
 *	/>
 */
function FileNameInput({
	fileName,
	endpoint,
	onSuccess = () => {},
	isHyperLink = false,
	externalLink,
}) {
	const [isEditing, setEditing] = useState(false);

	const { toastMsg } = useContext(AccountContext);

	if (!fileName) return null;

	if (!isEditing)
		return (
			<HStack justifyContent="space-between" width="100%">
				{isHyperLink && externalLink ? (
					<Link
						isExternal
						href={externalLink}
						sx={{
							overflow: "hidden",
						}}
					>
						{fileName || "File"} <ExternalLinkIcon />
					</Link>
				) : (
					<Text
						sx={{
							overflow: "hidden",
						}}
					>
						{fileName || "File"}
					</Text>
				)}

				<IconButton
					size="xs"
					icon={<EditIcon />}
					onClick={() => {
						setEditing(true);
					}}
				/>
			</HStack>
		);

	return (
		<Formik
			initialValues={{
				filename: fileName,
			}}
			validate={({ filename }) => {
				if (!filename || filename.trim().length === 0) {
					return { filename: "Filename cannot be empty" };
				}
				return { filename: isValidFileName(filename) };
			}}
			onSubmit={async ({ filename }, { setSubmitting }) => {
				try {
					setSubmitting(true);
					const res = await PUT(endpoint, { filename });
					onSuccess(res);
					setEditing(false);
					toastMsg({
						title: "Success",
						desc: "File Name updated successfully!",
						type: "success",
					});
				} catch (e) {
					let errorMsg = handleError(e);
					if (typeof errorMsg !== "string") errorMsg = "Error renaming file";
					toastMsg({
						title: "Error!",
						desc: errorMsg,
						type: "error",
					});
				} finally {
					setSubmitting(false);
				}
			}}
		>
			{({ isSubmitting }) => (
				<Form
					style={{
						width: "100%",
					}}
				>
					<Field component={TextInput} name="filename" />
					<HStack mt={2} justifyContent="flex-end" gap={1}>
						<IconButton
							icon={<CloseIcon />}
							size="xs"
							variant="outline"
							onClick={() => {
								setEditing(false);
							}}
						/>
						<IconButton
							type="submit"
							icon={<CheckIcon />}
							size="xs"
							disabled={isSubmitting}
						/>
					</HStack>
				</Form>
			)}
		</Formik>
	);
}

export default FileNameInput;
