import {
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import useFetch from "hooks/useFetch";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import ResearchTask from "./ResearchTask";
import TeamMembers from "./TeamMembers";

function SingleResearch() {
	const { id } = useParams();
	const { data, error, loading } = useFetch(
		`/api/researchTask/getResearchTask/${id}`
	);
	const renderPageData = useCallback(() => {
		if (loading) return <Text>Loading...</Text>;
		if (error || !data) return <Text>Error getting research task data</Text>;
		return (
			<TabPanels ml={4}>
				<TabPanel py={0} px={0}>
					<ResearchTask researchData={data} />
				</TabPanel>
				<TabPanel px={0} py={0}>
					<TeamMembers
						membersData={data.people}
						periodStart={data.period_start}
						periodEnd={data.period_end}
						researchTaskId={id}
					/>
				</TabPanel>
			</TabPanels>
		);
	}, [data, error, loading, id]);
	return (
		<Navigation>
			<Tabs variant="soft-rounded" orientation="vertical" size="sm">
				<TabList gap={5} maxWidth="200px" minWidth="200px">
					<Tab justifyContent="left">Research Task</Tab>
					<Tab justifyContent="left">Team Members</Tab>
				</TabList>
				{renderPageData()}
			</Tabs>
		</Navigation>
	);
}

export default SingleResearch;
