import { Badge, Box, VStack } from "@chakra-ui/react";
import React from "react";

function PageSection({ title, children }) {
	return (
		<VStack align="stretch" justifyContent="flex-start" pb={5} px={5}>
			<Badge>{title}</Badge>
			<Box px={5}>{children}</Box>
		</VStack>
	);
}
export default PageSection;
