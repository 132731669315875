import React from "react";
import {
  Heading,
  HStack,
  Badge,
  Box,
  Divider,
  VStack,
  Text,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

const TagsTab = ({ projectProfile }) => {
  return (
    <Box
      padding={"10px"}
      borderWidth={1}
      borderRadius={8}
      boxShadow="lg"
      rounded="xl"
    >
      <HStack justifyContent={"space-between"}>
        <Badge>
          <Heading as="h6" size="xs">
            Tags
          </Heading>
        </Badge>
      </HStack>
      <Divider />
      <VStack align={"stretch"} justifyContent="flex-start" pt={3} pb={3}>
        <UnorderedList pl={10}>
          {projectProfile.tags?.map((tg) => (
            <ListItem key={tg.id}>
              <Text>{tg.tag_title}</Text>
            </ListItem>
          ))}
        </UnorderedList>
      </VStack>
    </Box>
  );
};

export default TagsTab;
