import {
	Box,
	Button,
	Divider,
	Heading,
	Highlight,
	HStack,
} from "@chakra-ui/react";
import Datatable from "components/Datatable";
import React from "react";
import { CSVLink } from "react-csv";

function ResearchTasksReport({ researchTasksReport, startDate, endDate }) {
	return (
		<>
			<HStack justifyContent={"space-between"}>
				<Heading size="sm" mb={2}>
					<Highlight
						query="Research Tasks Report"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Research Tasks Report
					</Highlight>
				</Heading>

				<CSVLink
					data={researchTasksReport}
					headers={[
						{ label: "ID", key: "id" },
						{ label: "Task Number", key: "task_number" },
						{ label: "Task Name", key: "task_name" },
						{ label: "Research Thurst", key: "researchThrusts" },
						{ label: "Centers", key: "centers" },
						{ label: "Period Start", key: "period_start" },
						{ label: "Period End", key: "period_end" },
						{ label: "Public", key: "public" },
						{ label: "Updated On", key: "updated_at" },
					]}
					filename={`SERC_Research_Tasks_Report_${startDate}_${endDate}`}
					target="_blank"
				>
					<Button size="xs">Export</Button>
				</CSVLink>
			</HStack>
			<Divider mb={2} />
			<Box
				padding="20px"
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				<Datatable
					data={researchTasksReport}
					columns={[
						{
							className: "dt-body-left",
							title: "ID",
							data: "id",
						},
						{
							className: "dt-body-left",
							title: "Task Number",
							data: "task_number",
						},
						{
							className: "dt-body-left",
							title: "Task Name",
							data: "task_name",
							width: "30%",
						},
						{
							className: "dt-body-left",
							title: "Research Thurst",
							data: "researchThrusts",
							width: "30%",
						},
						{
							className: "dt-body-left",
							title: "Centers",
							data: "centers",
						},
						{
							className: "dt-body-left",
							title: "Period Start",
							data: "period_start",
						},
						{
							className: "dt-body-left",
							title: "Period End",
							data: "period_end",
						},
						{
							className: "dt-body-left",
							title: "Public",
							data: "public",
							width: "3%",
						},
						{
							className: "dt-body-left",
							title: "Updated At",
							data: "updated_at",
						},
					]}
					theaders={[
						"ID",
						"Task Number",
						"Task Name",
						"Research Thurst",
						"Centers",
						"Period Start",
						"Period End",
						"Public",
						"Updated At",
					]}
					navigatePath="/research-tasks"
					tableId="viewResearchReport"
				/>
			</Box>
		</>
	);
}

export default ResearchTasksReport;
