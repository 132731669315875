import React, { useContext, useEffect, useState } from "react";
import {
	Heading,
	HStack,
	Text,
	VStack,
	Badge,
	Box,
	IconButton,
	FormControl,
	Select as ChakraSelect,
	Input,
	Divider,
	UnorderedList,
	ListItem,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	useDisclosure,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import {
	EditIcon,
	CloseIcon,
	CheckIcon,
	DeleteIcon,
	AddIcon,
} from "@chakra-ui/icons";
import { getBaseUrl } from "../../../../base";
import { AccountContext } from "contexts/AccountContext";
import axios from "axios";

const PhoneSec = ({ orgProfile, getOrgProfile, id }) => {
    const { toastMsg, apiCall } = useContext(AccountContext);

		const {
			isOpen: isUpdateOpen,
			onOpen: onUpdateOpen,
			onClose: onUpdateClose,
		} = useDisclosure();
		const {
			isOpen: isDeleteOpen,
			onOpen: onDeleteOpen,
			onClose: onDeleteClose,
		} = useDisclosure();

		const [deleteObj, setDeleteObj] = useState({ id: "" });

		const {
			isOpen: isAddOpen,
			onOpen: onAddOpen,
			onClose: onAddClose,
		} = useDisclosure();

	const [phoneEdit, setPhoneEdit] = useState(false);
	const togglePhoneEdit = () => {
		setPhoneEdit(!phoneEdit);
	};

	const [oldPhoneList, setOldPhoneList] = useState([]);

	useEffect(() => {
		setOldPhoneList(
			orgProfile.phones?.map((ph) => {
				return {
					id: ph.id,
					phone_number: ph.phone_number,
					phone_type: ph.phone_type,
				};
			})
		);
	}, [orgProfile]);

	const handleOldPhoneChange = (e, index) => {
		const { name, value } = e.target;
		const newPhoneList = [...oldPhoneList];
		newPhoneList[index][name] = value;
		setOldPhoneList(newPhoneList);
	};

	const [phoneList, setPhoneList] = useState([]);

	const handleAddPhone = () => {
		setPhoneList([
			...phoneList,
			{
				organization_id: orgProfile.id,
				phone_number: "",
				phone_type: "",
			},
		]);
	};

	const handlePhoneChange = (e, index) => {
		const { name, value } = e.target;
		const newPhoneList = [...phoneList];
		newPhoneList[index][name] = value;
		setPhoneList(newPhoneList);
	};

	const handleRemovePhone = (idx) => {
		const newPhoneList = [...phoneList];
		newPhoneList.splice(idx, 1);
		setPhoneList(newPhoneList);
	};

	//func to update phone no
	const updateOrgPhone = async () => {
		let url = `${getBaseUrl()}/api/org/updateOrgPhone`;
		oldPhoneList.forEach(async (ph, idx) => {
			try {
				await apiCall(url, "POST", ph).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		togglePhoneEdit();
		return;
	};

	//func to delete phone no
	const deleteOrgPhone = async () => {
		try {
			let url = `${getBaseUrl()}/api/org/deleteOrgPhone`;
			await apiCall(url, "POST", deleteObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				getOrgProfile(id);
				togglePhoneEdit();
				return;
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	//func to add phone no
	const addOrgPhone = async () => {
		let url = `${getBaseUrl()}/api/org/addOrgPhone`;
		phoneList.forEach(async (ph, idx) => {
			try {
				await apiCall(url, "POST", ph).then((res) => {
					toastMsg({
						title: "Success!",
						desc: res.data.message,
						type: "success",
					});
				});
			} catch (error) {
				toastMsg({
					title: "Error!",
					desc: error.response.data.message,
					type: "error",
				});
				return;
			}
		});
		getOrgProfile(id);
		togglePhoneEdit();
		setPhoneList([]);
		return;
	};

	return (
		<Box
			padding={"10px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Phone
					</Heading>
				</Badge>
				{phoneEdit === false ? (
					<IconButton onClick={togglePhoneEdit} size="xs" icon={<EditIcon />} />
				) : (
					<IconButton
						onClick={togglePhoneEdit}
						size="xs"
						icon={<CloseIcon />}
					/>
				)}
			</HStack>
			<Divider />
			{phoneEdit === false ? (
				<VStack align={"stretch"} justifyContent="flex-start" pb={5} pt={3}>
					<UnorderedList pl={10}>
						{orgProfile.phones?.map((ph) => (
							<ListItem key={ph.id}>
								<Text>
									{ph.phone_number}{" "}
									<Badge size={"xs"} variant="outline">
										{ph.phone_type}
									</Badge>
								</Text>
							</ListItem>
						))}
					</UnorderedList>
				</VStack>
			) : (
				<>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isDeleteOpen}
								onClose={() => onDeleteClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Delete Phone Number
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to delete the phone number
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onDeleteClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													deleteOrgPhone();
													onDeleteClose();
												}}
												ml={3}
											>
												Delete
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>

							<AlertDialog
								isOpen={isUpdateOpen}
								onClose={() => onUpdateClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Update Phone Number
										</AlertDialogHeader>

										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to update the phone numbers
										</AlertDialogBody>
										<AlertDialogCloseButton />
										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onUpdateClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													updateOrgPhone();
													onUpdateClose();
												}}
												ml={3}
											>
												Update
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Edit Phone</Badge>
								<IconButton
									size="xs"
									icon={<CheckIcon />}
									onClick={() => onUpdateOpen()}
								/>
							</HStack>
							<Divider />
							{oldPhoneList && oldPhoneList.length > 0 && (
								<VStack gap={0} mt={2}>
									{oldPhoneList?.map((ph, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="phone_number"
													value={ph.phone_number}
													type="text"
													placeholder="Phone Number"
													size={"sm"}
													onChange={(e) => {
														handleOldPhoneChange(e, idx);
													}}
												/>
											</FormControl>
											<FormControl isRequired>
												<ChakraSelect
													name="phone_type"
													value={ph.phone_type}
													placeholder="Type"
													size={"sm"}
													onChange={(e) => {
														handleOldPhoneChange(e, idx);
													}}
												>
													<option value="Personal">Personal</option>
													<option value="Work">Work</option>
													<option value="Other">Other</option>
												</ChakraSelect>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => {
													setDeleteObj({ id: ph.id });
													onDeleteOpen();
												}}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
					<Box
						padding={"10px"}
						borderWidth={1}
						borderRadius={8}
						boxShadow="lg"
						rounded="xl"
						my={2}
						pb={5}
						mx={10}
					>
						<form>
							<AlertDialog
								isOpen={isAddOpen}
								onClose={() => onAddClose()}
								closeOnOverlayClick={false}
								motionPreset="slideInBottom"
							>
								<AlertDialogOverlay>
									<AlertDialogContent>
										<AlertDialogHeader fontSize="lg" fontWeight="bold">
											Add Phone Number
										</AlertDialogHeader>
										<AlertDialogCloseButton />
										<AlertDialogBody>
											Are you sure?
											<br />
											Confirm to Add new phone numbers
										</AlertDialogBody>

										<AlertDialogFooter>
											<Button size={"sm"} onClick={() => onAddClose()}>
												Cancel
											</Button>
											<Button
												type="submit"
												size={"sm"}
												onClick={() => {
													addOrgPhone();
													onAddClose();
												}}
												ml={3}
											>
												Add
											</Button>
										</AlertDialogFooter>
									</AlertDialogContent>
								</AlertDialogOverlay>
							</AlertDialog>
							<HStack justifyContent={"space-between"}>
								<Badge>Add New Phone</Badge>
								<HStack>
									<IconButton
										size="xs"
										icon={<AddIcon />}
										onClick={handleAddPhone}
									/>
									{phoneList && phoneList.length > 0 && (
										<IconButton
											size="xs"
											icon={<CheckIcon />}
											onClick={() => onAddOpen()}
										/>
									)}
								</HStack>
							</HStack>
							<Divider />
							{phoneList && phoneList.length > 0 && (
								<VStack gap={0} mt={2}>
									{phoneList?.map((ph, idx) => (
										<HStack gap={5} w={"full"} px={5} key={idx}>
											<FormControl isRequired>
												<Input
													name="phone_number"
													value={ph.phone_number}
													type="number"
													placeholder="Phone Number"
													size={"sm"}
													onChange={(e) => {
														handlePhoneChange(e, idx);
													}}
												/>
											</FormControl>
											<FormControl isRequired>
												<ChakraSelect
													name="phone_type"
													value={ph.phone_type}
													placeholder="Type"
													size={"sm"}
													onChange={(e) => {
														handlePhoneChange(e, idx);
													}}
												>
													<option value="Personal">Personal</option>
													<option value="Work">Work</option>
													<option value="Other">Other</option>
												</ChakraSelect>
											</FormControl>
											<Divider height="50px" orientation="vertical" />
											<IconButton
												variant="outline"
												size="xs"
												icon={<DeleteIcon />}
												onClick={() => handleRemovePhone(idx)}
											/>
										</HStack>
									))}
								</VStack>
							)}
						</form>
					</Box>
				</>
			)}
		</Box>
	);
};

export default PhoneSec;
