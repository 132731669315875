import React, { useState, useEffect, useContext } from "react";
import Navigation from "components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { useNavigate, useParams } from "react-router-dom";
import {
	Badge,
	Box,
	Divider,
	Heading,
	HStack,
	IconButton,
	ListItem,
	FormControl,
	Input,
	FormLabel,
	UnorderedList,
	useDisclosure,
	VStack,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	AlertDialogCloseButton,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { getBaseUrl } from "base";
import { DELETE, PUT } from "utils/api";

const SingleResearchTaskRole = () => {
	const { id } = useParams();
	const [roleProfile, setRoleProfile] = useState({});
	const [infoEdit, setInfoEdit] = useState(false);
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [addBtnStatus, setAddBtnStatus] = useState(false);
	const navigate = useNavigate();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isUpdateOpen,
		onOpen: onUpdateOpen,
		onClose: onUpdateClose,
	} = useDisclosure();

	const toggleInfoEdit = () => {
		setInfoEdit(!infoEdit);
	};

	//func to get single Org type profile
	const getRTRoleProfile = async (id) => {
		let url = `${getBaseUrl()}/api/researchTaskRoles/${id}`;
		const { data } = await apiCall(url);
		setRoleProfile(data);
		return;
	};

	const [roleObj, setRoleObj] = useState({});

	useEffect(() => {
		setRoleObj({ roleName: roleProfile.roleName });
	}, [roleProfile]);

	//func to update rt role
	const updateRole = async () => {
		try {
			await PUT(`/api/researchTaskRoles/${id}`, roleObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
			});
		} catch (error) {
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
		getRTRoleProfile(id);
		toggleInfoEdit();
		return;
	};

	//func to delete rt role
	const deleteRole = async () => {
		try {
			setAddBtnStatus(true);
			let url = ``;
			await DELETE(`/api/researchTaskRoles/${id}`).then((data) => {
				toastMsg({
					title: "Success!",
					desc: data.message,
					type: "success",
				});
				setTimeout(() => {
					navigate("/rt-roles");
					setAddBtnStatus(false);
				}, 2000);
				return;
			});
		} catch (error) {
			setAddBtnStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	useEffect(() => {
		getRTRoleProfile(id);
		window.scrollTo(0, 0);
	}, []);

	return (
		<Navigation>
			<VStack gap={1} align={"stretch"} pb={20}>
				<AlertDialog
					isOpen={isOpen}
					onClose={() => onClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Delete Role
							</AlertDialogHeader>
							<AlertDialogCloseButton />
							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to Delete RT Role
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onClose()}>
									Cancel
								</Button>
								<Button
									size={"sm"}
									isLoading={addBtnStatus}
									loadingText="Deleting"
									onClick={() => {
										deleteRole();
									}}
									ml={3}
								>
									Delete
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				<AlertDialog
					isOpen={isUpdateOpen}
					onClose={() => onUpdateClose()}
					closeOnOverlayClick={false}
					motionPreset="slideInBottom"
				>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Update Role
							</AlertDialogHeader>

							<AlertDialogBody>
								Are you sure?
								<br />
								Confirm to update the RT Role Name
							</AlertDialogBody>
							<AlertDialogCloseButton />
							<AlertDialogFooter>
								<Button size={"sm"} onClick={() => onUpdateClose()}>
									Cancel
								</Button>
								<Button
									type="submit"
									size={"sm"}
									onClick={() => {
										updateRole();
										onUpdateClose();
									}}
									ml={3}
								>
									Update
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
				<Box
					padding={"10px"}
					borderWidth={1}
					borderRadius={8}
					boxShadow="lg"
					rounded="xl"
				>
					<HStack justifyContent={"space-between"}>
						<Badge>
							<Heading as="h6" size="xs">
								RT Role
							</Heading>
						</Badge>
						<HStack>
							{infoEdit === false ? (
								<IconButton
									onClick={toggleInfoEdit}
									size="xs"
									icon={<EditIcon />}
								/>
							) : (
								<>
									<IconButton
										onClick={toggleInfoEdit}
										size="xs"
										icon={<CloseIcon />}
									/>
									<IconButton
										size="xs"
										icon={<CheckIcon />}
										onClick={() => onUpdateOpen()}
									/>
								</>
							)}
						</HStack>
					</HStack>
					<Divider />
					{infoEdit === false ? (
						<VStack align={"stretch"} px={5}>
							<VStack
								align={"stretch"}
								justifyContent="flex-start"
								pb={5}
								pt={3}
							>
								<Heading as="h2" size="xl">
									{roleProfile.roleName}
								</Heading>
								<Heading as="h4" size="md">
									RT Role ID: {roleProfile.id}
								</Heading>
								<br />
								<UnorderedList pl={5}>
									<ListItem>
										Created on:{" "}
										{new Date(roleProfile.createdAt).toLocaleDateString()}
									</ListItem>
									<ListItem>
										Updated on:{" "}
										{new Date(roleProfile.updatedAt).toLocaleDateString()}
									</ListItem>
								</UnorderedList>
							</VStack>
							<Divider />
							<br />
							<HStack justifyContent={"center"}>
								<Button
									rightIcon={<DeleteIcon />}
									w={"10%"}
									onClick={() => {
										onOpen();
									}}
								>
									Delete
								</Button>
							</HStack>
						</VStack>
					) : (
						<VStack py={2} gap={2} mt={5}>
							<VStack gap={3} w={"full"} px={5}>
								<FormControl isRequired>
									<FormLabel>Research Task Role Name</FormLabel>
									<Input
										value={roleObj.roleName}
										type="text"
										placeholder="Role Name"
										onChange={(e) => {
											setRoleObj({
												roleName: e.target.value,
											});
										}}
									/>
								</FormControl>
							</VStack>
						</VStack>
					)}
				</Box>
			</VStack>
		</Navigation>
	);
};

export default SingleResearchTaskRole;
