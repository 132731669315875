import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	HStack,
	Badge,
	Heading,
	Button,
	Divider,
	VStack,
	FormControl,
	FormLabel,
	Input,
	IconButton,
} from "@chakra-ui/react";
import { getBaseUrl } from "base";
import { AccountContext } from "contexts/AccountContext";
import { CloseIcon } from "@chakra-ui/icons";

const CreateUser = ({ toggleAddUser, getUserList }) => {
	const { toastMsg, apiCall } = useContext(AccountContext);
	const [userObj, setUserObj] = useState({
		email: "",
		first_name: "",
		last_name: "",
	});
	const [signupBtn, setSignUpBtn] = useState(false);

	//func to create - signup user
	const createUser = async (userResgObj) => {
		let url = `${getBaseUrl()}/api/createUser`;
		const { data } = await apiCall(url, "POST", userResgObj);
		return data;
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserObj((prev) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleCreateUser = async (e) => {
		try {
			setSignUpBtn(true);
			e.preventDefault();
			await createUser(userObj).then((data) => {
				toastMsg({
					title: "Success!",
					desc: "User created successfully",
					type: "success",
				});
				getUserList();
				toggleAddUser();
				setSignUpBtn(false);
				return;
			});
		} catch (error) {
			setSignUpBtn(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			return;
		}
	};

	return (
		<Box
			padding={"15px"}
			borderWidth={1}
			borderRadius={8}
			boxShadow="lg"
			rounded="xl"
		>
			<HStack justifyContent={"space-between"}>
				<Badge>
					<Heading as="h6" size="xs">
						Add User
					</Heading>
				</Badge>
				<IconButton
					icon={<CloseIcon />}
					size="xs"
					onClick={() => {
						setUserObj({});
						toggleAddUser();
					}}
				/>
			</HStack>
			<Divider />
			<br />
			<form action="submit" onSubmit={handleCreateUser}>
				<VStack gap={3} px={3}>
					<FormControl isRequired>
						<FormLabel>First Name</FormLabel>
						<Input type="text" name="first_name" onChange={handleChange} />
					</FormControl>
					<FormControl isRequired>
						<FormLabel>Last Name</FormLabel>
						<Input type="text" name="last_name" onChange={handleChange} />
					</FormControl>
					<FormControl isRequired>
						<FormLabel>Email address</FormLabel>
						<Input type="email" name="email" onChange={handleChange} />
					</FormControl>

					<HStack gap={3} justifyContent={"center"} pt={5} pb={3}>
						<Button
							size="xs"
							onClick={() => {
								setUserObj({});
								toggleAddUser();
							}}
						>
							Cancel
						</Button>
						<Button
							size="xs"
							colorScheme="teal"
							type="submit"
							isLoading={signupBtn}
							loadingText="Creating User"
						>
							Add User
						</Button>
					</HStack>
				</VStack>
			</form>
		</Box>
	);
};

export default CreateUser;
