import React, { useCallback } from "react";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";
import dummyImg from "assets/profile.jpeg";
import { getFormattedDateStr } from "utils/helpers";

const People = () => {
	const { data, loading, error } = useFetch("/api/people/getAllPeopleWithOrg");
	const navigate = useNavigate();
	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting People data</p>;
		data.forEach((dt) => {
			dt.updated_at = getFormattedDateStr(new Date(dt.updated_at));
			if (!dt.orgs) dt.orgs = "";
			if (Array.isArray(dt.orgs))
				dt.orgs = dt.orgs.map((a) => a.organization_name).join(", ");
			if (!dt.emails) dt.emails = "";
			if (Array.isArray(dt.emails))
				dt.emails = dt.emails.map((emailObj) => emailObj.email).join(", ");
		});
		return (
			<Datatable
				data={data}
				columns={[
					{
						title: "IMG",
						width: "10%",
						sortable: false,
						render: (data, type, row) => {
							return `<img src="${
								row.image_s3 === null ? dummyImg : row.image_s3
							}" onerror="this.onerror=null; this.src='${dummyImg}'" alt="img"/>`;
						},
					},
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Prefix",
						data: "prefix",
					},
					{
						className: "dt-body-left",
						title: "First Name",
						data: "first_name",
					},
					{ className: "dt-body-left", title: "Last Name", data: "last_name" },
					{
						className: "dt-body-left",
						title: "Organisation",
						width: "35%",
						data: "orgs",
					},
					{
						className: "dt-body-left",
						title: "Emails",
						width: "35%",
						data: "emails",
					},
					{
						className: "dt-body-left",
						title: "Public",
						data: "public",
					},
					{
						className: "dt-body-left",
						width: "10%",
						title: "Updated On",
						data: "updated_at",
					},
				]}
				theaders={[
					"IMG",
					"ID",
					"Prefix",
					"First Name",
					"Last Name",
					"Organisation",
					"Emails",
					"Public",
					"Updated On",
				]}
				navigatePath="/people"
				tableId="viewPeople"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="People"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						People
					</Highlight>
				</Heading>
				<HStack gap={0}>
					<Button size="xs" onClick={() => navigate("/people/create")}>
						Create
					</Button>
					<CSVLink
						data={data}
						headers={[
							{ label: "ID", key: "id" },
							{ label: "Prefix", key: "prefix" },
							{ label: "First Name", key: "first_name" },
							{ label: "Last Name", key: "last_name" },
							{ label: "Organizations", key: "orgs" },
							{ label: "Updated On", key: "updated_at" },
						]}
						filename="SERC_Peoples_Data"
						target="_blank"
					>
						<Button size="xs">Export</Button>
					</CSVLink>
				</HStack>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default People;
