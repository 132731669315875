import React, { useContext, useState } from "react";
import {
	Heading,
	Highlight,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	Stack,
	Box,
	VStack,
	Badge,
	Text,
	Icon,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import Navigation from "../../components/Layout/Navigation";
import { AccountContext } from "contexts/AccountContext";
import { BiReset } from "react-icons/bi";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { getBaseUrl } from "base";

const Profile = () => {
	const { session, apiCall, toastMsg } = useContext(AccountContext);
	// console.log(session);
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [passChgStatus, setPassChgStatus] = useState(false);

	//func to change user password
	const changePassword = async () => {
		try {
			setPassChgStatus(true);
			let url = `${getBaseUrl()}/api/users/changePassword/`;
			let passObj = {
				username: session.user.email,
				password: password,
			};
			await apiCall(url, "POST", passObj).then((res) => {
				toastMsg({
					title: "Success!",
					desc: res.data.message,
					type: "success",
				});
				setPassword("");
				setPassChgStatus(false);
				return;
			});
		} catch (error) {
			setPassChgStatus(false);
			toastMsg({
				title: "Error!",
				desc: error.response.data.message,
				type: "error",
			});
			setPassword("");
			return;
		}
	};

	return (
		<Navigation>
			<div>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Profile"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Profile
					</Highlight>
				</Heading>
			</div>
			{session && (
				<Flex justifyContent={"center"}>
					<Stack
						spacing={8}
						w={"full"}
						maxW={"lg"}
						rounded={"2xl"}
						boxShadow={"2xl"}
						borderWidth={1}
						p={6}
						my={12}
					>
						<Box textAlign={"center"}>
							<Heading
								pb={2}
								lineHeight={1.1}
								fontSize={{ base: "2xl", sm: "3xl" }}
							>
								{session.name}
							</Heading>
							<Flex alignItems="center" justifyContent="center" gap={2}>
								<Text color="gray.600" fontSize={"xs"}>
									Admin
								</Text>
								{session.isAdmin ? (
									<Icon boxSize={4} as={FaCheckCircle} color="green.400" />
								) : (
									<Icon boxSize={4} as={FaTimesCircle} color="red.400" />
								)}
							</Flex>
						</Box>
						<VStack align={"stretch"} spacing={4}>
							<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
								<Badge>Email ID</Badge>
								<Text px={5}>{session.user.email}</Text>
							</VStack>
							<VStack align={"stretch"} justifyContent="flex-start" pb={5}>
								<Badge>Change Password</Badge>
								<VStack align={"stretch"} spacing={2} px={5} gap={3}>
									<FormControl pt={2} id="new-password" isRequired>
										<FormLabel fontSize={"xs"}>New Password</FormLabel>
										<InputGroup>
											<Input
												value={password}
												placeholder="********"
												name="password"
												type={showPassword ? "text" : "password"}
												onChange={(e) => setPassword(e.target.value)}
											/>
											<InputRightElement h={"full"}>
												<Button
													variant={"ghost"}
													onClick={() =>
														setShowPassword((showPassword) => !showPassword)
													}
												>
													{showPassword ? <ViewIcon /> : <ViewOffIcon />}
												</Button>
											</InputRightElement>
										</InputGroup>
									</FormControl>
									<Button
										type="submit"
										size={"sm"}
										w="full"
										rightIcon={<BiReset />}
										isLoading={passChgStatus}
										loadingText="Changing Password"
										bg={"teal.400"}
										color={"white"}
										_hover={{
											bg: "teal.500",
										}}
										onClick={changePassword}
									>
										Change Password
									</Button>
								</VStack>
							</VStack>
						</VStack>
					</Stack>
				</Flex>
			)}
		</Navigation>
	);
};

export default Profile;
