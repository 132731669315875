import React, { useCallback } from "react";
import {
	Heading,
	Highlight,
	HStack,
	Button,
	Divider,
	Box,
} from "@chakra-ui/react";
import Navigation from "components/Layout/Navigation";
import { useNavigate } from "react-router-dom";
import useFetch from "hooks/useFetch";
import Datatable from "components/Datatable";

const Specialties = () => {
	const { data, loading, error } = useFetch(
		"/api/specialities/getAllSpecialities"
	);
	const navigate = useNavigate();

	const renderData = useCallback(() => {
		if (loading) return <p>Loading...</p>;
		if (error) return <p>Error getting Specialties data</p>;

		return (
			<Datatable
				data={data}
				columns={[
					{
						className: "dt-body-left",
						title: "ID",
						data: "id",
					},
					{
						className: "dt-body-left",
						title: "Title",
						data: "specialty_title",
					},
				]}
				theaders={["ID", "Title"]}
				navigatePath=""
				tableId="viewSpecialties"
			/>
		);
	}, [data, loading, error]);

	return (
		<Navigation>
			<HStack justifyContent={"space-between"}>
				<Heading as="h3" size="mx" mb={2}>
					<Highlight
						query="Specialties"
						styles={{ px: "2", py: "1", rounded: "full", bg: "brand.100" }}
					>
						Specialties
					</Highlight>
				</Heading>
				<Button size="xs">Create</Button>
			</HStack>
			<Divider mb={2} />
			<Box
				padding={"20px"}
				borderWidth={1}
				borderRadius={8}
				boxShadow="lg"
				rounded="xl"
			>
				{renderData()}
			</Box>
		</Navigation>
	);
};

export default Specialties;
